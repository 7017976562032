<template>
    <SvgIcon :width="iconSize" :height="iconSize">
        <component :is="starIcon" />
    </SvgIcon>
</template>

<script>
import StarEmptySmall from '@static/icons/20px/star-empty.svg?inline';
import StarHalfSmall from '@static/icons/20px/star-half.svg?inline';
import StarFilledSmall from '@static/icons/20px/star-filled.svg?inline';
import StarEmptyLarge from '@static/icons/32px/star-empty.svg?inline';
import StarHalfLarge from '@static/icons/32px/star-half.svg?inline';
import StarFilledLarge from '@static/icons/32px/star-filled.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';

const STAR_MAP = {
    small: {
        empty: StarEmptySmall,
        half: StarHalfSmall,
        full: StarFilledSmall,
    },
    large: {
        empty: StarEmptyLarge,
        half: StarHalfLarge,
        full: StarFilledLarge,
    },
};

export default {
    name: 'RatingStar',

    components: {
        SvgIcon,
        StarEmptySmall,
        StarHalfSmall,
        StarFilledSmall,
        StarEmptyLarge,
        StarHalfLarge,
        StarFilledLarge,
    },

    props: {
        type: {
            type: String,
            required: true,
        },

        isLarge: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        starIcon() {
            return STAR_MAP[this.isLarge ? 'large' : 'small'][this.type];
        },

        iconSize() {
            return this.isLarge ? '32px' : '20px';
        },
    },
};
</script>
