<script>
import { LOCALE_WITH_EUR_PRICE, EUR } from '@configs/duty';

import { SHOW_DUTY_INFORMATION_NAME } from '@localeConfig/keys';

export default {
    name: 'DutyDataProvider',

    props: {
        product: {
            type: Object,
            required: true,
        },
    },

    computed: {
        isDutyInformationEnabled() {
            return this.$getLocaleConfigByKey(SHOW_DUTY_INFORMATION_NAME);
        },

        showDutyInformation() {
            if (this.isDutyInformationEnabled) {
                const priceAmountToExceed = 100;

                try {
                    return (
                        this.product.values.final_price.value[
                            LOCALE_WITH_EUR_PRICE
                        ][EUR].amount > priceAmountToExceed
                    );
                } catch {
                    return false;
                }
            }

            return false;
        },
    },

    render() {
        return this.$scopedSlots.default({
            showDutyInformation: this.showDutyInformation,
        });
    },
};
</script>
