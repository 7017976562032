<template>
    <div class="product-reviews">
        <div class="top-container">
            <ContainerContent class="top-content">
                <BaseHeading
                    v-if="reviewsTotalCount"
                    theme="h3"
                    class="reviews-heading"
                >
                    {{ $t('Product reviews') }} ({{ reviewsTotalCount }})
                </BaseHeading>
                <div class="summary-block">
                    <div class="rating">
                        <RatingStars
                            :rating="averageRating"
                            :is-large="true"
                            class="stars"
                        />
                        <BaseHeading theme="h3" class="average-rating">
                            {{ averageRating }} / 5
                        </BaseHeading>
                    </div>
                    <TextButton
                        v-if="hasLinkHowReviewsWork"
                        class="button"
                        @click.native="openModal()"
                    >
                        {{ $t('How do reviews work?') }}
                    </TextButton>
                </div>
            </ContainerContent>
        </div>

        <ContainerContent
            v-if="isReviewsListVisible"
            class="reviews-container"
            :class="{
                'is-product-reviews-loading': isProductReviewsLoading,
            }"
        >
            <Loader
                v-if="isProductReviewsLoading"
                height="100%"
                class="loader"
            />

            <ProductReviewsList
                :reviews="reviews.items"
                class="product-reviews-list"
            />

            <div
                :ref="REF_PRODUCT_REVIEWS_OBSERVER"
                class="reviews-bottom-observer"
            />

            <Pagination
                :current-page="currentPage"
                :total-pages="totalPages"
                :navigate-to-page="loadProductReviewsData"
                :is-disabled="isProductReviewsLoading"
                class="reviews-pagination"
                @page-changed="isProductReviewsLoading = true"
            />
        </ContainerContent>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { PRODUCT_SECTION_REVIEWS_ID } from '@configs/class-names';
import {
    PRODUCT_PAGE_REVIEWS_LIST_SIZE,
    REVIEWS_PAGINATION_SCROLL_DURATION,
} from '@configs/reviews';
import { STICKY_HEADER_HEIGHT } from '@configs/header';

import { loadReviews } from '@assets/reviews';

import TextButton from '@atoms/TextButton/TextButton';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import Loader from '@atoms/Loader/Loader';
import RatingStars from '@atoms/RatingStars/RatingStars';

import ProductReviewsList from '@molecules/ProductReviewsList/ProductReviewsList';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import Pagination from '@molecules/Pagination/Pagination';

const { mapActions: mapActionsMessages } = createNamespacedHelpers('messages');

const REF_PRODUCT_REVIEWS_OBSERVER = 'reviews-observer';

export default {
    name: 'ProductReviews',

    components: {
        TextButton,
        ContainerContent,
        ProductReviewsList,
        BaseHeading,
        RatingStars,
        Loader,
        Pagination,
    },

    props: {
        reviewsData: {
            type: Object,
            required: true,
        },

        averageRating: {
            type: Number,
            required: true,
        },

        sku: {
            type: String,
            required: true,
        },

        hasLinkHowReviewsWork: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isProductReviewsLoading: false,
            currentPage: 1,
            reviews: this.reviewsData,
            reviewsObserver: null,
        };
    },

    computed: {
        ...mapState(['isMobile']),

        reviewsTotalCount() {
            return this.reviews?.totalCountAll || 0;
        },

        totalPages() {
            return Math.ceil(
                this.reviewsTotalCount / PRODUCT_PAGE_REVIEWS_LIST_SIZE
            );
        },

        isReviewsListVisible() {
            return this.reviews?.items?.length > 0;
        },
    },

    watch: {
        reviewsData() {
            if (this.reviewsData) {
                this.reviews = this.reviewsData;
            }
        },

        isMobile: {
            immediate: true,
            async handler(value) {
                if (!value) {
                    this.removeObserver();

                    return;
                }

                await this.$nextTick();

                const element = this.$refs[REF_PRODUCT_REVIEWS_OBSERVER];

                if (!element) {
                    return;
                }

                this.reviewsObserver = new IntersectionObserver(entries => {
                    entries.forEach(async entry => {
                        if (!entry.isIntersecting) {
                            return;
                        }

                        this.reviewsObserver.unobserve(element);

                        await this.loadMoreReviews();
                        await this.$nextTick();

                        this.reviewsObserver.observe(element);
                    });
                });

                this.reviewsObserver.observe(element);
            },
        },
    },

    beforeCreate() {
        this.REF_PRODUCT_REVIEWS_OBSERVER = REF_PRODUCT_REVIEWS_OBSERVER;
    },

    beforeDestroy() {
        this.removeObserver();
    },

    mounted() {
        this.$emit('loaded');
    },

    methods: {
        ...mapActionsMessages(['addErrorMessage']),

        removeObserver() {
            this.reviewsObserver?.disconnect();
            this.reviewsObserver = null;
        },

        async fetchReviews(pageNumber) {
            this.isProductReviewsLoading = true;

            const { reviews, error: loadingError } = await loadReviews({
                app: this,
                sku: this.sku,
                currentPage: pageNumber,
                pageSize: PRODUCT_PAGE_REVIEWS_LIST_SIZE,
            });

            if (loadingError) {
                this.addErrorMessage({
                    text: this.$t(
                        // eslint-disable-next-line max-len
                        'Sorry, but we are unable to retrieve reviews at the moment. Please refresh the page and try again in a moment.'
                    ),
                });
                this.isProductReviewsLoading = false;

                return null;
            }

            this.isProductReviewsLoading = false;

            return reviews;
        },

        async loadMoreReviews() {
            if (this.currentPage >= this.totalPages) {
                return;
            }

            const pageNumber = this.currentPage + 1;

            const reviews = await this.fetchReviews(pageNumber);

            if (!reviews) {
                return;
            }

            this.currentPage = pageNumber;

            this.reviews = {
                ...this.reviews,
                items: [...this.reviews.items, ...reviews.items],
            };
        },

        async loadProductReviewsData(pageNumber) {
            const reviews = await this.fetchReviews(pageNumber);

            if (!reviews) {
                return;
            }

            this.currentPage = pageNumber;

            this.reviews = reviews;

            this.$scrollTo(
                `#${PRODUCT_SECTION_REVIEWS_ID}`,
                REVIEWS_PAGINATION_SCROLL_DURATION,
                { offset: this.isMobile ? -STICKY_HEADER_HEIGHT : -16 }
            );
        },

        openModal() {
            this.$emit('open-modal-how-opinions-work');
        },
    },
};
</script>

<style lang="scss" scoped>
.product-reviews {
    .top-container {
        @apply bg-gray8 py-4;
    }

    .summary-block {
        @apply text-center;
    }

    .average-rating {
        @apply text-l leading-l;
    }

    .summary-info {
        @apply flex flex-col;
    }

    .summary-count {
        @apply mt-2 text-xs leading-s;
    }

    .reviews-heading {
        @apply block;
    }

    .reviews-container {
        @apply block relative;
    }

    .is-product-reviews-loading {
        &:before {
            @apply bg-light absolute top-0 left-0 opacity-50 z-1 w-full h-full;
            content: '';
        }
    }

    .loader {
        @apply z-2;
    }

    .button {
        @apply text-xs leading-xs text-gray1 underline;
    }

    @screen mobile-and-tablet-only {
        .product-reviews-list {
            @apply -mx-3;
        }

        .reviews-heading {
            @apply hidden;
        }

        .reviews-pagination {
            @apply hidden;
        }

        .average-rating {
            @apply inline-block;
        }

        .average-rating,
        .button {
            @apply mt-12p;
        }

        .loader {
            @apply fixed pointer-events-none;
        }
    }

    @screen lg {
        .top-container {
            @apply py-7;
        }

        .product-reviews-list {
            @apply mt-4;
        }

        .reviews-heading {
            @apply w-full mb-3;
        }

        .summary-block {
            @apply w-full flex justify-between items-center;
        }

        .rating {
            @apply flex justify-between items-center gap-12p;
        }

        .reviews-pagination {
            @apply mt-4;
        }
    }
}
</style>
