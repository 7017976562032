import {
    AVAILABILITY_IN_STOCK,
    AVAILABILITY_OUT_OF_STOCK,
    ORGANIZATION_DEFAULT_NAME,
} from '@configs/structured-data';

import { IMAGE_TYPE_PRODUCT_512w_512h } from '@types/Image';

import StructuredDataBreadcrumbList from '@models/StructuredData/StructuredDataBreadcrumbList';
import StructuredDataListItem from '@models/StructuredData/StructuredDataListItem';
import StructuredDataProduct from '@models/StructuredData/StructuredDataProduct';
import StructuredDataBrand from '@models/StructuredData/StructuredDataBrand';
import StructuredDataAggregateRating from '@models/StructuredData/StructuredDataAggregateRating';
import StructuredDataOffer from '@models/StructuredData/StructuredDataOffer';
import StructuredDataOrganization from '@models/StructuredData/StructuredDataOrganization';
import StructuredDataReview from '@models/StructuredData/StructuredDataReview';
import StructuredDataRating from '@models/StructuredData/StructuredDataRating';

import { calculateAverageFromRatings } from '@assets/reviews';

export const getStructuredDataBreadcrumbList = (
    breadcrumbs,
    fullPath,
    absoluteUrl
) => {
    const structuredDataBreadcrumbList = new StructuredDataBreadcrumbList();

    if (!breadcrumbs?.length || typeof absoluteUrl !== 'function') {
        return structuredDataBreadcrumbList;
    }

    breadcrumbs.forEach((item, index) => {
        let url;

        if (typeof item.path !== 'undefined') {
            url = item.path;
        } else {
            url = fullPath || '';
        }

        url = absoluteUrl(url);

        structuredDataBreadcrumbList.addItem(
            new StructuredDataListItem(index + 1, item.name, url)
        );
    });

    return structuredDataBreadcrumbList;
};

export const getStructuredDataProduct = ({
    name,
    description,
    sku,
    category,
    images = [],
    brandName,
    baseUrl,
    canonicalUrl,
    price,
    currency,
    reviews,
    averageRating,
    variants = {},
}) => {
    const structuredDataProduct = new StructuredDataProduct(
        name,
        description,
        sku,
        category ? category.label : ''
    );

    if (images.length) {
        images.forEach(image => {
            if (typeof image[IMAGE_TYPE_PRODUCT_512w_512h] !== 'undefined') {
                structuredDataProduct.addImage(
                    image[IMAGE_TYPE_PRODUCT_512w_512h].url
                );
            }
        });
    }

    const ratingCount = reviews && reviews.totalCount ? reviews.totalCount : 0;
    const reviewCount = reviews && reviews.items ? reviews.items.length : 0;

    if (averageRating > 0 && ratingCount > 0) {
        const aggregateRating = new StructuredDataAggregateRating(
            averageRating,
            ratingCount,
            reviewCount
        );

        structuredDataProduct.addAggregateRating(aggregateRating);
    }

    if (reviewCount) {
        reviews.items.forEach(reviewItem => {
            const structuredDataReview = new StructuredDataReview(
                reviewItem.content
            );

            const structuredDataRating = new StructuredDataRating(
                calculateAverageFromRatings(reviewItem.ratings)
            );

            structuredDataReview.addRating(structuredDataRating);

            structuredDataProduct.addReview(structuredDataReview);
        });
    }

    if (price && price.promotional && currency) {
        const productPrice = price.promotional.amount;

        const offer = new StructuredDataOffer(
            productPrice,
            currency,
            canonicalUrl
        );

        const seller = new StructuredDataOrganization(
            ORGANIZATION_DEFAULT_NAME,
            baseUrl
        );

        const stockQuantity = Object.values(variants).reduce(
            (quantity, { stock_quantity }) => quantity + stock_quantity,
            0
        );

        offer.addSeller(seller);
        offer.addAvailability(
            stockQuantity > 0
                ? AVAILABILITY_IN_STOCK
                : AVAILABILITY_OUT_OF_STOCK
        );

        structuredDataProduct.addOffer(offer);
    }

    if (brandName) {
        const brand = new StructuredDataBrand(brandName);

        structuredDataProduct.addBrand(brand);
    }

    return structuredDataProduct;
};
