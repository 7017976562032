import StructuredDataBaseClass from '@models/StructuredData/StructuredDataBaseClass';
import StructuredDataOrganization from '@models/StructuredData/StructuredDataOrganization';
import { AVAILABILITIES, TYPE_OFFER } from '@configs/structured-data';

export default class StructuredDataOffer extends StructuredDataBaseClass {
    constructor(price, currency, url) {
        super(TYPE_OFFER);
        this.price = price;
        this.currency = currency;
        this.url = url;
        this.optionalFields = {};
    }

    addSeller(organisation) {
        if (!(organisation instanceof StructuredDataOrganization)) {
            throw new TypeError(
                'Seller must be instance of StructuredDataOrganization'
            );
        }

        this.optionalFields.seller = organisation.build();
    }

    addAvailability(availability) {
        if (!Object.values(AVAILABILITIES).includes(availability)) {
            throw new TypeError('Availability must have proper value');
        }

        this.optionalFields.availability = availability;
    }

    build() {
        return {
            ...super.build(),
            price: this.price,
            priceCurrency: this.currency,
            url: this.url,
            itemCondition: 'https://schema.org/NewCondition',
            ...this.optionalFields,
        };
    }
}
