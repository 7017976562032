var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"duty-info-container",class:{ 'duty-text-not-extended': !_vm.extended }},[_c('BaseParagraph',{staticClass:"duty-text"},[_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$t(
                    // eslint-disable-next-line max-len
                    'There will be an additional payment for duty and VAT, the approximate amount is +{amount}*',
                    { amount: _vm.amount }
                )
            )}})]),_vm._v(" "),(_vm.extended)?_c('BaseParagraph',{staticClass:"duty-subtext"},[_vm._v("\n        "+_vm._s(_vm.$t('Payment in cash to the courier upon delivery of the order'))+"\n    ")]):_vm._e(),_vm._v(" "),_c('BaseTooltip',{attrs:{"position":_vm.tooltipPosition,"padding":"10px","is-mobile":_vm.isMobile,"tooltip-container-class":"duty-tooltip-container"},scopedSlots:_vm._u([(_vm.extended)?{key:"tooltipContent",fn:function(){return [_c('BaseParagraph',{staticClass:"tooltip-content"},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.$t('Order < {orderValue}€: no charge.', {
                            orderValue: _vm.ORDER_AMOUNT,
                        })
                    )}})]),_vm._v(" "),_c('br'),_vm._v(" "),_c('BaseParagraph',{staticClass:"tooltip-content"},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.$t(
                            // eslint-disable-next-line max-len
                            'Order > {orderValue}€: duty 10% on the amount above {orderValue}€.',
                            {
                                orderValue: _vm.ORDER_AMOUNT,
                            }
                        )
                    )}})]),_vm._v(" "),_c('br'),_vm._v(" "),_c('BaseParagraph',{staticClass:"tooltip-content"},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.$t(
                            'VAT 20% on the amount above {orderValue}€ + duty.',
                            {
                                orderValue: _vm.ORDER_AMOUNT,
                            }
                        )
                    )}})])]},proxy:true}:{key:"tooltipContent",fn:function(){return [_c('BaseParagraph',{staticClass:"tooltip-content"},[_vm._v("\n                "+_vm._s(_vm.$t(
                        // eslint-disable-next-line max-len
                        'You do not pay customs duty if the value of one package does not exceed {orderValue}€.',
                        {
                            orderValue: _vm.ORDER_AMOUNT,
                        }
                    ))+"\n            ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('BaseLinkStatic',{staticClass:"duty-info-link",attrs:{"path":_vm.$tPath('duty-cost'),"target":'_blank'}},[_vm._v("\n                "+_vm._s(_vm.$t('Detailed information on duty charges'))+"\n            ")])]},proxy:true}],null,true)},[_c('span',{staticClass:"duty-details-text duty-text"},[_vm._v("\n            "+_vm._s(_vm.$t('Details'))+"\n        ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }