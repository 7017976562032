import { TYPE_ORGANIZATION } from '@configs/structured-data';
import StructuredDataBaseClass from './StructuredDataBaseClass';

export default class StructuredDataOrganization extends StructuredDataBaseClass {
    constructor(name, url) {
        super(TYPE_ORGANIZATION);
        this.name = name;
        this.url = url;
    }

    build() {
        return {
            ...super.build(),
            name: this.name,
            url: this.url,
        };
    }
}
