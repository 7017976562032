export const ORGANIZATION_DEFAULT_NAME = 'Eobuwie';
export const BEST_RATING = 5;
export const PRODUCT_REVIEWS_LIMIT = 10;
export const AVAILABILITY_DISCONTINUED = 'https://schema.org/Discontinued';
export const AVAILABILITY_IN_STOCK = 'https://schema.org/InStock';
export const AVAILABILITY_IN_STORE_ONLY = 'https://schema.org/InStoreOnly';
export const AVAILABILITY_LIMITED_AVAILABILITY =
    'https://schema.org/LimitedAvailability';
export const AVAILABILITY_ONLINE_ONLY = 'https://schema.org/OnlineOnly';
export const AVAILABILITY_OUT_OF_STOCK = 'https://schema.org/OutOfStock';
export const AVAILABILITY_PRE_ORDER = 'https://schema.org/PreOrder';
export const AVAILABILITY_PRE_SALE = 'https://schema.org/PreSale';
export const AVAILABILITY_SOLD_OUT = 'https://schema.org/SoldOut';

export const AVAILABILITIES = {
    AVAILABILITY_DISCONTINUED,
    AVAILABILITY_IN_STOCK,
    AVAILABILITY_IN_STORE_ONLY,
    AVAILABILITY_LIMITED_AVAILABILITY,
    AVAILABILITY_ONLINE_ONLY,
    AVAILABILITY_OUT_OF_STOCK,
    AVAILABILITY_PRE_ORDER,
    AVAILABILITY_PRE_SALE,
    AVAILABILITY_SOLD_OUT,
};

export const TYPE_ORGANIZATION = 'Organization';
export const TYPE_PRODUCT = 'Product';
export const TYPE_OFFER = 'Offer';
export const TYPE_AGGREGATE_RATING = 'AggregateRating';
export const TYPE_BRAND = 'Brand';
export const TYPE_BREADCRUMBS = 'BreadcrumbList';
export const TYPE_LIST_ITEM = 'ListItem';
export const TYPE_REVIEW = 'Review';
export const TYPE_RATING = 'Rating';

export const TYPES = {
    TYPE_ORGANIZATION,
    TYPE_PRODUCT,
    TYPE_OFFER,
    TYPE_AGGREGATE_RATING,
    TYPE_BRAND,
    TYPE_BREADCRUMBS,
    TYPE_LIST_ITEM,
    TYPE_REVIEW,
    TYPE_RATING,
};
