import { BEST_RATING, TYPE_RATING } from '@configs/structured-data';

import StructuredDataBaseClass from '@models/StructuredData/StructuredDataBaseClass';

export default class StructuredDataRating extends StructuredDataBaseClass {
    constructor(ratingValue) {
        super(TYPE_RATING);
        this.ratingValue = ratingValue;
    }

    build() {
        return {
            ...super.build(),
            ratingValue: this.ratingValue,
            bestRating: BEST_RATING,
        };
    }
}
