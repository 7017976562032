import StructuredDataBrand from '@models/StructuredData/StructuredDataBrand';
import StructuredDataBaseClass from '@models/StructuredData/StructuredDataBaseClass';
import StructuredDataOffer from '@models/StructuredData/StructuredDataOffer';
import StructuredDataAggregateRating from '@models/StructuredData/StructuredDataAggregateRating';
import { TYPE_PRODUCT, PRODUCT_REVIEWS_LIMIT } from '@configs/structured-data';
import StructuredDataReview from '@models/StructuredData/StructuredDataReview';

export default class StructuredDataProduct extends StructuredDataBaseClass {
    constructor(name, description, sku, category, gtin13) {
        super(TYPE_PRODUCT);
        this.name = name;
        this.description = description;
        this.productId = sku;
        this.sku = sku;
        this.category = category;
        this.gtin13 = gtin13;
        this.images = [];
        this.optionalFields = {
            review: [],
        };
    }

    addImage(image) {
        this.images.push(image);
    }

    addReview(review) {
        if (this.optionalFields.review.length < PRODUCT_REVIEWS_LIMIT) {
            if (!(review instanceof StructuredDataReview)) {
                throw new TypeError(
                    'Review must be instance of StructuredDataReview'
                );
            }

            this.optionalFields.review.push(review.build());
        }
    }

    addBrand(brand) {
        if (!(brand instanceof StructuredDataBrand)) {
            throw new TypeError(
                'Brand must be instance of StructuredDataBrand'
            );
        }

        this.optionalFields.brand = brand.build();
    }

    addOffer(offer) {
        if (!(offer instanceof StructuredDataOffer)) {
            throw new TypeError(
                'Offer must be instance of StructuredDataOffer'
            );
        }

        this.optionalFields.offers = offer.build();
    }

    addAggregateRating(rating) {
        if (!(rating instanceof StructuredDataAggregateRating)) {
            throw new TypeError(
                'Rating must be instance of StructuredDataAggregateRating'
            );
        }

        this.optionalFields.aggregateRating = rating.build();
    }

    build() {
        return {
            ...super.build(),
            name: this.name,
            description: this.description,
            productID: this.productId,
            sku: this.sku,
            category: this.category,
            gtin13: this.gtin13,
            image: this.images,
            ...this.optionalFields,
        };
    }
}
