<template>
    <ButtonSmall
        class="pagination-item"
        :class="{
            current: isCurrent,
            static: isStatic,
        }"
        :variant="buttonVariant"
        :to="createPath(pageNumber, rangeSeparator)"
        :disabled="disabled || isCurrent"
        :tabindex="tabIndex"
        v-on="$listeners"
    >
        {{ pageNumber }}
    </ButtonSmall>
</template>

<script>
import {
    ButtonSmall,
    BUTTON_SMALL_VARIANTS,
} from '@eobuwie-ui/components/ButtonSmall/v1';

export default {
    name: 'PaginationItem',

    components: {
        ButtonSmall,
    },

    props: {
        pageNumber: {
            type: [Number, String],
            required: true,
        },

        currentPage: {
            type: Number,
            required: true,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        createPath: {
            type: Function,
            default: () => undefined,
        },

        rangeSeparator: {
            type: String,
            default: '...',
        },
    },

    computed: {
        isStatic() {
            return typeof this.pageNumber === 'string';
        },

        isCurrent() {
            const parsedPageNumber = parseInt(this.pageNumber, 10);

            return (
                !Number.isNaN(parsedPageNumber) &&
                parsedPageNumber === this.currentPage
            );
        },

        tabIndex() {
            return this.isStatic || this.isCurrent ? -1 : 0;
        },

        buttonVariant() {
            return this.disabled || this.isCurrent
                ? BUTTON_SMALL_VARIANTS.PRIMARY
                : BUTTON_SMALL_VARIANTS.PRIMARY_ALTERNATIVE;
        },
    },
};
</script>

<style lang="scss" scoped>
.pagination-item {
    @apply mr-2 min-w-xl min-h-xl;

    &:last-child {
        @apply mr-0;
    }

    &.current,
    &.static {
        @apply pointer-events-none;
    }

    @screen lg {
        @apply min-w-xl min-h-xl;
    }
}
</style>
