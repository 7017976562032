<template>
    <div class="guarantee-text">
        <BaseHeading class="guarantee-heading">
            {{ $t('Guarantee of product originality') }}
        </BaseHeading>
        <BaseParagraph class="paragraph">
            {{
                $t(
                    // eslint-disable-next-line max-len
                    'All products from the eobuwie.pl offer are original and come directly from manufacturers or verified distributors.'
                )
            }}
        </BaseParagraph>
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

export default {
    name: 'ProductGuaranteeOfOriginality',

    components: {
        BaseHeading,
        BaseParagraph,
    },
};
</script>

<style lang="scss" scoped>
.guarantee-text {
    @apply bg-gray8 p-3 mt-4;

    .guarantee-heading {
        @apply block mb-2 text-s leading-s font-bold;
    }

    .paragraph {
        @apply text-s leading-s text-gray1;
    }
}

@screen lg {
    .guarantee-text {
        @apply mb-0 mx-0 mt-5;
    }
}
</style>
