<template>
    <div :data-test-id="PRODUCT_PRICE" class="product-details-header-price">
        <div
            :data-test-id="FINAL_PRICE"
            :class="{
                promotional:
                    discountPercent &&
                    (!isOmnibusStrictEnabled || priceOmnibus),
            }"
            class="final-price"
        >
            {{ formatLocalePrice(pricePromotional) }}
        </div>

        <div
            v-if="
                !isOmnibusStrictEnabled &&
                priceRegular !== pricePromotional &&
                isRegularPriceEnabled
            "
            :data-test-id="REGULAR_PRICE"
            class="price-with-discount"
        >
            {{ formatLocalePrice(priceRegular) }}
        </div>

        <slot name="after-price" />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
    IS_OMNIBUS_STRICT_ENABLED,
    IS_REGULAR_PRICE_ENABLED,
} from '@localeConfig/keys';

import {
    REGULAR_PRICE,
    FINAL_PRICE,
    PRODUCT_PRICE,
} from '@types/AutomaticTestIDs';

import { cutOffTheCents } from '@assets/cut-off-the-cents';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'ProductDetailsHeaderPrice',

    props: {
        priceRegular: {
            type: String,
            default: null,
        },

        pricePromotional: {
            type: String,
            required: true,
        },

        priceOmnibus: {
            type: Number,
            default: 0,
        },

        discountPercent: {
            type: String,
            default: null,
        },
    },

    computed: {
        ...mapConfigGetters(['localeConfigByKey']),

        isRegularPriceEnabled() {
            return this.localeConfigByKey(IS_REGULAR_PRICE_ENABLED);
        },

        isOmnibusStrictEnabled() {
            return this.localeConfigByKey(IS_OMNIBUS_STRICT_ENABLED);
        },
    },

    beforeCreate() {
        this.REGULAR_PRICE = REGULAR_PRICE;
        this.FINAL_PRICE = FINAL_PRICE;
        this.PRODUCT_PRICE = PRODUCT_PRICE;
    },

    methods: {
        formatLocalePrice(price) {
            return cutOffTheCents(price, this.$getLocaleConfigByKey);
        },
    },
};
</script>

<style lang="scss" scoped>
.product-details-header-price {
    .price-with-discount {
        @apply inline-block text-text3 line-through;
    }

    .final-price {
        @apply inline-block text-xxl leading-xxl pr-2;

        &.promotional {
            @apply text-red1;
        }
    }
}
</style>
