<template>
    <ContainerContent
        :align-left="true"
        class="popular-brands-and-categories-new"
    >
        <PopularCategories
            :categories="categories"
            :is-mobile="isMobile"
            class="seo-categories"
        />
        <PopularBrands :brands="brands" class="seo-brands" />
    </ContainerContent>
</template>

<script>
import PopularCategories from '@molecules/PopularCategories/PopularCategories';
import PopularBrands from '@molecules/PopularBrands/PopularBrands';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';

export default {
    name: 'PopularBrandsAndCategoriesNew',

    components: {
        PopularBrands,
        PopularCategories,
        ContainerContent,
    },

    props: {
        brands: {
            type: Array,
            required: true,
        },

        categories: {
            type: Array,
            required: true,
        },

        isMobile: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.popular-brands-and-categories-new {
    @apply overflow-hidden;

    .seo-categories {
        @apply pb-5 mt-5 border-b-1 border-gray4;
    }

    .seo-brands {
        @apply mt-5 mb-5;
    }

    @screen lg {
        .seo-categories {
            @apply pb-5 mt-5 border-b-1 border-gray4;
        }

        .seo-brands {
            @apply mt-5 mb-5;
        }
    }
}
</style>
