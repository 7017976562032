export const convertDisplaySizeToFitMateSize = label => {
    const [wholeNumber, fraction] = label.split(' ');

    if (!fraction) {
        return wholeNumber;
    }

    const [numerator, denominator] = fraction
        .split('/')
        .map(part => parseInt(part, 10));

    const decimalFraction = String(numerator / denominator).slice(2, 4);

    return `${wholeNumber}.${decimalFraction}`;
};
