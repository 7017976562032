<template>
    <ContainerContent class="links-list-section">
        <ExpandCollapse
            class="block-expand-collapse"
            :is-expanded-on-desktop="true"
            @expand-change="emitAccordionEvents($event)"
        >
            <template #header>
                <span class="popular-block-heading">
                    {{ title }}
                </span>
            </template>
            <template #icon-plus>
                <SvgIcon width="20px" height="20px" class="icon">
                    <Down />
                </SvgIcon>
            </template>
            <template #icon-minus>
                <SvgIcon width="20px" height="20px" class="icon">
                    <Up />
                </SvgIcon>
            </template>
            <StaticHtmlLinkHandler @navigate="onNavigateClick($event)">
                <StaticBlock :content="content" class="popular-block" />
            </StaticHtmlLinkHandler>
        </ExpandCollapse>
    </ContainerContent>
</template>

<script>
import {
    PSB_POPULAR_BRANDS,
    PSB_POPULAR_CATEGORIES,
} from '@configs/popular-blocks';

import { ON_ELEMENT_ACTION } from '@analytics-modules/product/types/Events';
import { MODULE_NAME as PRODUCT_MODULE_NAME } from '@analytics-modules/product/meta';
import { CATEGORIES as PRODUCT_CATEGORIES } from '@analytics-modules/product/types/Categories';
import { ACTIONS as GLOBAL_ACTIONS } from '@analytics-module/types/Actions';
import { ACTIONS as PRODUCT_ACTIONS } from '@analytics-modules/product/types/Actions';

import Up from '@static/icons/24px/up.svg?inline';
import Down from '@static/icons/24px/down.svg?inline';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import ExpandCollapse from '@atoms/ExpandCollapse/ExpandCollapse';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import StaticHtmlLinkHandler from '@molecules/StaticHtmlLinkHandler/StaticHtmlLinkHandler';

export default {
    name: 'LinksListSection',

    components: {
        ContainerContent,
        SvgIcon,
        Up,
        Down,
        ExpandCollapse,
        StaticBlock,
        StaticHtmlLinkHandler,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        content: {
            type: String,
            required: true,
        },

        identifier: {
            type: String,
            required: true,
        },
    },

    methods: {
        onNavigateClick(event) {
            const dataLinkText = event?.target?.outerText;

            if (this.identifier === PSB_POPULAR_BRANDS) {
                this.$analytics.moduleEmit(
                    PRODUCT_MODULE_NAME,
                    ON_ELEMENT_ACTION,
                    {
                        eventCategory:
                            PRODUCT_CATEGORIES.PRODUCT_LIST_FOOTER_CONTENT,
                        eventAction: PRODUCT_ACTIONS.CLICK_BRAND,
                        eventLabel: dataLinkText,
                    }
                );

                return;
            }

            if (this.identifier === PSB_POPULAR_CATEGORIES) {
                this.$analytics.moduleEmit(
                    PRODUCT_MODULE_NAME,
                    ON_ELEMENT_ACTION,
                    {
                        eventCategory:
                            PRODUCT_CATEGORIES.PRODUCT_LIST_FOOTER_CONTENT,
                        eventAction: PRODUCT_ACTIONS.CLICK_CATEGORY,
                        eventLabel: dataLinkText,
                    }
                );
            }
        },

        emitAccordionEvents(isAccordionExpanded) {
            this.$analytics.moduleEmit(PRODUCT_MODULE_NAME, ON_ELEMENT_ACTION, {
                eventCategory: PRODUCT_CATEGORIES.PRODUCT_LIST_FOOTER_CONTENT,
                eventAction:
                    isAccordionExpanded === true
                        ? GLOBAL_ACTIONS.OPEN
                        : GLOBAL_ACTIONS.CLOSE,
                eventLabel: this.identifier,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.links-list-section {
    &:deep() {
        .popular-block-heading {
            @apply font-semibold;
        }

        .text-link {
            @apply w-full h-7 flex items-center;

            &:hover {
                @apply underline;
            }
        }

        .content {
            @apply mt-3;
        }
    }

    @screen mobile-and-tablet-only {
        .block-expand-collapse {
            @apply w-full border-b-1 border-solid border-gray5 py-3;
        }

        .popular-block {
            @apply -mb-12p;
        }
    }

    @screen lg {
        .block-expand-collapse {
            @apply -mb-2;
        }

        &:deep() {
            .content {
                @apply mt-5;
            }

            .expand-collapse {
                .header {
                    @apply justify-center;
                }
            }

            .popular-block-list {
                @apply flex flex-wrap -mx-12p;
            }

            .popular-block-item {
                @apply w-1/5 px-12p pb-2;
            }

            .text-link {
                @apply h-auto w-auto inline;
            }
        }

        .popular-block-heading {
            @apply text-xl leading-xl;
        }
    }
}
</style>
