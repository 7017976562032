<template>
    <div
        class="duty-info-container"
        :class="{ 'duty-text-not-extended': !extended }"
    >
        <BaseParagraph class="duty-text">
            <span
                v-html="
                    $t(
                        // eslint-disable-next-line max-len
                        'There will be an additional payment for duty and VAT, the approximate amount is +{amount}*',
                        { amount }
                    )
                "
            />
        </BaseParagraph>

        <BaseParagraph v-if="extended" class="duty-subtext">
            {{
                $t('Payment in cash to the courier upon delivery of the order')
            }}
        </BaseParagraph>

        <BaseTooltip
            :position="tooltipPosition"
            padding="10px"
            :is-mobile="isMobile"
            tooltip-container-class="duty-tooltip-container"
        >
            <span class="duty-details-text duty-text">
                {{ $t('Details') }}
            </span>
            <template v-if="extended" #tooltipContent>
                <BaseParagraph class="tooltip-content">
                    <span
                        v-html="
                            $t('Order < {orderValue}€: no charge.', {
                                orderValue: ORDER_AMOUNT,
                            })
                        "
                    />
                </BaseParagraph>
                <br />
                <BaseParagraph class="tooltip-content">
                    <span
                        v-html="
                            $t(
                                // eslint-disable-next-line max-len
                                'Order > {orderValue}€: duty 10% on the amount above {orderValue}€.',
                                {
                                    orderValue: ORDER_AMOUNT,
                                }
                            )
                        "
                    />
                </BaseParagraph>
                <br />
                <BaseParagraph class="tooltip-content">
                    <span
                        v-html="
                            $t(
                                'VAT 20% on the amount above {orderValue}€ + duty.',
                                {
                                    orderValue: ORDER_AMOUNT,
                                }
                            )
                        "
                    />
                </BaseParagraph>
            </template>

            <template v-else #tooltipContent>
                <BaseParagraph class="tooltip-content">
                    {{
                        $t(
                            // eslint-disable-next-line max-len
                            'You do not pay customs duty if the value of one package does not exceed {orderValue}€.',
                            {
                                orderValue: ORDER_AMOUNT,
                            }
                        )
                    }}
                </BaseParagraph>
                <br />
                <BaseLinkStatic
                    class="duty-info-link"
                    :path="$tPath('duty-cost')"
                    :target="'_blank'"
                >
                    {{ $t('Detailed information on duty charges') }}
                </BaseLinkStatic>
            </template>
        </BaseTooltip>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { POSITION_BOTTOM, POSITION_RIGHT } from '@types/Tooltip';

import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BaseTooltip from '@atoms/BaseTooltip/BaseTooltip';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

export default {
    name: 'DutyInfo',

    components: {
        BaseParagraph,
        BaseTooltip,
        BaseLinkStatic,
    },

    props: {
        amount: {
            type: String,
            default: '',
        },

        extended: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        ...mapState(['isMobile']),

        tooltipPosition() {
            return this.isMobile ? POSITION_RIGHT : POSITION_BOTTOM;
        },
    },

    beforeCreate() {
        this.POSITION_BOTTOM = POSITION_BOTTOM;
        this.POSITION_RIGHT = POSITION_RIGHT;
        this.ORDER_AMOUNT = 150;
    },
};
</script>

<style lang="scss" scoped>
.duty-info-container {
    @apply my-3;

    &.duty-text-not-extended {
        .duty-text {
            @apply text-gray3 text-r leading-r;
        }
    }

    .duty-details-text {
        @apply cursor-pointer font-bold inline;
    }

    .tooltip-content {
        @apply text-light;
    }

    .duty-info-link {
        @apply text-s leading-s font-bold;
    }

    .duty-text {
        @apply block;
    }

    .duty-details-text {
        @apply text-s leading-s text-text;
    }

    .duty-subtext,
    .duty-details-text {
        @apply inline;
    }

    &:deep() {
        .base-tooltip {
            @apply block;
        }

        .duty-tooltip-container {
            width: theme('customVariables.tooltip.width');
        }

        &.duty-text-not-extended {
            .duty-amount-text {
                @apply hidden;
            }
        }
    }

    @screen lg {
        &:deep() {
            .base-tooltip,
            .base-tooltip-wrapper {
                @apply inline-block;
            }
        }

        &.duty-text-not-extended {
            .duty-text {
                @apply inline;
            }
        }
    }
}
</style>
