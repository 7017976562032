<template>
    <SideModal
        :is-open="isOpen"
        v-bind="sideModalProps"
        :label="headings[currentStep]"
        :class="{ 'multi-step-side-modal-with-back': canGoBack }"
        class="multi-step-side-modal"
        @close="close()"
    >
        <template v-if="canGoBack && showBackArrow" #header-left-icon>
            <ButtonIcon
                :data-test-id="CLOSE_SIDE_MODAL"
                :variant="BUTTON_ICON_VARIANT"
                class="go-back-button"
                @click.native="previousStep()"
            >
                <Icon :icon="ChevronLeft" />
            </ButtonIcon>
        </template>

        <template #header>
            <slot name="header" v-bind="publicApi" />
        </template>

        <template #default>
            <slot name="default" v-bind="publicApi" />
        </template>

        <template #footer>
            <slot name="footer" v-bind="publicApi" />
        </template>
    </SideModal>
</template>

<script>
import { CLOSE_SIDE_MODAL } from '@types/AutomaticTestIDs';

import { getProps, getSubsetOfComponentProps } from '@assets/component';

import SideModal from '@molecules/SideModal/SideModal';

import {
    ButtonIcon,
    BUTTON_ICON_VARIANTS,
} from '@eobuwie-ui/components/ButtonIcon/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';

import { ChevronLeft } from '@eobuwie-ui/icons/v2/navigation';

const SIDE_MODAL_PROPS = getProps(SideModal);

export default {
    name: 'MultiStepSideModal',

    components: {
        SideModal,
        ButtonIcon,
        Icon,
    },

    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },

        headings: {
            type: Object,
            default: () => ({}),
        },

        initStep: {
            type: Number,
            default: 1,
        },

        showBackArrow: {
            type: Boolean,
            default: true,
        },

        ...SIDE_MODAL_PROPS,
    },

    data() {
        return {
            currentStep: this.initStep,
        };
    },

    computed: {
        canGoBack() {
            return this.currentStep > 1;
        },

        publicApi() {
            return {
                currentStep: this.currentStep,
                close: this.close,
                previousStep: this.previousStep,
                nextStep: this.nextStep,
                setStep: this.setStep,
            };
        },

        sideModalProps() {
            return getSubsetOfComponentProps(
                this.$props,
                Object.keys(SIDE_MODAL_PROPS)
            );
        },
    },

    watch: {
        currentStep() {
            this.emitStepChangedEvent();
        },
    },

    beforeCreate() {
        this.BUTTON_ICON_VARIANT = BUTTON_ICON_VARIANTS.TERTIARY;
        this.ChevronLeft = ChevronLeft;
        this.CLOSE_SIDE_MODAL = CLOSE_SIDE_MODAL;
    },

    methods: {
        emitStepChangedEvent() {
            this.$emit('step-changed', this.currentStep);
        },

        changeStep(stepDiff) {
            const oldStep = this.currentStep;

            this.currentStep += stepDiff;

            this.$emit('change-step', {
                oldStep,
                newStep: this.currentStep,
            });
        },

        previousStep() {
            if (!this.canGoBack) {
                return;
            }

            this.changeStep(-1);
        },

        nextStep() {
            this.changeStep(1);
        },

        setStep(step) {
            if (step > 0) {
                this.currentStep = step;
            }
        },

        close() {
            this.$emit('close', {
                currentStep: this.currentStep,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.multi-step-side-modal {
    .go-back-button {
        @apply absolute z-1 w-7 h-7 my-1 left-4 flex justify-center items-center;
    }

    &.multi-step-side-modal-with-back {
        &:deep(.header) {
            @apply px-8 flex items-center justify-center;
        }
    }

    @screen md {
        .go-back-button {
            @apply left-18;
        }
    }
}
</style>
