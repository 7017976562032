var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-specification-item-mobile"},[(_vm.currentAttributeList.length)?_c('WithScroll',{attrs:{"is-horizontal":true,"hide-scrollbar":true,"autoscroll-on-init":true,"center-active-element":true,"autoscroll-active-class":_vm.ACTIVE_SELECTOR},scopedSlots:_vm._u([{key:"default",fn:function({ autoscrollToActiveElement }){return [_c('div',{staticClass:"tags-list"},_vm._l((_vm.currentAttributeList),function(attribute,index){return _c('BaseTag',{key:`${attribute.code}_${index}`,staticClass:"tags",class:{
                    [_vm.ACTIVE_SELECTOR]:
                        attribute.value === _vm.productDetailsContent.value,
                },attrs:{"with-icon":false,"selected":attribute.value === _vm.productDetailsContent.value},nativeOn:{"click":function($event){return _vm.updateMobileItem(attribute, autoscrollToActiveElement)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(_vm._s(attribute.value))]},proxy:true}],null,true)})}),1)]}}],null,false,3385565234)}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"details-paragraph"},[_c('BaseParagraph',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.productDetailsContent.details)}})]),_vm._v(" "),(_vm.type === _vm.FOOTWEAR_WIDTH_ATTRIBUTE_NAME)?_c('BaseLinkStatic',{staticClass:"more-details-link",attrs:{"path":_vm.$tPathKey('blog-shoe-width'),"target":"_blank"}},[_vm._v("\n            "+_vm._s(_vm.$t('Find out more'))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.type === _vm.TECHNOLOGY_ATTRIBUTE_NAME)?_c('BaseLinkStatic',{staticClass:"more-details-link",attrs:{"path":_vm.productDetailsContent.url,"target":"_blank"},nativeOn:{"click":function($event){return _vm.$emit('click-link', {
                    code: _vm.TECHNOLOGY_ATTRIBUTE_NAME,
                    value: _vm.productDetailsContent.value,
                })}}},[_vm._v("\n            "+_vm._s(_vm.$t('Show products with this technology'))+"\n        ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }