<template>
    <div
        v-if="isLabelVisible"
        :class="{ 'has-badge-promo': !!hasContent }"
        class="marketing-action-label"
    >
        <BadgePromoWide
            :background-color="promoLabel.bgColor"
            :color="promoLabel.textColor"
            :label="promoLabel.formattedContent"
            :description="`· ${$t('Valid until')}`"
            :variant="BADGE_PROMO_WIDE_VARIANT"
            class="promotion-badge"
        >
            <template #timer>
                <Timer
                    :now="TIME_NOW"
                    :date="endDateObject"
                    :variant="TIMER_VARIANTS.DATE_BASIC"
                    :size="TIMER_SIZES.S"
                    :color="promoLabel.textColor"
                    @stop="hideLabel()"
                />
            </template>
        </BadgePromoWide>

        <BadgePromoDescription
            v-if="hasContent"
            :content="content"
            :color="backgroundColor"
            :link-text="promoLabel.linkText"
            :link-url="promoLabel.linkUrl"
            @click="linkClick()"
        >
            <template v-if="promoLabel.code" #code>
                <PromoCode :code="promoLabel.code" @click="codeClick()" />
            </template>
            <template v-else-if="isTimeCounterVisible" #timer>
                <Timer
                    :now="TIME_NOW"
                    :date="endDateObject"
                    :variant="TIMER_VARIANTS.TIME_BASIC"
                    :size="TIMER_SIZES.M"
                    :labels="TIMER_LABELS"
                    @stop="hideLabel()"
                />
            </template>
        </BadgePromoDescription>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import DateHelper from '@models/DateHelper/DateHelper';

import { FORMAT_YYYYMMDD_HHMM } from '@types/DateFormat';

import { DAY_IN_MILISECONDS } from '@assets/date';

import { ON_ELEMENT_ACTION } from '@analytics-module/modules/product/types/Events';
import {
    CLICK_LINK,
    COPY_CODE,
} from '@analytics-module/modules/product/types/Actions';
import { PRODUCT_BADGE_PROMO } from '@analytics-module/modules/product/types/Categories';
import { MODULE_NAME as PRODUCT_MODULE_NAME } from '@analytics-module/modules/product/meta';

import { BadgePromoDescription } from '@eobuwie-ui/components/BadgePromoDescription/v1';
import {
    BadgePromoWide,
    BADGE_PROMO_WIDE_VARIANTS,
} from '@eobuwie-ui/components/BadgePromoWide/v1';
import { PromoCode } from '@eobuwie-ui/components/PromoCode/v1';
import {
    Timer,
    TIMER_VARIANTS,
    TIMER_SIZES,
} from '@eobuwie-ui/components/Timer/v1';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'MarketingActionLabel',

    components: {
        BadgePromoWide,
        BadgePromoDescription,
        PromoCode,
        Timer,
    },

    props: {
        promoLabel: {
            type: Object,
            required: true,
        },
    },

    data() {
        return { isLabelVisible: true };
    },

    computed: {
        ...mapConfigGetters(['timezone']),

        isTimeCounterVisible() {
            return (
                this.promoLabel.isTimerEnabled &&
                this.validDuration > 0 &&
                this.validDuration <= DAY_IN_MILISECONDS
            );
        },

        validDuration() {
            return this.promoLabel.timeToEnd || 0;
        },

        content() {
            return this.promoLabel.formattedProductCardContent || null;
        },

        backgroundColor() {
            return this.promoLabel.bgColor;
        },

        endDateObject() {
            return DateHelper.toJSDate(
                this.dateHelper.createDateFromStringInTimezone(
                    this.promoLabel.endDate
                )
            );
        },

        hasContent() {
            const { code, linkText, linkUrl } = this.promoLabel || {};

            return (
                !!this.content ||
                (this.isTimeCounterVisible && !code) ||
                code ||
                (linkText && linkUrl)
            );
        },
    },

    beforeCreate() {
        this.TIMER_VARIANTS = TIMER_VARIANTS;
        this.TIMER_SIZES = TIMER_SIZES;
        this.BADGE_PROMO_WIDE_VARIANT = BADGE_PROMO_WIDE_VARIANTS.M;
    },

    created() {
        this.dateHelper = new DateHelper(this.timezone, FORMAT_YYYYMMDD_HHMM);
        this.TIME_NOW = DateHelper.toJSDate(
            this.dateHelper.createCurrentDateInTimezone()
        );

        this.TIMER_LABELS = {
            days: this.$t('days'),
            hours: this.$t('hours'),
            minutes: this.$t('minutes'),
            seconds: this.$t('seconds'),
        };
    },

    methods: {
        hideLabel() {
            this.isLabelVisible = false;
        },

        linkClick() {
            this.$analytics.moduleEmit(PRODUCT_MODULE_NAME, ON_ELEMENT_ACTION, {
                eventCategory: PRODUCT_BADGE_PROMO,
                eventAction: CLICK_LINK,
                eventLabel: this.promoLabel.badgeContent,
            });
        },

        codeClick() {
            this.$analytics.moduleEmit(PRODUCT_MODULE_NAME, ON_ELEMENT_ACTION, {
                eventCategory: PRODUCT_BADGE_PROMO,
                eventAction: COPY_CODE,
                eventLabel: this.promoLabel.code,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-action-label {
    @apply flex flex-col gap-y-ui-3;

    @screen mobile-and-tablet-only {
        &:not(.has-badge-promo) {
            @apply hidden;
        }

        .promotion-badge {
            @apply hidden;
        }
    }

    @screen ui-desktop {
        &.has-badge-promo {
            @apply relative z-ui-0;
            @apply pt-ui-7;

            .promotion-badge {
                @apply absolute top-ui-0 left-ui-0;
                @apply w-ui-percent-100;
                @apply z-ui-1;
            }
        }
    }
}
</style>
