<template>
    <div class="popular-seo-brands">
        <BaseHeading class="heading">
            {{ $t('Popular brands in this category') }}
        </BaseHeading>

        <div class="links-grid">
            <div
                v-for="({ url, label }, index) in brands"
                :key="`seo-brands-${url}-${label}`"
                :class="{ 'hidden-on-mobile': isHiddenOnMobile(index) }"
            >
                <BaseLinkStatic :path="url" class="seo-link">
                    {{ label }}
                </BaseLinkStatic>
            </div>
        </div>
        <ButtonComponent
            v-if="areBrandsHidden"
            :variant="BUTTON_VARIANT"
            class="show-more-button"
            @click="showAllBrands()"
        >
            <template #icon>
                <Icon :icon="Add" width="20px" height="20px" />
            </template>
            {{ $t('Show more') }}
        </ButtonComponent>
    </div>
</template>

<script>
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import { Add } from '@eobuwie-ui/icons/v1/other';

import {
    ButtonComponent,
    BUTTON_COMPONENT_VARIANTS,
} from '@eobuwie-ui/components/ButtonComponent/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';

const NUMBER_OF_ELEMENTS_SHOWN_ON_MOBILE = 12;

export default {
    name: 'PopularBrands',

    components: {
        BaseHeading,
        BaseLinkStatic,
        Icon,
        ButtonComponent,
    },

    props: {
        brands: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            areBrandsHidden: true,
        };
    },

    beforeCreate() {
        this.BUTTON_VARIANT = BUTTON_COMPONENT_VARIANTS.PRIMARY_ACCENT;
        this.Add = Add;
    },

    methods: {
        isHiddenOnMobile(index) {
            return (
                this.areBrandsHidden &&
                index + 1 > NUMBER_OF_ELEMENTS_SHOWN_ON_MOBILE
            );
        },

        showAllBrands() {
            this.areBrandsHidden = false;
        },
    },
};
</script>

<style scoped lang="scss">
.popular-seo-brands {
    @apply w-full;

    .heading {
        @apply leading-m text-m;
    }

    .links-grid {
        @apply grid mt-20p leading-s text-s gap-y-12p;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .seo-link {
        &:hover {
            @apply no-underline text-gray2;
        }
    }

    .show-more-button {
        @apply mt-20p;
    }

    .hidden-on-mobile {
        @apply hidden;
    }

    @screen md {
        .links-grid {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    @screen lg {
        .heading {
            @apply text-l leading-l;
        }

        .hidden-on-mobile {
            @apply block;
        }

        .show-more-button {
            @apply hidden;
        }

        .links-grid {
            @apply mt-5 leading-r text-r;
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }

    @screen xl {
        .links-grid {
            grid-template-columns: repeat(6, minmax(0, 1fr));
        }
    }
}
</style>
