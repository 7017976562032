var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Observer',{ref:"observer",staticClass:"display",class:{
        'display-template':
            _vm.display.displayFormat === 'ImageTemplateDisplayResponse',
    },style:(_vm.style),attrs:{"options":_vm.OBSERVER_OPTIONS,"observe-once":true},on:{"intersect":function($event){return _vm.viewContent($event)}}},[(_vm.pictureSrc)?_c('BaseLinkStatic',{staticClass:"display-link",attrs:{"path":_vm.redirectUrl},nativeOn:{"click":[function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.onClickDisplay(_vm.LEFT_CLICK)},function($event){if(!$event.ctrlKey)return null;if($event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.onClickDisplay(_vm.RIGHT_CLICK)}],"mouseup":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;if('button' in $event && $event.button !== 1)return null;return _vm.onClickDisplay(_vm.MIDDLE_CLICK)},"contextmenu":function($event){return _vm.onClickDisplay(_vm.RIGHT_CLICK)}}},[(_vm.display.displayFormat === 'ImageTemplateDisplayResponse')?_c('div',{staticClass:"template"},[_c('div',{staticClass:"column content",style:({
                    backgroundColor:
                        _vm.display.templateParams.backgroundColor || 'grey',
                })},[_c('section',[_c('p',{staticClass:"title"},[_c('HeaderText',{attrs:{"tag":"strong","size":"m","variant":"alternative"}},[_vm._v("\n                            "+_vm._s(_vm.display.templateParams.title)+"\n                        ")])],1),_vm._v(" "),_c('BodyText',{attrs:{"color":"alternative"}},[_vm._v("\n                        "+_vm._s(_vm.display.templateParams.subtitle)+"\n                    ")]),_vm._v(" "),_c('ButtonSmall',{attrs:{"variant":"secondary-alternative"}},[_vm._v("\n                        "+_vm._s(_vm.$te(_vm.display.templateParams.cta)
                                ? _vm.$t(_vm.display.templateParams.cta)
                                : _vm.$t('Show more'))+"\n                    ")])],1)]),_vm._v(" "),_c('div',{staticClass:"column image"},[_c('BasePicture',{attrs:{"width":1,"height":1,"src":_vm.pictureSrc,"sources":_vm.pictureSources,"sources-with-media":_vm.sourcesWithMedia,"alt":_vm.alt},on:{"load":function($event){_vm.isImageLoaded = true}}})],1)]):(_vm.pictureSrc)?_c('BasePicture',{class:_vm.breakpointMap === _vm.DISPLAY_BREAKPOINT_MOBILE
                    ? 'aspect-breakpoint-mobile'
                    : 'aspect-breakpoint-tablet',attrs:{"width":1,"height":1,"src":_vm.pictureSrc,"sources":_vm.pictureSources,"sources-with-media":_vm.sourcesWithMedia,"alt":_vm.alt},on:{"load":function($event){_vm.isImageLoaded = true}}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }