var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isMobile && _vm.item.details)?_c('BaseTooltip',{staticClass:"product-specification-item-with-tooltip",attrs:{"position":_vm.POSITION_TOP,"is-mobile":_vm.isMobile,"padding":"10px","tooltip-class":"product-specification-tooltip-wrapper","tooltip-container-class":"product-specification-tooltip-container"},scopedSlots:_vm._u([{key:"tooltipContent",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.item.details)}}),_vm._v(" "),(_vm.urlLabel)?_c('BaseLinkStatic',{staticClass:"tooltip-link",attrs:{"path":_vm.item.url,"target":"_blank"}},[_vm._v("\n            "+_vm._s(_vm.urlLabel)+"\n        ")]):_vm._e()]},proxy:true}],null,false,1084931855)},[(_vm.item.url)?_c('BaseLinkStatic',{staticClass:"base-link",attrs:{"path":_vm.item.url,"label":_vm.item.value,"target":"_blank"},nativeOn:{"click":function($event){return _vm.$emit('click-link', {
                code: _vm.item.code,
                value: _vm.item.value,
            })}}}):_c('span',[_vm._v(_vm._s(_vm.item.value))])],1):(!_vm.isMobile && !_vm.item.details)?_c('div',{staticClass:"product-specification-item-with-empty-description"},[(_vm.item.url)?_c('BaseLinkStatic',{staticClass:"base-link",attrs:{"path":_vm.item.url,"label":_vm.item.value,"target":"_blank"},nativeOn:{"click":function($event){return _vm.$emit('click-link', {
                code: _vm.item.code,
                value: _vm.item.value,
            })}}}):_c('span',[_vm._v(_vm._s(_vm.item.value))])],1):_c('TextButton',{staticClass:"product-specification-mobile-button",nativeOn:{"click":function($event){return _vm.$emit('open-next-step', _vm.item)}}},[_vm._v("\n    "+_vm._s(_vm.item.value)+"\n")])
}
var staticRenderFns = []

export { render, staticRenderFns }