<template>
    <div class="product-rating" @click="onClick()">
        <RatingStars :rating="productAverageRating" />
        <BaseParagraph v-if="productAverageRating > 0" class="reviews-summary">
            <span class="product-average-rating">
                {{ productAverageRating }}
            </span>
            <span v-if="reviewsTotalCount > 0" class="reviews-total-count">
                ({{ reviewsTotalCount }})
            </span>
        </BaseParagraph>
    </div>
</template>

<script>
import { PRODUCT_SECTION_REVIEWS_ID } from '@configs/class-names';

import RatingStars from '@atoms/RatingStars/RatingStars';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import { MODAL_REVIEWS_PANEL_NAME } from '@configs/modals';

export default {
    name: 'ProductRating',

    components: {
        RatingStars,
        BaseParagraph,
    },

    props: {
        productAverageRating: {
            type: Number,
            required: true,
        },

        reviewsTotalCount: {
            type: Number,
            required: true,
        },

        isMobile: {
            type: Boolean,
            required: true,
        },
    },

    methods: {
        onClick() {
            this.$emit('click-rating');

            if (this.isMobile) {
                this.$modals.open(MODAL_REVIEWS_PANEL_NAME);

                return;
            }

            this.$scrollTo(`#${PRODUCT_SECTION_REVIEWS_ID}`);
        },
    },
};
</script>

<style lang="scss" scoped>
.product-rating {
    @apply inline-flex cursor-pointer;

    .reviews-summary {
        @apply flex ml-2;
    }

    .product-average-rating,
    .reviews-total-count {
        @apply text-xs;
    }

    .reviews-total-count {
        @apply ml-1;
    }

    .product-average-rating {
        @apply font-bold;
    }
}
</style>
