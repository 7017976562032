export const HALF_OF_SPONSORED_PRODUCTS = 2;
export const NUMBER_OF_PRODUCTS_BETWEEN_SPONSORED = 8;
export const MAXIMUM_OF_SPONSORED_PRODUCTS = 4;

export const LEFT_CLICK = 'LEFT_CLICK';
export const LEFT_CTRL_CLICK = 'LEFT_CTRL_CLICK';
export const RIGHT_CLICK = 'RIGHT_CLICK';
export const MIDDLE_CLICK = 'MIDDLE_CLICK';

export const DISPLAY_BREAKPOINT_MOBILE = 'mobile';
export const DISPLAY_BREAKPOINT_TABLET = 'tablet';
export const DISPLAY_BREAKPOINT_MAP = [
    DISPLAY_BREAKPOINT_MOBILE,
    DISPLAY_BREAKPOINT_TABLET,
];

export const DISPLAY_PLACEMENT_TRACK_AND_TRACE = 'TRACK_AND_TRACE';
export const DISPLAY_PLACEMENT_THANK_YOU_PAGE = 'THANK_YOU_PAGE';
