import {
    DELIVERY_TIME_ESTIMATES_BY_STORE,
    WEEKEND_DAYS,
    UNAVAILABLE_DELIVERY_DATES,
} from '@configs/delivery-time';
import { WEEKDAYS_SHORT } from '@configs/date';

import { FORMAT_DDMM_DOT } from '@types/DateFormat';

import { formatDate, addHours } from '@assets/date';

const hoursCounter = {
    from: 0,
    to: 0,
};

export const deliveryTime = {
    from: '',
    to: '',
};

export const isDeliveryDateValid = (dateToValidate, store) => {
    const unavailableDeliveryDates = UNAVAILABLE_DELIVERY_DATES[store] || [];

    return [
        date => !WEEKEND_DAYS.includes(date.getDay()),
        date =>
            unavailableDeliveryDates.every(
                uDate =>
                    date.toISOString().split('T')[0] !==
                    uDate.toISOString().split('T')[0]
            ),
    ].every(validator => validator(dateToValidate));
};

export const getClosestValidDeliveryDate = (date, store) => {
    if (!isDeliveryDateValid(date, store)) {
        const newDate = addHours(date, 24);

        return getClosestValidDeliveryDate(newDate, store);
    }

    return date;
};

export const setNextValidDay = (date, hoursType, store) => {
    const nextDay = getClosestValidDeliveryDate(addHours(date, 24), store);

    hoursCounter[hoursType] -= 24;

    if (hoursCounter[hoursType] <= 0) {
        deliveryTime[hoursType] = nextDay;

        return;
    }

    setNextValidDay(nextDay, hoursType, store);
};

export const getEstimatedDelivery = store => {
    const DELIVERY_TIME_ESTIMATES_BY_WEEKDAY =
        DELIVERY_TIME_ESTIMATES_BY_STORE[store];
    const date = new Date();
    const { toHours, fromHours } = DELIVERY_TIME_ESTIMATES_BY_WEEKDAY[
        date.getDay()
    ];

    hoursCounter.from = fromHours;
    hoursCounter.to = toHours;

    setNextValidDay(date, 'from', store);
    setNextValidDay(date, 'to', store);

    return deliveryTime;
};

export const formatDeliveryDate = (date, t, onlyDate) => {
    const weekDayLocal = WEEKDAYS_SHORT[date.getDay()];
    const dateFormatted = formatDate(date, FORMAT_DDMM_DOT);

    return onlyDate
        ? `${t(weekDayLocal)} ${dateFormatted}`
        : `${t(weekDayLocal)} (${dateFormatted})`;
};

export const getDeliveryLabel = (store, t, onlyDate) => {
    if (
        !DELIVERY_TIME_ESTIMATES_BY_STORE ||
        !store ||
        !Object.hasOwn(DELIVERY_TIME_ESTIMATES_BY_STORE, store)
    ) {
        return '';
    }

    const { from, to } = getEstimatedDelivery(store);
    const dateFromFormatted = formatDeliveryDate(from, t, onlyDate);
    const dateToFormatted = formatDeliveryDate(to, t, onlyDate);

    return onlyDate
        ? `${dateFromFormatted} - ${dateToFormatted}`
        : `${t('With you')}: ${dateFromFormatted} - ${dateToFormatted}`;
};
