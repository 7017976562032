<template>
    <div class="choose-size">
        <template v-if="product.isInStock">
            <SizeSuggestion
                v-if="isSizeSuggestionVisible"
                :product-size-suggestion="product.sizeSuggestion"
                :is-esizeme-enabled="isEsizemeEnabled"
                :is-esizeme-recommendation-promise="
                    isEsizemeRecommendationDeffered
                        ? isEsizemeRecommendationDeffered.promise
                        : null
                "
                :size-recommended="
                    esizemeRecommendationSizes.recommended ||
                    fitMateRecommendedSize
                "
                :size-acceptable="esizemeRecommendationSizes.acceptable"
                :is-recommended-size-available="
                    esizemeRecommendationSizes.isRecommendedSizeAvailable
                "
                :is-acceptable-size-available="
                    esizemeRecommendationSizes.isAcceptableSizeAvailable
                "
                :is-fit-mate-enabled="
                    isFitMateEnabled && isFitMateWidgetEnabled
                "
                :is-fit-mate-recommendation-error="isFitMateRecommendationError"
                :is-fit-mate-recommendation-promise="fitMatePromise"
                :selected-scan="selectedScan"
                :scans="userScansSorted"
                class="size-suggestion"
                @add-scan="openAddScanModal()"
                @matching-preview="openEsizemeModal()"
                @matching-preview-fit-mate="openFitMateRecommendation()"
                @find-your-size="openFitMateRecommendation()"
            />

            <SizeGrid
                :product-sku="productSku"
                :product-variants="productVariantsWithSizes"
                :selected-size="chosenSize"
                :recommended-size-label="recommendedSizeLabel"
                :is-no-size-chosen-info-visible="isNoSizeChosenInfoVisible"
                @selected="setChosenSize($event)"
                @selected-not-available="selectedNotAvailable($event)"
            />
        </template>

        <ButtonBanner
            v-if="isSizeChartButton && hasSizeDimensions"
            :data-test-id="SIZE_TABLE_BUTTON"
            class="size-table-button"
            @click.native="openTableModal()"
        >
            {{ $t('Size chart') }}
        </ButtonBanner>

        <ProductSizesTableModal
            v-if="isProductSizesTableModalOpen"
            :product-variants="productVariantsWithSizes"
            :is-open="isProductSizesTableModalOpen"
            :chosen-size="chosenSize"
            :size-dimensions="sizeDimensions"
            :init-step="initModalStep"
            :product-sku="productSku"
            :size-table-type="sizeTableType"
            :is-esizeme-enabled="isEsizemeEnabled"
            :is-recommended-size-unavailable="
                esizemeRecommendationSizes.isRecommendedSizeAvailable === false
            "
            :is-recommendation-for-product-unavailable="
                isRecommendationForProductUnavailable
            "
            :esizeme-selected-scan-id="esizemeSelectedScanId"
            @close="closeChooseSizeModal()"
            @toggle-scan="onScanToggle($event)"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { SIZE_TABLE_BUTTON } from '@types/AutomaticTestIDs';

import {
    STEP_ESIZEME_ALL,
    STEP_SIZES_TABLE,
} from '@configs/product-sizes-table-modal';
import {
    SAFE_SIZE_TABLE_TYPE,
    SAFE_SIZE_LENGTH,
    MANUAL_TABLE_TYPE,
    MANUAL_TABLE_KEYS,
    MANUFACTURER_TABLE_ATTRIBUTES_KEYS,
    MANUFACTURER_TABLE_TYPE,
    NO_TABLE_TYPE,
} from '@configs/size-tables';
import { MODAL_ADD_ESIZEME_SCAN } from '@configs/modals';

import { MODULE_NAME as ESIZEME_MODULE_NAME } from '@analytics-module/modules/esizeme/meta';
import {
    PROFILE_SELECT,
    PROFILE_UNSELECT,
    SCAN_ADD,
} from '@analytics-module/modules/esizeme/types/Events';
import {
    PRODUCT_SIZE_SELECTED,
    ON_ELEMENT_ACTION,
} from '@analytics-module/modules/product/types/Events';
import { MODULE_NAME as PRODUCT_MODULE_NAME } from '@analytics-module/modules/product/meta';
import { CATEGORIES as PRODUCT_CATEGORIES } from '@analytics-module/modules/product/types/Categories';
import { ACTIONS as GLOBAL_ACTIONS } from '@analytics-module/types/Actions';

import { getChosenSizeLabelFromVariant, getSizeTable } from '@assets/product';
import {
    checkIfRecommendationIsAcceptable,
    checkIfRecommendationIsBest,
} from '@assets/esizeme';

import Deferred from '@core-assets/deferred';

import { NO_SUCH_ENTITY } from '@types/GraphQLErrorCodes';

import FitMate from '@mixins/FitMate';

import OverlayLoader from '@atoms/OverlayLoader/OverlayLoader';

import SizeGrid from '@organisms/ChooseSize/SizeGrid';

import { ButtonBanner } from '@eobuwie-ui/components/ButtonBanner/v1';

const { mapGetters: mapEsizemeGetters } = createNamespacedHelpers('esizeme');

const ESIZEME_EVENT_LABEL_NO_SIZE_AVAILABLE = 'no_size_available';
const ESIZEME_EVENT_LABEL_NO_MATCH = 'no_match';
const ESIZEME_EVENT_LABEL_RECOMMENDED = 'recommended';
const ESIZEME_EVENT_LABEL_ACCEPTABLE = 'acceptable';

export default {
    name: 'ChooseSize',

    components: {
        ButtonBanner,
        SizeGrid,
        ProductSizesTableModal: () => ({
            component: import(
                /* webpackChunkName: "product-sizes-table-modal" */
                '@molecules/ProductSizesTableModal/ProductSizesTableModal'
            ),

            loading: OverlayLoader,
        }),

        SizeSuggestion: () => ({
            component: import(
                /* webpackChunkName: "size-suggestion" */
                '@molecules/ProductSpecification/SizeSuggestion'
            ),
        }),
    },

    mixins: [FitMate],

    props: {
        productFamily: {
            type: Object,
            required: true,
        },

        productSku: {
            type: String,
            required: true,
        },

        isProductOneSize: {
            type: Boolean,
            default: false,
        },

        productVariants: {
            type: Object,
            required: true,
        },

        chosenSize: {
            type: String,
            required: true,
        },

        isSizeChartButton: {
            type: Boolean,
            default: false,
        },

        isProductSizesTableModalOpen: {
            type: Boolean,
            default: false,
        },

        product: {
            type: Object,
            default: () => ({}),
        },

        sizeSetOnInit: {
            type: Boolean,
            default: false,
        },

        isNoSizeChosenInfoVisible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            initModalStep: STEP_SIZES_TABLE,
            esizemeRecommendation: {},
            esizemeSelectedSize: null,
            isEsizemeRecommendationLoaded: false,
            isEsizemeRecommendationDeffered: null,
            esizemeInfo: null,
        };
    },

    computed: {
        ...mapEsizemeGetters([
            'isEsizemeProductPageEnabled',
            'selectedScan',
            'userScansSorted',
        ]),

        isEsizemeEnabled() {
            return !!(this.isEsizemeProductPageEnabled && this.hasTrueSize);
        },

        sizeTable() {
            return getSizeTable(this.product, this.$i18n.tLabel);
        },

        sizeDimensions() {
            return Object.keys(this.sizeTable).map(
                item => this.sizeTable[item]
            );
        },

        sizeTableType() {
            const sizeTableKeys = Object.keys(this.sizeTable);

            if (sizeTableKeys.includes(SAFE_SIZE_LENGTH)) {
                return SAFE_SIZE_TABLE_TYPE;
            }

            if (sizeTableKeys.some(key => MANUAL_TABLE_KEYS.includes(key))) {
                return MANUAL_TABLE_TYPE;
            }

            if (
                sizeTableKeys.some(key =>
                    MANUFACTURER_TABLE_ATTRIBUTES_KEYS.includes(key)
                )
            ) {
                return MANUFACTURER_TABLE_TYPE;
            }

            return NO_TABLE_TYPE;
        },

        hasSizeDimensions() {
            return this.sizeDimensions.length > 0;
        },

        esizemeRecommendationSizes() {
            const sizes = {
                recommended: null,
                recommendedKey: null,
                acceptableKey: null,
                acceptable: null,
                isRecommendedSizeAvailable: null,
                isAcceptableSizeAvailable: null,
            };

            const {
                variant_dimension: variantDimension = null,
            } = this.productFamily;

            Object.entries(this.productVariants).forEach(
                ([variantKey, productVariant]) => {
                    const sizeLabel = getChosenSizeLabelFromVariant(
                        productVariant,
                        variantDimension
                    );

                    if (!sizes.recommended) {
                        const isEsizemeRecommendedSize = checkIfRecommendationIsBest(
                            this.esizemeRecommendation,
                            sizeLabel
                        );

                        if (isEsizemeRecommendedSize) {
                            sizes.recommended = sizeLabel;
                            sizes.recommendedKey = variantKey;
                            sizes.isRecommendedSizeAvailable =
                                productVariant.stock_quantity > 0;

                            return;
                        }
                    }

                    if (!sizes.acceptable) {
                        const isEsizemeAcceptableSize = checkIfRecommendationIsAcceptable(
                            this.esizemeRecommendation,
                            sizeLabel
                        );

                        if (isEsizemeAcceptableSize) {
                            sizes.acceptable = sizeLabel;
                            sizes.acceptableKey = variantKey;
                            sizes.isAcceptableSizeAvailable =
                                productVariant.stock_quantity > 0;
                        }
                    }
                }
            );

            return sizes;
        },

        productVariantsWithSizes() {
            const {
                productFamily: { variant_dimension: variantDimension = null },
                esizemeRecommendationSizes: { recommended, acceptable },
            } = this;

            return Object.values(this.productVariants).reduce(
                (acc, productVariant) => {
                    const sizeLabel = getChosenSizeLabelFromVariant(
                        productVariant,
                        variantDimension
                    );

                    const { stock_quantity: stockQuantity } = productVariant;

                    return {
                        ...acc,
                        [productVariant.size]: {
                            ...productVariant,
                            sizeLabel,
                            isEsizemeRecommendedSize: sizeLabel === recommended,
                            isEsizemeAcceptableSize: sizeLabel === acceptable,
                            isOutOfStock: stockQuantity === 0,
                        },
                    };
                },
                {}
            );
        },

        isEsizemeRecommendationsAvailable() {
            return (
                this.isEsizemeEnabled &&
                this.selectedScan?.id &&
                !this.product.isOneSize
            );
        },

        esizemeSelectedScanId() {
            return this.selectedScan?.id || null;
        },

        hasTrueSize() {
            return this.product.hasTrueSize;
        },

        recommendedSizeLabel() {
            const {
                recommended,
                acceptable,
                isRecommendedSizeAvailable,
                isAcceptableSizeAvailable,
            } = this.esizemeRecommendationSizes;

            if (isRecommendedSizeAvailable) {
                return recommended;
            }

            if (isAcceptableSizeAvailable) {
                return acceptable;
            }

            return '';
        },

        isSizeSuggestionVisible() {
            return (
                this.isEsizemeEnabled ||
                this.product.sizeSuggestion ||
                (this.isFitMateEnabled && this.isFitMateWidgetEnabled)
            );
        },
    },

    watch: {
        isEsizemeRecommendationsAvailable: {
            handler() {
                this.initEsizeMeSizeRecommendation();
            },

            immediate: true,
        },

        selectedScan: {
            handler() {
                this.initEsizeMeSizeRecommendation();
            },
        },

        productVariantsWithSizes: {
            handler() {
                if (!this.isEsizemeRecommendationLoaded) {
                    return;
                }

                if (!this.sizeSetOnInit) {
                    this.setEsizemeRecommendedSizeOnInit();
                }

                this.sendEsizemeInfo();
                this.emitEsizemeAddToCartInfo();
            },

            immediate: true,
        },
    },

    beforeCreate() {
        this.SIZE_TABLE_BUTTON = SIZE_TABLE_BUTTON;
    },

    created() {
        this.isEsizemeRecommendationDeffered = new Deferred();
    },

    methods: {
        setEsizeMeInfo() {
            const { esizemeSelectedSize: sizeSelected } = this;
            const profileId = this.selectedScan?.id;

            const {
                recommended: sizeRecommended,
                acceptable: sizeAcceptable,
                isRecommendedSizeAvailable,
                isAcceptableSizeAvailable,
            } = this.esizemeRecommendationSizes;

            const esizemeInfo = {
                esizeme: true,
            };

            if (profileId) {
                esizemeInfo.profileId = profileId;
            } else {
                esizemeInfo.esizeme = false;
                this.esizemeInfo = esizemeInfo;

                return;
            }

            if (sizeSelected) {
                esizemeInfo.sizeSelected = sizeSelected;

                if (sizeRecommended) {
                    esizemeInfo.esizemeChosenSizeLabel = ESIZEME_EVENT_LABEL_RECOMMENDED;
                } else if (sizeAcceptable) {
                    esizemeInfo.esizemeChosenSizeLabel = ESIZEME_EVENT_LABEL_ACCEPTABLE;
                } else {
                    esizemeInfo.esizemeChosenSizeLabel = ESIZEME_EVENT_LABEL_NO_MATCH;
                }
            }

            if (!isRecommendedSizeAvailable && !isAcceptableSizeAvailable) {
                esizemeInfo.esizeme = ESIZEME_EVENT_LABEL_NO_SIZE_AVAILABLE;
            }

            if (sizeRecommended) {
                esizemeInfo.sizeReco = sizeRecommended;
            }

            if (sizeAcceptable) {
                esizemeInfo.sizeAcceptable = sizeAcceptable;
            }

            this.esizemeInfo = esizemeInfo;
        },

        sendEsizemeInfo() {
            this.setEsizeMeInfo();

            this.$emit('esizeme-info', this.esizemeInfo);
        },

        emitSizeSelectionEvent(sizeLabel) {
            const eventData = {
                productSku: this.productSku,
                chosenSize: sizeLabel,
                isProductOneSize: this.isProductOneSize,
            };

            const profileId = this.selectedScan?.id;

            if (profileId) {
                eventData.profileId = profileId;
            }

            if (sizeLabel === this.esizemeRecommendationSizes.recommended) {
                eventData.esizeme = ESIZEME_EVENT_LABEL_RECOMMENDED;
            } else if (
                sizeLabel === this.esizemeRecommendationSizes.acceptable
            ) {
                eventData.esizeme = ESIZEME_EVENT_LABEL_ACCEPTABLE;
            }

            this.$analytics.moduleEmit(
                PRODUCT_MODULE_NAME,
                PRODUCT_SIZE_SELECTED,
                eventData
            );
        },

        openChooseSizeModal() {
            this.$emit('open-size-modal');
        },

        closeChooseSizeModal() {
            this.$emit('close-size-modal');
        },

        setChosenSize({ size, sizeLabel }) {
            this.$emit('set-chosen-size', size.toString());

            this.emitSizeSelectionEvent(sizeLabel);
        },

        openEsizemeModal() {
            this.initModalStep = STEP_ESIZEME_ALL;

            this.openChooseSizeModal();
        },

        openTableModal() {
            this.initModalStep = STEP_SIZES_TABLE;

            this.openChooseSizeModal();

            this.$analytics.moduleEmit(PRODUCT_MODULE_NAME, ON_ELEMENT_ACTION, {
                eventCategory: PRODUCT_CATEGORIES.PRODUCT_TABLE_SIZE_PRODUCT,
                eventAction: GLOBAL_ACTIONS.OPEN,
                eventLabel: this.productSku,
            });
        },

        selectedNotAvailable(selectedProductVariant) {
            this.$nextTick(() => {
                this.$emit('selected-not-available', selectedProductVariant);
            });
        },

        initEsizeMeSizeRecommendation() {
            this.esizemeRecommendation = {};

            if (this.isEsizemeRecommendationsAvailable) {
                this.getEsizemeSizeRecommendation();
            } else {
                this.esizemeInfo = {
                    esizeme: false,
                };

                this.$emit('esizeme-info', this.esizemeInfo);

                this.emitEsizemeAddToCartInfo();
            }
        },

        async getEsizemeSizeRecommendation() {
            const { id: scanId } = this.selectedScan || {};

            if (!scanId) {
                return;
            }

            const {
                recommendation,
                errorCode,
            } = await this.$services.esizeme.getRecommendationForProductAndScan(
                scanId,
                this.product.sku
            );

            if (recommendation) {
                this.esizemeRecommendation = recommendation;
            } else if (errorCode === NO_SUCH_ENTITY) {
                this.sendEsizemeInfo();
                this.emitEsizemeAddToCartInfo();
            }

            this.isEsizemeRecommendationLoaded = true;
            this.isEsizemeRecommendationDeffered?.resolve();
        },

        setEsizemeRecommendedSizeOnInit() {
            const {
                recommended,
                recommendedKey,
                acceptable,
                acceptableKey,
                isRecommendedSizeAvailable,
                isAcceptableSizeAvailable,
            } = this.esizemeRecommendationSizes;

            const recommendation = {
                size: null,
                sizeLabel: null,
            };

            if (isRecommendedSizeAvailable) {
                recommendation.size = recommendedKey;
                recommendation.sizeLabel = recommended;
            } else if (isAcceptableSizeAvailable) {
                recommendation.size = acceptableKey;
                recommendation.sizeLabel = acceptable;
            }

            if (!recommendation.size) {
                return;
            }

            this.esizemeSelectedSize = String(recommendation.sizeLabel);

            this.$emit('set-chosen-size', recommendation.size);
        },

        emitEsizemeAddToCartInfo() {
            const scanId = this.selectedScan?.id || '';

            this.$emit('esizeme-add-to-cart-info', {
                scanId,
                ...this.esizemeRecommendationSizes,
                isEsizemeEnabled: this.isEsizemeEnabled,
            });
        },

        async onScanToggle(scan) {
            const { id: scanId, isSelected } = scan;

            if (isSelected) {
                this.sendScanSelectionEvent({
                    scanId,
                    isSelected: true,
                });

                return;
            }

            this.isEsizemeRecommendationDeffered = new Deferred();

            await this.isEsizemeRecommendationDeffered.promise;

            this.sendScanSelectionEvent({
                scanId,
                isSelected: false,
            });
        },

        sendScanSelectionEvent({ scanId, isSelected }) {
            const {
                recommended: sizeRecommended,
                acceptable: sizeAcceptable,
            } = this.esizemeRecommendationSizes;

            const eventPayload = {
                scanId,
            };

            if (sizeRecommended) {
                eventPayload.sizeRecommended = sizeRecommended;
            }

            if (sizeAcceptable) {
                eventPayload.sizeAcceptable = sizeAcceptable;
            }

            this.$analytics.moduleEmit(
                ESIZEME_MODULE_NAME,
                isSelected ? PROFILE_UNSELECT : PROFILE_SELECT,
                eventPayload
            );
        },

        openAddScanModal() {
            this.$modals.open(MODAL_ADD_ESIZEME_SCAN);

            this.$analytics.moduleEmit(ESIZEME_MODULE_NAME, SCAN_ADD, {
                isNewScan: true,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.choose-size {
    .size-table-button {
        @apply h-ui-10;
    }

    .size-suggestion {
        @apply mb-ui-4;
    }

    @screen mobile-and-tablet-only {
        .size-suggestion {
            @apply -mx-ui-4;
        }
    }
}
</style>
