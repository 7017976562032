<template>
    <div class="popular-brands-and-categories">
        <LinksListSection
            v-for="{ identifier, content, title } in availableBlocks"
            :key="identifier"
            :title="title"
            :content="content"
            :identifier="identifier"
            class="links-section"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
    PSB_POPULAR_BRANDS,
    PSB_POPULAR_CATEGORIES,
} from '@configs/popular-blocks';

import LinksListSection from '@molecules/LinksListSection/LinksListSection';

const {
    mapGetters: mapCmsBlockGetters,
    mapActions: mapCmsBlockActions,
} = createNamespacedHelpers('cmsBlock');

export default {
    name: 'PopularBrandsAndCategories',

    components: {
        LinksListSection,
    },

    computed: {
        ...mapCmsBlockGetters(['staticBlockById']),

        popularBrandsBlock() {
            return this.staticBlockById(PSB_POPULAR_BRANDS) || {};
        },

        popularCategoriesBlock() {
            return this.staticBlockById(PSB_POPULAR_CATEGORIES) || {};
        },

        availableBlocks() {
            return [
                this.popularBrandsBlock,
                this.popularCategoriesBlock,
            ].filter(({ title, content }) => title && content);
        },
    },

    methods: {
        ...mapCmsBlockActions(['getCmsBlocks']),
    },
};
</script>

<style lang="scss" scoped>
.popular-brands-and-categories {
    @apply mt-7;

    @screen mobile-and-tablet-only {
        @apply mb-5;
    }

    @screen lg {
        @apply mt-9;

        .links-section {
            @apply mb-9;
        }
    }
}
</style>
