<template>
    <div class="product-reviews-item">
        <div class="review-header">
            <RatingStars :rating="rating" :is-large="false" />
            <div class="date">
                {{ date }}
            </div>
        </div>
        <div :data-test-id="COMMENTS" class="content">
            {{ content }}
        </div>
        <div v-if="name" class="name">
            {{ name }}
        </div>
    </div>
</template>

<script>
import { COMMENTS } from '@types/AutomaticTestIDs';

import RatingStars from '@atoms/RatingStars/RatingStars';

export default {
    name: 'ProductReviewsItem',

    components: {
        RatingStars,
    },

    props: {
        rating: {
            type: Number,
            required: true,
        },

        name: {
            type: String,
            default: '',
        },

        date: {
            type: String,
            default: '',
        },

        content: {
            type: String,
            default: '',
        },
    },

    beforeCreate() {
        this.COMMENTS = COMMENTS;
    },
};
</script>

<style lang="scss" scoped>
.product-reviews-item {
    @apply w-full;

    .review-header {
        @apply flex justify-between items-center mb-3;
    }

    .date,
    .name {
        @apply text-s leading-xs;
    }

    .date {
        @apply text-text2;
    }

    .content {
        @apply text-r leading-s text-text2;
    }

    .name {
        @apply mt-2 text-text3;
    }

    @screen lg {
        .date,
        .name {
            @apply leading-s;
        }

        .content {
            @apply leading-r;
        }
    }
}
</style>
