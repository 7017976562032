import { TYPE_REVIEW } from '@configs/structured-data';

import StructuredDataBaseClass from '@models/StructuredData/StructuredDataBaseClass';
import StructuredDataRating from '@models/StructuredData/StructuredDataRating';

export default class StructuredDataReview extends StructuredDataBaseClass {
    constructor(reviewBody) {
        super(TYPE_REVIEW);
        this.reviewBody = reviewBody;
        this.optionalFields = {};
    }

    addRating(rating) {
        if (!(rating instanceof StructuredDataRating)) {
            throw new TypeError(
                'Rating must be instance of StructuredDataRating'
            );
        }

        this.optionalFields.reviewRating = rating.build();
    }

    build() {
        return {
            ...super.build(),
            reviewBody: this.reviewBody,
            ...this.optionalFields,
        };
    }
}
