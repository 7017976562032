<script>
import { createNamespacedHelpers } from 'vuex';

const {
    mapActions: mapCustomerActions,
    mapGetters: mapCustomerGetters,
} = createNamespacedHelpers('customer');

export default {
    name: 'WithWishlistedProducts',

    props: {
        productsSkus: {
            type: Array,
            required: true,
        },
    },

    computed: {
        ...mapCustomerGetters(['isLoggedIn']),
    },

    mounted() {
        if (!this.isLoggedIn) {
            this.unwatchFetchingCustomerInProgress = this.$store.watch(
                state => state.customer.fetchingCustomerInProgress,
                fetchingCustomerInProgress => {
                    if (!fetchingCustomerInProgress) {
                        this.unwatchFetchingCustomerInProgress();

                        if (this.isLoggedIn) {
                            this.checkWishlist(this.productsSkus);
                        }
                    }
                }
            );
        } else {
            this.checkWishlist(this.productsSkus);
        }
    },

    beforeDestroy() {
        if (typeof this.unwatchFetchingCustomerInProgress !== 'undefined') {
            this.unwatchFetchingCustomerInProgress();
        }
    },

    methods: {
        ...mapCustomerActions(['checkWishlist']),
    },

    render() {
        return this.$slots.default;
    },
};
</script>
