<template>
    <div class="product-main-gallery">
        <GalleryGrid
            ref="galleryGrid"
            :media="productMedia"
            :photo-override="variantImage"
            @image-click="onGalleryItemClick($event)"
            @video-click="onGalleryItemClick($event)"
            @element-view="onElementView($event)"
        />
        <client-only>
            <GallerySlider
                v-if="isMobileGallerySliderEnabled"
                ref="gallerySlider"
                :media="productMedia"
                class="gallery-slider"
                @slide-change="emitAnalyticsElementView($event)"
                @image-click="onGalleryItemClick($event)"
                @video-click="onGalleryItemClick($event)"
            />
        </client-only>

        <ProductModalGallery
            v-if="isModalGalleryOpened"
            :product-media="productMedia"
            :start-index="modalGalleryStartPictureIndex"
            :is-mobile="isMobile"
            @close-modal="onModalGalleryClose()"
        />

        <slot name="gallery-additional-content" />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { MEDIA_FAMILY_MAP, VIDEO as VIDEO_FAMILY } from '@configs/media-family';
import { VIDEO_GALLERY_POSITION } from '@configs/product';

import {
    ON_ELEMENT_ACTION,
    PRODUCT_GALLERY_ELEMENTS_LOADED,
    PRODUCT_GALLERY_ELEMENT_CLICK,
    PRODUCT_GALLERY_ELEMENT_VIEW,
} from '@analytics-module/modules/product/types/Events';
import { CATEGORIES as PRODUCT_CATEGORIES } from '@analytics-module/modules/product/types/Categories';
import { ACTIONS as GLOBAL_ACTIONS } from '@analytics-module/types/Actions';
import { LABELS as GLOBAL_LABELS } from '@analytics-module/types/Labels';
import { MODULE_NAME as PRODUCT_MODULE_NAME } from '@analytics-module/modules/product/meta';

import {
    PRODUCT_IMAGE_TYPES,
    IMAGE_TYPE_PRODUCT_64w_64h,
    IMAGE_TYPE_PRODUCT_512w_512h,
    IMAGE_TYPE_PRODUCT_528w_704h,
    IMAGE_TYPE_PRODUCT_660w_880h,
    IMAGE_TYPE_PRODUCT_1800w_2400h,
    IMAGE_PLACEHOLDERS,
} from '@types/Image';
import { ORIENTATION_VERTICAL } from '@types/Slider';
import { FETCH_PRIORITY } from '@types/FetchPriority';
import { PICTURE, VIDEO } from '@types/Gallery';

import { getProductImage } from '@assets/images';
import { getVideoPlaceholder } from '@assets/video';

import GalleryGrid from '@organisms/ProductMainGallery/GalleryGrid';

export default {
    name: 'ProductMainGallery',

    components: {
        GalleryGrid,

        ProductModalGallery: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "product-modal-gallery" */ '@organisms/ProductModalGallery/ProductModalGallery'
            ),
        }),

        GallerySlider: () => ({
            component: import(
                /* webpackChunkName: "gallery-slider" */
                '@organisms/ProductMainGallery/GallerySlider'
            ),
        }),
    },

    props: {
        product: {
            type: Object,
            required: true,
        },

        variantImage: {
            type: Object,
            default: null,
        },

        isPageEventEmittedPromise: {
            type: Promise,
            default: new Promise(() => {}),
        },

        isEsizemeInfoLoadedPromise: {
            type: Promise,
            default: new Promise(() => {}),
        },

        isColorVariantsLoadedPromise: {
            type: Promise,
            default: new Promise(() => {}),
        },

        esizemeInfo: {
            type: Object,
            default: () => ({}),
        },

        variants: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            isModalGalleryOpened: false,
            modalGalleryStartPictureIndex: 0,
            viewedElements: [],
        };
    },

    computed: {
        ...mapState(['isMobile', 'isMobileEvaluated']),

        productMedia() {
            const { productVideo, product } = this;

            const dimensions = [IMAGE_TYPE_PRODUCT_1800w_2400h];

            const placeholders =
                IMAGE_PLACEHOLDERS[IMAGE_TYPE_PRODUCT_1800w_2400h];

            const media = product.images.map((element, index) => {
                return {
                    fetchPriority: this.getFetchPriority(index),
                    isLazy: index !== 0,
                    type: PICTURE,
                    family: element.family,
                    thumbnail: getProductImage(
                        product,
                        index,
                        [IMAGE_TYPE_PRODUCT_64w_64h],
                        IMAGE_PLACEHOLDERS[IMAGE_TYPE_PRODUCT_64w_64h]
                    ),
                    product: getProductImage(
                        product,
                        index,
                        [
                            IMAGE_TYPE_PRODUCT_660w_880h,
                            IMAGE_TYPE_PRODUCT_528w_704h,
                        ],
                        IMAGE_PLACEHOLDERS[IMAGE_TYPE_PRODUCT_660w_880h]
                    ),
                    zoom: getProductImage(
                        product,
                        index,
                        dimensions,
                        placeholders
                    ),
                };
            });

            if (productVideo) {
                media.splice(VIDEO_GALLERY_POSITION, 0, {
                    type: VIDEO,
                    video: productVideo,
                    imagePlaceholder: getVideoPlaceholder(media),
                    family: VIDEO_FAMILY,
                });
            }

            return media;
        },

        productVideo() {
            const { video, name } = this.product;

            if (!video) {
                return;
            }

            const { width, height } = PRODUCT_IMAGE_TYPES[
                IMAGE_TYPE_PRODUCT_512w_512h
            ];

            return {
                url: video,
                alt: name,
                width,
                height,
            };
        },

        mediaFamily() {
            return this.productMedia.map(
                ({ family }) => MEDIA_FAMILY_MAP[family] || ''
            );
        },

        isMobileGallerySliderEnabled() {
            return (
                this.productMedia.length > 1 &&
                this.isMobile &&
                this.isMobileEvaluated
            );
        },
    },

    watch: {
        isModalGalleryOpened(isOpen) {
            this.$emit(isOpen ? 'modal-gallery-open' : 'modal-gallery-close');

            if (!isOpen) {
                const refName = this.isMobile ? 'gallerySlider' : 'galleryGrid';

                this.$refs[refName]?.playVideo?.();
            }
        },
    },

    beforeCreate() {
        this.ORIENTATION_VERTICAL = ORIENTATION_VERTICAL;
        this.PICTURE = PICTURE;
        this.VIDEO = VIDEO;
        this.viewedElements = [];
    },

    mounted() {
        this.emitProductGalleryElementsCount();
    },

    methods: {
        emitProductGalleryElementClick(index) {
            const { type } = this.productMedia[index];
            const isPhoto = type === PICTURE;
            const photoType =
                MEDIA_FAMILY_MAP[this.productMedia[index]?.family] || '';

            this.$analytics.moduleEmit(
                PRODUCT_MODULE_NAME,
                PRODUCT_GALLERY_ELEMENT_CLICK,
                {
                    position: index,
                    isPhoto,
                    photoType,
                }
            );
        },

        onElementView(index) {
            if (this.isMobileGallerySliderEnabled) {
                return;
            }

            this.emitAnalyticsElementView(index);
        },

        onGalleryItemClick(index) {
            this.modalGalleryStartPictureIndex = index;

            this.isModalGalleryOpened = true;
            this.emitProductGalleryElementClick(index);
        },

        onModalGalleryClose() {
            this.isModalGalleryOpened = false;
            this.modalGalleryStartPictureIndex = 0;

            this.$analytics.moduleEmit(PRODUCT_MODULE_NAME, ON_ELEMENT_ACTION, {
                eventCategory: PRODUCT_CATEGORIES.PRODUCT_PHOTO_GALLERY_MODAL,
                eventAction: GLOBAL_ACTIONS.CLICK_NAVIGATION,
                eventLabel: GLOBAL_LABELS.X_BUTTON,
            });
        },

        getFetchPriority(index) {
            return index === 0 ? FETCH_PRIORITY.HIGH : null;
        },

        async emitProductGalleryElementsCount() {
            await Promise.all([
                this.isPageEventEmittedPromise,
                this.isEsizemeInfoLoadedPromise,
                this.isColorVariantsLoadedPromise,
            ]);

            const colorVariantsCount = this.variants.length || 1;

            this.$analytics.moduleEmit(
                PRODUCT_MODULE_NAME,
                PRODUCT_GALLERY_ELEMENTS_LOADED,
                {
                    photosCount: this.product.images.length,
                    videosCount: this.productVideo ? 1 : 0,
                    photosTypeArray: this.mediaFamily,
                    sizeSuggestion: !!this.product.sizeSuggestion,
                    colorVariantsCount,
                    ...this.esizemeInfo,
                }
            );
        },

        async emitAnalyticsElementView(index) {
            if (this.viewedElements.includes(index)) {
                return;
            }

            this.viewedElements.push(index);

            const { type } = this.productMedia[index];
            const isPhoto = type === PICTURE;
            const photoType =
                MEDIA_FAMILY_MAP[this.productMedia[index]?.family] || '';

            await this.isPageEventEmittedPromise;

            this.$analytics.moduleEmit(
                PRODUCT_MODULE_NAME,
                PRODUCT_GALLERY_ELEMENT_VIEW,
                {
                    position: index,
                    isPhoto,
                    photoType,
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.product-main-gallery {
    @apply relative;

    .gallery-slider {
        @apply absolute top-0 left-0 w-ui-percent-100;
    }

    @screen mobile-and-tablet-only {
        @apply -mx-ui-4;
    }

    @screen ui-desktop {
        .gallery-slider {
            @apply hidden;
        }
    }
}
</style>
