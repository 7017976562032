import { TYPES } from '@configs/structured-data';

export default class StructuredDataBaseClass {
    constructor(type) {
        if (!type) {
            throw new TypeError('Type must be provided');
        }

        if (!Object.values(TYPES).includes(type)) {
            throw new TypeError('Type must have correct value');
        }

        this.type = type;
    }

    build() {
        return {
            '@context': 'https://schema.org',
            '@type': this.type,
        };
    }

    toJSON() {
        return JSON.stringify(this.build());
    }
}
