export default {
    props: {
        currentPage: {
            type: Number,
            required: true,
        },

        totalPages: {
            type: Number,
            required: true,
        },

        navigateToPage: {
            type: Function,
            default: () => {},
        },

        isDisabled: {
            type: Boolean,
            default: false,
        },

        createPaginationPath: {
            type: Function,
            required: false,
        },
    },

    computed: {
        isFirstPage() {
            return this.currentPage === 1;
        },

        isLastPage() {
            return this.currentPage === this.totalPages;
        },
    },

    methods: {
        setPageNumber(pageNumber) {
            // inner pagination model property
            // in case of handling pagination trough inner controls like text input etc
            if (this.page) {
                this.page = pageNumber;
            }

            this.$emit('page-changed');
            this.navigateToPage(pageNumber);
        },

        createPagesArray(startNumber, resultLength) {
            let localStartingPage = startNumber;

            return new Array(resultLength).fill(null).map(() => {
                localStartingPage += 1;

                return localStartingPage;
            });
        },

        createPath(pageNumber) {
            if (pageNumber === 0 || pageNumber > this.totalPages) {
                return '';
            }

            return this.createPaginationPath?.(pageNumber);
        },
    },
};
