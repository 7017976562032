<template>
    <Observer
        :observe-once="true"
        :options="SIZE_GRID_OBSERVER_OPTIONS"
        class="size-grid"
        @intersect="onSizeGirdInViewport($event)"
    >
        <div class="legend">
            <span class="selected-size">
                {{ $t('Size') }}:

                <span v-if="hasOneVariant" class="selected-size-label">
                    {{ selectedSizeLabel }}
                </span>
            </span>
            <span v-if="hasAnyLastSizes" class="last-items">
                {{ $t('Last items') }}
            </span>
        </div>

        <Message
            v-if="!selectedSize && isNoSizeChosenInfoVisible"
            :variant="MESSAGE_VARIANT"
            class="choose-size-info"
        >
            {{ $t('Choose the size you want to add') }}
        </Message>

        <div v-if="!hasOneVariant" class="sizes">
            <PillSize
                v-for="variant in productVariantsDisplay"
                :key="variant.id"
                :selected="variant.isSelected"
                :data-test-id="PRODUCT_SIZE"
                :data-test-value="variant.size"
                :class="{
                    'has-last-items': variant.isRunningOutOfStock,
                    'is-selected': variant.isSelected,
                    'is-out-of-stock': variant.isOutOfStock,
                    'is-esizeme-recommended':
                        recommendedSizeLabel === variant.sizeLabel,
                }"
                class="size"
                @select="onSelect(variant)"
            >
                <span
                    :data-test-id="!variant.isOutOfStock ? PRODUCT_SIZES : null"
                    class="size-content"
                >
                    <Icon
                        v-if="variant.isOutOfStock"
                        :icon="Email"
                        width="16px"
                        height="16px"
                    />

                    {{ variant.sizeLabel }}
                </span>
            </PillSize>
        </div>
    </Observer>
</template>

<script>
import { PRODUCT_SIZE, PRODUCT_SIZES } from '@types/AutomaticTestIDs';

import { ON_ELEMENT_ACTION } from '@analytics-modules/product/types/Events';
import { MODULE_NAME as PRODUCT_MODULE_NAME } from '@analytics-modules/product/meta';
import { ACTIONS as GLOBAL_ACTIONS } from '@analytics-module/types/Actions';
import { CATEGORIES as PRODUCT_CATEGORIES } from '@analytics-modules/product/types/Categories';

import Observer from '@atoms/Observer/Observer';

import { PillSize } from '@eobuwie-ui/components/PillSize/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';
import { Message, MESSAGE_VARIANTS } from '@eobuwie-ui/components/Message/v1';

import { Email } from '@eobuwie-ui/icons/v2/communication';

import {
    getProductSizes,
    getProductVariantsBySortOrder,
} from '@assets/product-variants';

export default {
    name: 'SizeGrid',

    components: {
        Icon,
        PillSize,
        Message,
        Observer,
    },

    props: {
        productSku: {
            type: String,
            required: true,
        },

        productVariants: {
            type: Object,
            required: true,
        },

        selectedSize: {
            type: String,
            default: '',
        },

        recommendedSizeLabel: {
            type: String,
            default: '',
        },

        isNoSizeChosenInfoVisible: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        selectedSizeLabel() {
            return this.productVariants[this.selectedSize]?.sizeLabel;
        },

        hasOneVariant() {
            return Object.keys(this.productVariants).length === 1;
        },

        productVariantsDisplay() {
            return getProductVariantsBySortOrder(
                getProductSizes(this.productVariants, {
                    chosenSize: this.selectedSize,
                    showNotifyAboutAvailability: true,
                })
            );
        },

        hasAnyLastSizes() {
            return this.productVariantsDisplay.some(
                variant => variant.isRunningOutOfStock
            );
        },
    },

    beforeCreate() {
        this.Email = Email;

        this.PRODUCT_SIZES = PRODUCT_SIZES;
        this.PRODUCT_SIZE = PRODUCT_SIZE;
        this.MESSAGE_VARIANT = MESSAGE_VARIANTS.ERROR;

        this.SIZE_GRID_OBSERVER_OPTIONS = {
            root: null,
            threshold: 0,
            rootMargin: '0px 0px 0px 0px',
        };
    },

    methods: {
        onSelect(variant) {
            const payload = {
                sku: variant.id,
                size: variant.size,
                sizeLabel: variant.sizeLabel,
            };

            if (variant.isOutOfStock) {
                this.$emit('selected-not-available', payload);

                return;
            }

            this.$emit('selected', payload);
        },

        onSizeGirdInViewport(isInViewport) {
            if (!isInViewport) {
                return;
            }

            const sizesAll = [];
            const sizesAvailable = [];
            const sizesUnavailable = [];

            this.productVariantsDisplay.forEach(({ size, isOutOfStock }) => {
                sizesAll.push(size);

                if (isOutOfStock) {
                    sizesUnavailable.push(size);

                    return;
                }

                sizesAvailable.push(size);
            });

            const eventLabel = `${this.productSku};sizes_all: ${sizesAll.join(
                ','
            )};sizes_available:${sizesAvailable.join(
                ','
            )};sizes_unavailable:${sizesUnavailable.join(',')}`;

            this.$analytics.moduleEmit(PRODUCT_MODULE_NAME, ON_ELEMENT_ACTION, {
                eventCategory: PRODUCT_CATEGORIES.PRODUCT_SIZE_AVAILABLE,
                eventAction: GLOBAL_ACTIONS.VIEW,
                eventLabel,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@mixin dot() {
    @apply content-[''];
    @apply block;
    @apply w-ui-4;
    @apply h-ui-4;
    @apply bg-ui-swap-amber;
    @apply rounded-ui-percent-50;
}

.size-grid {
    .sizes {
        @apply grid grid-cols-ui-4;
        @apply gap-ui-2;

        grid-template-columns: repeat(auto-fill, minmax(79px, 1fr));
    }

    .legend {
        @apply flex justify-between items-center;
        @apply gap-x-ui-2;
        @apply mb-ui-2;
        @apply font-ui-body-m;
    }

    .choose-size-info {
        @apply mb-ui-2;
    }

    .selected-size-label {
        @apply text-ui-text-secondary-default;
    }

    .last-items {
        @apply font-ui-body-s;
        @apply text-ui-text-secondary-default;
        @apply flex items-center;
        @apply gap-x-ui-2;

        &:before {
            @include dot();
        }
    }

    .size {
        &.has-last-items {
            .size-content {
                &:before {
                    @include dot();
                }
            }
        }

        &.is-selected {
            .size-content {
                @apply font-ui-body-m-bold;
            }
        }

        &.is-out-of-stock {
            .size-content {
                @apply text-ui-text-disabled-default;
                @apply gap-x-ui-1;
            }
        }

        &.is-esizeme-recommended {
            &.basic {
                @apply border-ui-container-stroke-esizeme-default;
            }

            .size-content {
                @apply text-ui-text-esizeme-default;
            }
        }
    }

    .size-content {
        @apply font-ui-body-m;
        @apply flex items-center;
        @apply gap-x-ui-2;
        @apply whitespace-nowrap;
    }

    @screen ui-desktop {
        .legend {
            @apply mb-ui-1;
        }

        .choose-size-info {
            @apply font-ui-body-s;
        }
    }
}
</style>
