import { BEST_RATING, TYPE_AGGREGATE_RATING } from '@configs/structured-data';
import StructuredDataBaseClass from '@models/StructuredData/StructuredDataBaseClass';

export default class StructuredDataAggregateRating extends StructuredDataBaseClass {
    constructor(ratingValue, ratingCount, reviewCount) {
        super(TYPE_AGGREGATE_RATING);
        this.ratingValue = ratingValue;
        this.ratingCount = ratingCount;
        this.reviewCount = reviewCount;
    }

    build() {
        return {
            ...super.build(),
            ratingValue: this.ratingValue,
            ratingCount: this.ratingCount,
            reviewCount: this.reviewCount,
            bestRating: BEST_RATING,
        };
    }
}
