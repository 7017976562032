<template>
    <ClientOnly>
        <Observer
            :observe-once="true"
            :options="OBSERVER_OPTIONS"
            class="synthrone"
            @intersect="onIntersection($event)"
        >
            <div id="syndicatesth" ref="synthrone" />
        </Observer>
    </ClientOnly>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { CATEGORIES as PRODUCT_CATEGORIES } from '@analytics-module/modules/product/types/Categories';
import { MODULE_NAME as PRODUCT_MODULE_NAME } from '@analytics-module/modules/product/meta';
import { ON_ELEMENT_ACTION } from '@analytics-module/modules/product/types/Events';
import { ACTIONS as GLOBAL_ACTIONS } from '@analytics-module/types/Actions';

import loadScript from '@assets/loadScript';
import Deferred from '@assets/Deferred';

import Observer from '@atoms/Observer/Observer';

const OBSERVER_OPTIONS = {
    root: null,
    threshold: 0,
    rootMargin: '0px 0px 200px 0px',
};

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

const ATTRIBUTE_NAME = 'data-source-id';

export default {
    name: 'Synthrone',

    components: {
        Observer,
    },

    data() {
        return {
            mutationAttributeDeferred: new Deferred(),
        };
    },

    computed: {
        ...mapConfigGetters(['synthroneScriptUrl']),
    },

    beforeCreate() {
        this.OBSERVER_OPTIONS = OBSERVER_OPTIONS;
    },

    async mounted() {
        await this.$nextTick();

        this.initMutationObserver();
    },

    beforeDestroy() {
        this.mutationObserver?.disconnect();
        this.mutationObserver = null;
        this.mutationAttributeDeferred = null;
    },

    methods: {
        async onIntersection(isIntersecting) {
            if (!isIntersecting) {
                return;
            }

            const synthroneElement = this.$refs.synthrone;

            const dataSourceId = synthroneElement.getAttribute(ATTRIBUTE_NAME);

            if (!dataSourceId) {
                await loadScript(this.synthroneScriptUrl);
                await this.mutationAttributeDeferred.promise;
            }

            this.$analytics.moduleEmit(PRODUCT_MODULE_NAME, ON_ELEMENT_ACTION, {
                eventCategory: PRODUCT_CATEGORIES.PRODUCT_RICH_CONTENT,
                eventAction: GLOBAL_ACTIONS.VIEW,
                eventLabel: synthroneElement.getAttribute(ATTRIBUTE_NAME),
            });
        },

        initMutationObserver() {
            const synthroneElement = this.$refs.synthrone;

            if (!synthroneElement) {
                return;
            }

            this.mutationObserver = new MutationObserver(mutationsList => {
                mutationsList.forEach(mutation => {
                    if (mutation.attributeName !== ATTRIBUTE_NAME) {
                        return;
                    }

                    this.mutationAttributeDeferred.resolve();

                    this.mutationObserver.disconnect();
                });
            });

            this.mutationObserver.observe(synthroneElement, {
                attributes: true,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.synthrone {
    @apply mx-auto;

    max-width: 1200px;
}
</style>
