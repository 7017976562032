<template>
    <div class="product-badges">
        <Badge
            v-for="{ code, label, variant } in badgesMapped"
            :key="`product-badge-${code}`"
            :variant="variant"
            :size="BADGE_SIZES.XS"
            :size-desktop="BADGE_SIZES.S"
            class="badge"
        >
            <span v-html="label" />
        </Badge>
    </div>
</template>

<script>
import { BADGE_VARIANTS_MAP, TYPE_DISCOUNT, TYPE_OCCASION } from '@types/Badge';

import { Badge, BADGE_SIZES } from '@eobuwie-ui/components/Badge/v1';

const DISCOUNT_BADGE_TYPES = [TYPE_OCCASION, TYPE_DISCOUNT];

export default {
    name: 'ProductBadgesNew',

    components: {
        Badge,
    },

    props: {
        badges: {
            type: Object,
            required: true,
        },
    },

    computed: {
        badgesSorted() {
            return Object.values(this.badges).sort(
                (a, b) => this.isPromotionBadge(b) - this.isPromotionBadge(a)
            );
        },

        badgesMapped() {
            return this.badgesSorted.map(badge => ({
                ...badge,
                variant: BADGE_VARIANTS_MAP[badge.code],
            }));
        },
    },

    beforeCreate() {
        this.BADGE_SIZES = BADGE_SIZES;
    },

    methods: {
        isPromotionBadge(badge) {
            return DISCOUNT_BADGE_TYPES.includes(badge.code);
        },
    },
};
</script>

<style lang="scss" scoped>
.product-badges {
    @apply flex gap-x-ui-2;
}
</style>
