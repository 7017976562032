var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"product-specification"},[_c('BaseHeading',{staticClass:"heading",attrs:{"tag":"h2"}},[_c('CellList',{staticClass:"open-button",attrs:{"variant":_vm.CELL_LIST_VARIANTS.BASIC,"horizontal-padding":_vm.CELL_LIST_PADDINGS.PX_0,"label":_vm.$t('Information about product')},nativeOn:{"click":function($event){return _vm.open()}},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('Icon',{attrs:{"icon":_vm.ShevronRight}})]},proxy:true}])}),_vm._v(" "),_c('span',{staticClass:"desktop-heading"},[_vm._v("\n            "+_vm._s(_vm.$t('Information about product'))+"\n        ")])],1),_vm._v(" "),_c('MultiStepSideModal',{ref:"multiStepSideModal",staticClass:"multi-step-side-modal",attrs:{"is-open":_vm.isSideModalOpen,"headings":_vm.headings,"init-step":_vm.initStep,"apply-only-on-mobile-and-tablet":true},on:{"step-changed":function($event){return _vm.clearProductDetails($event)},"close":function($event){return _vm.close()}},scopedSlots:_vm._u([{key:"default",fn:function({ currentStep, nextStep }){return [(currentStep === _vm.STEP_PRODUCT_SPECIFICATION)?[(_vm.description)?_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e(),_vm._v(" "),_c('div',{ref:"content",staticClass:"content",class:{ 'is-hidden': _vm.isCollapse }},[_c('div',{staticClass:"columns-wrapper"},_vm._l((_vm.visibleProductValuesWithColumns),function({
                                title,
                                items,
                            }){return _c('dl',{key:title,staticClass:"column"},[_c('BaseHeading',{staticClass:"column-title",attrs:{"tag":"h3"}},[_vm._v("\n                                "+_vm._s(title)+"\n                            ")]),_vm._v(" "),_vm._l((items),function({ value, attribute },index){return _c('ProductSpecificationItem',{key:`item-${index}`,attrs:{"align-top":!!value && !!value.component},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                    "+_vm._s(_vm.getSpecificationItemLabel(
                                            attribute.label
                                        ))+"\n                                ")]},proxy:true},{key:"value",fn:function(){return [(attribute.filterable)?[_vm._l((value),function(filter,filterIndex){return [_c('BaseLink',{key:`filter-${index}-${filterIndex}`,staticClass:"link",attrs:{"path":_vm.getAttributePath(
                                                        filter.attribute
                                                            .url,
                                                        filter.value.url
                                                    ),"label":filter.value.label},nativeOn:{"click":function($event){return _vm.onLinkClick({
                                                        code:
                                                            attribute.code,
                                                        value:
                                                            filter.value
                                                                .label,
                                                    })}}}),_vm._v(" "),(
                                                    filterIndex + 1 <
                                                    value.length
                                                )?[_vm._v("\n                                                ,\n                                            ")]:_vm._e()]})]:(value && value.component)?[(
                                                Array.isArray(value.value)
                                            )?_vm._l((value.value),function(item){return _c(value.component,{key:item.name,tag:"component",staticClass:"column-item",attrs:{"item":item,"is-mobile":_vm.isMobile},on:{"click-link":function($event){return _vm.onLinkClick($event)},"open-next-step":function($event){return _vm.nextSpecificationStep(
                                                        item,
                                                        nextStep
                                                    )}}})}):_c(value.component,{tag:"component",staticClass:"column-item",attrs:{"item":value,"is-mobile":_vm.isMobile},on:{"open-next-step":function($event){return _vm.nextSpecificationStep(
                                                    value,
                                                    nextStep
                                                )}}})]:[_vm._v("\n                                        "+_vm._s(value)+"\n                                    ")],_vm._v(" "),(
                                            _vm.hasEcoFriendlyAttribute(
                                                attribute.code
                                            )
                                        )?[_c('QuestionMarkTooltip',{staticClass:"eco-friendly-tooltip",attrs:{"is-mobile":_vm.isMobile,"tooltip-content":_vm.$t(
                                                    // eslint-disable-next-line max-len
                                                    'A product whose negative impact on the environment is maximally reduced or eliminated.'
                                                ),"tooltip-position":_vm.POSITION_BOTTOM}})]:_vm._e()]},proxy:true}],null,true)})})],2)}),0),_vm._v(" "),_c('ProductGuaranteeOfOriginality')],1)]:_vm._e(),_vm._v(" "),(currentStep === _vm.STEP_PRODUCT_SPECIFICATION_DETAILS)?_c('ProductSpecificationItemMobile',{attrs:{"product-attributes-list":_vm.productAttributesList,"product-details-content":_vm.productDetailsContent},on:{"update-active-product-item":function($event){return _vm.updateActiveProductItem($event)},"click-link":function($event){return _vm.onLinkClick($event)}}}):_vm._e()]}},{key:"footer",fn:function({ currentStep }){return [(currentStep === _vm.STEP_PRODUCT_SPECIFICATION_DETAILS)?[_c('div',{staticClass:"go-back-to-product-button-wrapper"},[_c('ButtonSmall',{staticClass:"go-back-to-product-button",attrs:{"variant":_vm.BUTTON_VARIANT},on:{"click":function($event){return _vm.close()}}},[_vm._v("\n                        "+_vm._s(_vm.$t('Back to the product'))+"\n                    ")])],1)]:_vm._e()]}}])}),_vm._v(" "),_c('ButtonSmall',{staticClass:"show-more-button",attrs:{"variant":_vm.BUTTON_VARIANT},on:{"click":function($event){return _vm.showMoreToggle($event)}}},[_vm._v("\n        "+_vm._s(_vm.showMoreText)+"\n    ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }