import { createNamespacedHelpers } from 'vuex';

import { ESIZEME_LABEL_NONE } from '@configs/esizeme';
import { AB_TEST_NAME, EXCLUDED_CATEGORIES_IDS } from '@configs/fit-mate';
import { GTM_CATEGORIES_STORAGE_KEY } from '@configs/storage';

import { IS_DISCOUNT_ENABLED } from '@localeConfig/keys';

import { PRODUCT_ERROR_DOMAIN } from '@errors/feature-domain-names';

import { IMAGE_TYPE_PRODUCT_256w_256h } from '@types/Image';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { AB_TEST } from '@analytics-types/Events';

import Deferred from '@assets/Deferred';
import { convertDisplaySizeToFitMateSize } from '@assets/fit-mate';
import { getChosenSizeLabelFromVariant } from '@assets/product';
import { transformCartItem } from '@assets/cart';

import { MODULE_NAME as FIT_MATE_MODULE_NAME } from '@analytics-module/modules/fit-mate/meta';
import {
    FIT_MATE_ADD_SIZE,
    FIT_MATE_CLOSE,
    FIT_MATE_NO_RECOMMENDATION,
    FIT_MATE_OPEN_RECOMMENDATION,
    FIT_MATE_RECOMMENDATION,
    FIT_MATE_VISIBLE,
} from '@analytics-module/modules/fit-mate/types/Events';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const { mapGetters: mapEsizemeGetters } = createNamespacedHelpers('esizeme');
const {
    mapGetters: mapCartGetters,
    mapActions: mapCartActions,
    mapState: mapCartState,
} = createNamespacedHelpers('cart');

const ANALYTICS_CATEGORY = 'category_analysis_and_research';

export default {
    props: {
        product: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            fitMatePromise: null,
            isFitMateScriptLoaded: false,
            isFitMateWidgetEnabled: true,
            fitMateRecommendedSize: '',
            isFitMateRecommendationError: false,
            isConsentsAccepted: true,
        };
    },

    computed: {
        ...mapConfigGetters(['locale']),
        ...mapEsizemeGetters(['isEsizemeProductPageEnabled']),
        ...mapCartGetters(['cartItems', 'complexProductDataItemsSkusMap']),
        ...mapCartState(['complexProductDataItems']),

        hasSafeSizeData() {
            return this.product.hasTrueSize;
        },

        isInExcludedCategory() {
            return this.product.categories
                .flat()
                .some(({ id }) => EXCLUDED_CATEGORIES_IDS.includes(id));
        },

        isFitMateEnabled() {
            const isTestOn = this.$abTests.getVariant(AB_TEST_NAME) === 'on';

            return (
                isTestOn &&
                !this.isInExcludedCategory &&
                !this.isEsizemeProductPageEnabled &&
                this.isConsentsAccepted &&
                this.hasSafeSizeData
            );
        },

        variantsWithSizeLabels() {
            const {
                variant_dimension: variantDimension = null,
            } = this.productFamily;

            return Object.values(this.productVariants).reduce(
                (acc, productVariant) => {
                    const sizeLabel = getChosenSizeLabelFromVariant(
                        productVariant,
                        variantDimension
                    );

                    const sizeLabelFitMate = convertDisplaySizeToFitMateSize(
                        sizeLabel
                    );

                    const { stock_quantity: stockQuantity } = productVariant;

                    return {
                        ...acc,
                        [sizeLabelFitMate]: {
                            ...productVariant,
                            sizeLabel,
                            sizeLabelFitMate,
                            isOutOfStock: stockQuantity === 0,
                        },
                    };
                },
                {}
            );
        },

        availableVariants() {
            return Object.fromEntries(
                Object.entries(this.variantsWithSizeLabels)
                    .filter(
                        // eslint-disable-next-line no-unused-vars
                        ([_sizeKey, { isOutOfStock }]) => !isOutOfStock
                    )
                    .map(([sizeLabelFitMate, { size, sizeLabel }]) => [
                        sizeLabelFitMate,
                        { size, sizeLabel },
                    ])
            );
        },

        availableSizes() {
            return Object.keys(this.availableVariants);
        },

        fitMateImage() {
            return this.product.images[0][IMAGE_TYPE_PRODUCT_256w_256h].url;
        },

        fitMateInputData() {
            const {
                brandName: brand,
                id: index,
                model: shoeName,
            } = this.product;

            const inputData = {
                brand,
                image: this.fitMateImage,
                shoe_model: index,
                shoe_name: shoeName,
                stock_sizes: this.availableSizes.join('|'),
            };

            if (this.locale === 'el_GR') {
                inputData.lang = 'gr';
            }

            return inputData;
        },

        cartItemsWithPaths() {
            return this.cartItems.map(item =>
                transformCartItem({
                    item,
                    isDiscountEnabled: this.$getLocaleConfigByKey(
                        IS_DISCOUNT_ENABLED
                    ),
                })
            );
        },
    },

    created() {
        this.fitMateDeffered = new Deferred();

        this.fitMatePromise = this.fitMateDeffered.promise;

        if (!this.isFitMateEnabled) {
            this.fitMateDeffered.resolve();
        }
    },

    watch: {
        isFitMateScriptLoaded(value) {
            if (value) {
                this.getFitMateRecommendation();
            }
        },
    },

    mounted() {
        this.checkConsent();

        if (this.isFitMateEnabled) {
            this.bindEventListeners();

            this.loadScript();
        }

        this.sendFitMateTestAnalytics();
    },

    beforeDestroy() {
        this.unbindEventListeners();
        this.closeFitMateRecommendation();
    },

    methods: {
        ...mapCartActions([
            'addProductToCart',
            'setComplexDataProductItems',
            'addToCartPending',
        ]),

        checkConsent() {
            const storedCategories = this.$storage.getItem(
                GTM_CATEGORIES_STORAGE_KEY
            );

            if (!storedCategories) {
                this.isConsentsAccepted = false;

                return;
            }

            const analyticsCategory = storedCategories.find(
                ({ code }) => code === ANALYTICS_CATEGORY
            );

            this.isConsentsAccepted = analyticsCategory?.isActive || false;
        },

        sendFitMateTestAnalytics() {
            const variantName = this.$abTests.getVariant(AB_TEST_NAME);

            if (!variantName) {
                return;
            }

            this.$analytics.emit(AB_TEST, {
                testName: AB_TEST_NAME,
                variantName,
            });

            window.clarity?.('set', AB_TEST_NAME, variantName);
        },

        async loadScript() {
            if (window.recpkg_api) {
                this.isFitMateScriptLoaded = true;

                return;
            }

            await import(
                /* webpackChunkName: "fit-mate-script" */
                /* webpackMode: "lazy" */
                '@assets/fit-mate-script'
            );
        },

        onSafeSizeLoaded() {
            this.isFitMateScriptLoaded = true;
        },

        getFitMateRecommendation() {
            window.recpkg_api?.getRecommendation?.(this.fitMateInputData);
        },

        processSafeSizeResponse(response) {
            const { guiAvailable, recommendation } = response || {};

            if (!guiAvailable) {
                this.isFitMateWidgetEnabled = false;

                return;
            }

            if (!recommendation) {
                this.$analytics.moduleEmit(
                    FIT_MATE_MODULE_NAME,
                    FIT_MATE_VISIBLE,
                    {
                        index: this.product.id,
                    }
                );

                return;
            }

            const { size, recommendationStatusCode } = recommendation;

            if (recommendationStatusCode !== 101) {
                this.isFitMateRecommendationError = true;

                this.$analytics.moduleEmit(
                    FIT_MATE_MODULE_NAME,
                    FIT_MATE_NO_RECOMMENDATION,
                    {
                        index: this.product.id,
                    }
                );

                return;
            }

            this.fitMateRecommendedSize =
                this.variantsWithSizeLabels[size]?.sizeLabel || size;

            const sizeToChose = this.availableVariants[size]?.size;

            if (sizeToChose && !this.chosenSize) {
                this.$emit('set-chosen-size', sizeToChose);
            }

            this.$analytics.moduleEmit(
                FIT_MATE_MODULE_NAME,
                FIT_MATE_RECOMMENDATION,
                {
                    index: this.product.id,
                    size: this.fitMateRecommendedSize,
                    isAvailable: !this.variantsWithSizeLabels[size]
                        ?.isOutOfStock,
                }
            );
        },

        onSafeSizeRecommendedSize({ detail: response }) {
            this.processSafeSizeResponse(response);
            this.fitMateDeffered.resolve();
        },

        openFitMateRecommendation() {
            window.recpkg_api?.openRecommendationGUI?.(this.fitMateInputData);

            this.$analytics.moduleEmit(
                FIT_MATE_MODULE_NAME,
                FIT_MATE_OPEN_RECOMMENDATION,
                {
                    index: this.product.id,
                    hasRecommendation: !!this.fitMateRecommendedSize,
                }
            );
        },

        closeFitMateRecommendation() {
            window.recpkg_api?.closeRecommendationGUI?.();
        },

        async addToCartAsync({ chosenSize, chosenSizeLabel }) {
            const { product } = this;

            this.$emit('set-chosen-size', chosenSize);

            if (!this.complexProductDataItemsSkusMap[product.id]) {
                this.setComplexDataProductItems([
                    ...this.complexProductDataItems,
                    product,
                ]);
            }

            try {
                const { name: productName, variants, sku } = product;

                const addToCartData = {
                    parent_sku: sku,
                    data: {
                        sku: variants[chosenSize].id,
                        quantity: 1,
                    },
                };

                this.addToCartPending(true);

                const addedSku = await this.addProductToCart({
                    productName,
                    addToCartData,
                    chosenSizeLabel,
                    esizeme: ESIZEME_LABEL_NONE,
                    pageType: this.$route.name,
                    actionFieldList: this.$route.path,
                });

                if (addedSku) {
                    const addedCartItem = this.cartItemsWithPaths.find(
                        ({ skuVariant }) => skuVariant === addedSku
                    );

                    if (addedCartItem) {
                        addedCartItem.quantity = 0;

                        this.$emit(
                            'open-modal-added-to-cart-confirmation',
                            addedCartItem
                        );
                    }
                }
            } catch (err) {
                this.$errorHandler.captureDomainError(
                    PRODUCT_ERROR_DOMAIN,
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]: 'addToCart',
                    },
                    {
                        product,
                        chosenSize,
                        chosenSizeLabel,
                    }
                );
            } finally {
                this.addToCartPending(false);
            }
        },

        onSafeSizeCart({ detail }) {
            const { selectedSize } = detail.recommendation || {};

            const {
                size: chosenSize,
                sizeLabel: chosenSizeLabel,
            } = this.availableVariants[
                convertDisplaySizeToFitMateSize(selectedSize)
            ];

            this.$analytics.moduleEmit(
                FIT_MATE_MODULE_NAME,
                FIT_MATE_ADD_SIZE,
                {
                    index: this.product.id,
                    size: chosenSizeLabel,
                }
            );

            this.addToCartAsync({ chosenSize, chosenSizeLabel });
        },

        bindEventListeners() {
            document.addEventListener('safesize-loaded', this.onSafeSizeLoaded);
            document.addEventListener('safesize-to-cart', this.onSafeSizeCart);
            document.addEventListener(
                'safesize-recommended-size',
                this.onSafeSizeRecommendedSize
            );
            document.addEventListener('safesize-closed', this.onSafeSizeClosed);
        },

        onSafeSizeClosed() {
            this.$analytics.moduleEmit(FIT_MATE_MODULE_NAME, FIT_MATE_CLOSE, {
                index: this.product.id,
            });
        },

        unbindEventListeners() {
            document.removeEventListener(
                'safesize-loaded',
                this.onSafeSizeLoaded
            );
            document.removeEventListener(
                'safesize-to-cart',
                this.onSafeSizeCart
            );
            document.removeEventListener(
                'safesize-recommended-size',
                this.onSafeSizeRecommendedSize
            );
            document.removeEventListener(
                'safesize-closed',
                this.onSafeSizeClosed
            );
        },
    },
};
