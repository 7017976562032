var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Observer',{staticClass:"size-grid",attrs:{"observe-once":true,"options":_vm.SIZE_GRID_OBSERVER_OPTIONS},on:{"intersect":function($event){return _vm.onSizeGirdInViewport($event)}}},[_c('div',{staticClass:"legend"},[_c('span',{staticClass:"selected-size"},[_vm._v("\n            "+_vm._s(_vm.$t('Size'))+":\n\n            "),(_vm.hasOneVariant)?_c('span',{staticClass:"selected-size-label"},[_vm._v("\n                "+_vm._s(_vm.selectedSizeLabel)+"\n            ")]):_vm._e()]),_vm._v(" "),(_vm.hasAnyLastSizes)?_c('span',{staticClass:"last-items"},[_vm._v("\n            "+_vm._s(_vm.$t('Last items'))+"\n        ")]):_vm._e()]),_vm._v(" "),(!_vm.selectedSize && _vm.isNoSizeChosenInfoVisible)?_c('Message',{staticClass:"choose-size-info",attrs:{"variant":_vm.MESSAGE_VARIANT}},[_vm._v("\n        "+_vm._s(_vm.$t('Choose the size you want to add'))+"\n    ")]):_vm._e(),_vm._v(" "),(!_vm.hasOneVariant)?_c('div',{staticClass:"sizes"},_vm._l((_vm.productVariantsDisplay),function(variant){return _c('PillSize',{key:variant.id,staticClass:"size",class:{
                'has-last-items': variant.isRunningOutOfStock,
                'is-selected': variant.isSelected,
                'is-out-of-stock': variant.isOutOfStock,
                'is-esizeme-recommended':
                    _vm.recommendedSizeLabel === variant.sizeLabel,
            },attrs:{"selected":variant.isSelected,"data-test-id":_vm.PRODUCT_SIZE,"data-test-value":variant.size},on:{"select":function($event){return _vm.onSelect(variant)}}},[_c('span',{staticClass:"size-content",attrs:{"data-test-id":!variant.isOutOfStock ? _vm.PRODUCT_SIZES : null}},[(variant.isOutOfStock)?_c('Icon',{attrs:{"icon":_vm.Email,"width":"16px","height":"16px"}}):_vm._e(),_vm._v("\n\n                "+_vm._s(variant.sizeLabel)+"\n            ")],1)])}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }