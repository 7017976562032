<template>
    <div class="gallery-grid">
        <div
            :class="{
                'extended-row': extendedRow,
                'is-all-visible': isAllVisible,
                'has-single-photo': media.length === 1,
                'has-override-photo': !!photoOverride,
            }"
            class="items"
        >
            <template v-for="(mediaItem, index) in displayMedia">
                <BasePicture
                    v-if="PICTURE === mediaItem.type"
                    :key="mediaItem.image.url"
                    ref="item"
                    :src="mediaItem.image.url"
                    :alt="mediaItem.image.alt"
                    :is-lazy="mediaItem.isLazy"
                    :is-background-filter-on="true"
                    :fetch-priority="mediaItem.fetchPriority"
                    :sources="mediaItem.image.sources"
                    :height="mediaItem.image.height"
                    :width="mediaItem.image.width"
                    :data-index="index"
                    sizes="(min-width: 1024px) 400px, 100vw"
                    class="item"
                    @click.native="$emit('image-click', index)"
                />

                <GalleryVideo
                    v-else-if="VIDEO === mediaItem.type"
                    :key="mediaItem.video.url"
                    ref="item"
                    :image="mediaItem.imagePlaceholder.product"
                    :video="mediaItem.video"
                    :data-index="index"
                    :is-background-filter-on="true"
                    image-sizes="(min-width: 1024px) 400px, 100vw"
                    class="item"
                    @video-click="$emit('video-click', index)"
                />
            </template>
        </div>

        <div v-if="isMoreButtonVisible" class="button-row">
            <ButtonLarge
                :variant="BUTTON_LARGE_VARIANT"
                @click.native="showMore()"
            >
                {{ $t('Show more') }}
            </ButtonLarge>
        </div>
    </div>
</template>

<script>
import { PICTURE, VIDEO } from '@types/Gallery';

import { PRODUCT_ELEMENTS_GALLERY_SHOW_MORE_PHOTOS } from '@analytics-module/modules/product/types/Events';
import { MODULE_NAME as PRODUCT_MODULE_NAME } from '@analytics-module/modules/product/meta';

import BasePicture from '@atoms/BasePicture/BasePicture';
import GalleryVideo from '@organisms/ProductMainGallery/GalleryVideo';

import {
    ButtonLarge,
    BUTTON_LARGE_VARIANTS,
} from '@eobuwie-ui/components/ButtonLarge/v1';

const PHOTOS_COUNT_TRESHOLD = 5;

export default {
    name: 'GalleryGrid',

    components: { GalleryVideo, BasePicture, ButtonLarge },

    props: {
        media: {
            type: Array,
            default: () => [],
        },

        photoOverride: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            isAllVisible: false,
            viewObserver: null,
        };
    },

    computed: {
        extendedRow() {
            return this.media.length === PHOTOS_COUNT_TRESHOLD + 1;
        },

        isMoreButtonVisible() {
            return (
                this.media.length > PHOTOS_COUNT_TRESHOLD + 1 &&
                !this.isAllVisible
            );
        },

        displayMedia() {
            const media = this.media
                .map(
                    ({
                        type,
                        product,
                        fetchPriority,
                        isLazy,
                        video,
                        imagePlaceholder,
                    }) => {
                        if (type === PICTURE) {
                            return {
                                type,
                                image: product,
                                fetchPriority,
                                isLazy,
                            };
                        }

                        if (type === VIDEO) {
                            return {
                                type,
                                video,
                                imagePlaceholder,
                            };
                        }

                        return null;
                    }
                )
                .filter(item => item);

            const { photoOverride } = this;

            if (!photoOverride) {
                return media;
            }

            media.splice(0, 1, {
                image: photoOverride,
                isLazy: false,
                type: PICTURE,
            });

            return media;
        },
    },

    beforeCreate() {
        this.PICTURE = PICTURE;
        this.VIDEO = VIDEO;
        this.BUTTON_LARGE_VARIANT = BUTTON_LARGE_VARIANTS.SECONDARY;
    },

    mounted() {
        this.startViewObserver();
    },

    beforeDestroy() {
        this.stopViewObserver();
    },

    methods: {
        startViewObserver() {
            this.viewObserver = new IntersectionObserver(
                entries => {
                    entries.forEach(entry => {
                        const { target, isIntersecting } = entry;

                        if (isIntersecting) {
                            const index = parseInt(target.dataset.index, 10);

                            this.$emit('element-view', index);
                            this.viewObserver.unobserve(target);
                        }
                    });
                },
                { threshold: 0.75 }
            );

            this.$refs.item?.forEach(item => {
                this.viewObserver.observe(item.$el);
            });
        },

        stopViewObserver() {
            this.viewObserver?.disconnect();
            this.viewObserver = null;
        },

        showMore() {
            this.isAllVisible = true;

            this.$analytics.moduleEmit(
                PRODUCT_MODULE_NAME,
                PRODUCT_ELEMENTS_GALLERY_SHOW_MORE_PHOTOS,
                {
                    visibleMedia: PHOTOS_COUNT_TRESHOLD,
                    mediaCount: this.displayMedia.length,
                }
            );
        },

        // eslint-disable-next-line vue/no-unused-properties
        playVideo() {
            this.$refs.item?.forEach(item => {
                item.playVideoIframe?.();
            });
        },
    },
};
</script>

<style lang="scss" scoped>
$backgroundFilterColor: theme('customVariables.backgroundFilterColor');

.gallery-grid {
    .item {
        @apply cursor-pointer;
        @apply flex items-center justify-center;
        background-color: $backgroundFilterColor;

        &:nth-child(n + 2) {
            @apply hidden;
        }
    }

    .button-row {
        @apply hidden;
    }

    @media (min-width: 600px) and (max-width: 1023px) {
        .item {
            padding: 0 calc((100vw - 600px) / 2);
        }
    }

    @screen ui-desktop {
        .items {
            @apply grid gap-[2px];
            @apply grid-cols-ui-12;

            &.extended-row {
                .item {
                    &:nth-child(n + 3) {
                        @apply col-ui-span-3;
                    }

                    &:nth-child(n + 6) {
                        @apply flex;
                    }
                }
            }

            &.has-single-photo {
                background-color: $backgroundFilterColor;

                .item {
                    @apply col-[4_/_10];
                }
            }

            &.has-override-photo {
                .item {
                    &:nth-child(n + 2) {
                        @apply opacity-ui-hover-3;
                    }
                }
            }

            &:not(.is-all-visible) {
                &:not(.extended-row) {
                    .item {
                        &:nth-child(n + 6) {
                            @apply hidden;
                        }
                    }
                }
            }
        }

        .item {
            @apply col-ui-span-6;

            &:nth-child(n + 2) {
                @apply flex;
            }

            &:nth-child(n + 3) {
                @apply col-ui-span-4;
            }
        }

        .button-row {
            @apply flex justify-center;
            @apply pt-ui-6;
        }
    }
}
</style>
