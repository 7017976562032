<template>
    <div class="popular-seo-categories">
        <BaseHeading class="heading">
            {{ $t('Others also searched') }}
        </BaseHeading>

        <WithScroll
            :is-enabled="isMobile"
            :is-horizontal="true"
            :theme="THEME_LIGHT"
            class="scroll-container"
        >
            <div
                ref="linksWrapper"
                :class="{ 'is-collapsed': areCategoriesHidden }"
                class="links-wrapper"
            >
                <BaseLinkStatic
                    v-for="{ url, label } in categories"
                    :key="`seo-categories-${url}-${label}`"
                    :path="url"
                    class="link-pill"
                >
                    <SvgIcon width="16px" height="16px" class="icon">
                        <Search />
                    </SvgIcon>
                    {{ label }}
                </BaseLinkStatic>
            </div>
        </WithScroll>

        <ButtonComponent
            v-if="areCategoriesHidden"
            :variant="BUTTON_VARIANT"
            class="show-more-button"
            @click="showAllCategories()"
        >
            <template #icon>
                <Icon :icon="Add" width="20px" height="20px" />
            </template>
            {{ $t('Show more') }}
        </ButtonComponent>
    </div>
</template>

<script>
import { THEME_LIGHT } from '@types/Scroll';

import Search from '@static/icons/24px/search.svg?inline';

import WithScroll from '@atoms/WithScroll/WithScroll';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

import { Add } from '@eobuwie-ui/icons/v1/other';

import {
    BUTTON_COMPONENT_VARIANTS,
    ButtonComponent,
} from '@eobuwie-ui/components/ButtonComponent/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';

export default {
    name: 'PopularCategories',

    components: {
        BaseLinkStatic,
        Search,
        SvgIcon,
        ButtonComponent,
        Icon,
        BaseHeading,
        WithScroll,
    },

    props: {
        categories: {
            type: Array,
            required: true,
        },

        isMobile: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            areCategoriesHidden: true,
        };
    },

    beforeCreate() {
        this.BUTTON_VARIANT = BUTTON_COMPONENT_VARIANTS.PRIMARY_ACCENT;
        this.Add = Add;
        this.THEME_LIGHT = THEME_LIGHT;
    },

    methods: {
        showAllCategories() {
            this.areCategoriesHidden = false;
        },
    },
};
</script>

<style scoped lang="scss">
$two-rows-height: 84px;

.popular-seo-categories {
    @apply w-full;

    .heading {
        @apply leading-m text-m;
    }

    .links-wrapper {
        @apply mt-20p leading-s text-s grid grid-rows-2 grid-flow-col gap-y-1;
    }

    .show-more-button {
        @apply mt-4 hidden;
    }

    .link-pill {
        @apply bg-gray8 mr-1 border-none whitespace-nowrap px-12p py-5p rounded-16;
        @apply text-gray1 font-bold inline-flex items-center cursor-pointer;

        &:hover {
            @apply no-underline bg-gray5;
        }
    }

    .icon {
        @apply mr-1;
    }

    .scroll-container {
        @apply max-w-full;
    }

    @screen lg {
        .heading {
            @apply text-l leading-l;
        }

        .link-pill {
            @apply mr-2;
        }

        .links-wrapper {
            @apply mt-4 leading-r text-r flex flex-wrap gap-y-2;
        }

        .is-collapsed {
            @apply overflow-hidden;
            max-height: $two-rows-height;
        }

        .show-more-button {
            @apply flex;
        }

        .icon {
            @apply mr-6p;
        }
    }
}
</style>
