<template>
    <div v-if="totalPages > 1" class="pagination">
        <div class="buttons">
            <ButtonLarge
                v-if="!isFirstPage"
                :variant="BUTTON_LARGE_SECONDARY"
                :disabled="isDisabled"
                :to="createPath(currentPage - 1)"
                :class="{ 'single-button': isLastPage }"
                class="prev-button"
                @click="setPageNumber(currentPage - 1)"
            >
                {{ $t('Previous') }}
            </ButtonLarge>
            <ButtonLarge
                v-if="!isLastPage"
                :variant="BUTTON_LARGE_SECONDARY"
                :disabled="isDisabled"
                :to="createPath(currentPage + 1)"
                :class="{ 'single-button': isFirstPage }"
                class="next-button"
                @click="setPageNumber(currentPage + 1)"
            >
                {{ $t('Next') }}
            </ButtonLarge>
        </div>

        <div class="pagination-items">
            <PaginationItem
                v-for="(pageNumber, index) in pages"
                :key="`${pageNumber}-${index}`"
                :page-number="pageNumber"
                :current-page="currentPage"
                :disabled="isDisabled"
                :create-path="createPath"
                :range-separator="rangeSeparator"
                @click="setPageNumber(pageNumber)"
            />
        </div>
    </div>
</template>

<script>
import Pagination from '@mixins/Pagination';

import PaginationItem from '@atoms/PaginationItem/PaginationItem';

import {
    ButtonLarge,
    BUTTON_LARGE_VARIANTS,
} from '@eobuwie-ui/components/ButtonLarge/v1';

const SHOWN_PAGES_COUNT = 4;

export default {
    name: 'Pagination',

    components: {
        PaginationItem,
        ButtonLarge,
    },

    mixins: [Pagination],

    props: {
        pagesToDisplayBeforeApplySeparator: {
            type: Number,
            default: 6,
        },

        rangeSeparator: {
            type: String,
            default: '...',
        },
    },

    computed: {
        isMorePagesToDisplay() {
            return this.totalPages > SHOWN_PAGES_COUNT;
        },

        numberOfPagesToDisplay() {
            const { isMorePagesToDisplay, totalPages } = this;

            return isMorePagesToDisplay ? SHOWN_PAGES_COUNT : totalPages;
        },

        halfPagesCount() {
            return Math.floor(this.numberOfPagesToDisplay / 2) + 1;
        },

        isAfterHalf() {
            return this.currentPage > this.halfPagesCount;
        },

        startingPage() {
            let startingPage = 0;

            if (this.isAfterHalf) {
                startingPage = this.currentPage - 2;

                if (this.currentPage > this.totalPages - this.halfPagesCount) {
                    startingPage =
                        this.totalPages - this.numberOfPagesToDisplay;
                }
            }

            return startingPage;
        },

        pages() {
            if (!this.totalPages) {
                return [];
            }

            if (this.totalPages <= this.pagesToDisplayBeforeApplySeparator) {
                return this.createPagesArray(0, this.totalPages);
            }

            const pagesCountBetweenSeparator = this.isAfterHalf
                ? this.numberOfPagesToDisplay - 1
                : this.numberOfPagesToDisplay;

            const result = this.createPagesArray(
                this.startingPage,
                pagesCountBetweenSeparator
            );

            const prefix = [];
            const suffix = [];

            const first = result[0];
            const last = result[result.length - 1];

            if (first > 2) {
                prefix.unshift(this.rangeSeparator);
            }

            if (first > 1) {
                prefix.unshift(1);
            }

            if (this.totalPages - last >= 2) {
                suffix.push(this.rangeSeparator);
            }

            if (this.totalPages - last >= 1) {
                suffix.push(this.totalPages);
            }

            return [...prefix, ...result, ...suffix];
        },
    },

    beforeCreate() {
        this.BUTTON_LARGE_SECONDARY = BUTTON_LARGE_VARIANTS.SECONDARY;
    },

    beforeMount() {
        const { totalPages } = this;

        if (totalPages > 0 && this.currentPage > totalPages) {
            this.navigateToPage(totalPages);
        }
    },
};
</script>

<style lang="scss" scoped>
$pagination-button-width: 156px;

.pagination {
    @apply flex flex-col items-center;

    .buttons {
        @apply flex flex-nowrap mb-3 gap-3 w-full justify-center;
    }

    .prev-button:not(.single-button),
    .next-button:not(.single-button) {
        min-width: calc((40% - theme('spacing.3')) / 2);
    }

    .single-button {
        min-width: calc(40%);
    }

    .pagination-items {
        @apply w-full text-center;
    }
}
</style>
