<template>
    <WithWishlistedProducts :products-skus="[product.sku]">
        <article :data-test-id="PRODUCT" class="product">
            <ContainerContent :align-left="true">
                <Breadcrumbs
                    :breadcrumbs="breadcrumbsList"
                    :show-last-item="false"
                />
            </ContainerContent>

            <RecommendedProductsSliderWrapperNew
                v-if="!product.isInStock"
                :recommendation-type="WEB_EOB_PDP_UNAVBL_1"
                :product-sku="product.sku"
                :placement-page-name="PRODUCT_PLACEMENT_PAGE_NAME"
                :is-page-event-emitted-promise="
                    isPageEventEmittedDeferred.promise
                "
                class="slider slider-unavailable"
            />

            <ContainerContent class="product-data">
                <div
                    :data-test-id="PRODUCT_GALLERY"
                    :class="{ 'gallery-column-open': isModalGalleryOpened }"
                    class="gallery-column"
                >
                    <ProductMainGallery
                        :product="product"
                        :variant-image="variantImage"
                        :is-page-event-emitted-promise="
                            isPageEventEmittedDeferred.promise
                        "
                        :is-esizeme-info-loaded-promise="
                            isEsizemeInfoLoadedDeffered.promise
                        "
                        :is-color-variants-loaded-promise="
                            colorVariantsDeferred.promise
                        "
                        :esizeme-info="esizemeInfo"
                        :variants="colorVariantsWithStates"
                        @modal-gallery-open="isModalGalleryOpened = true"
                        @modal-gallery-close="isModalGalleryOpened = false"
                    >
                        <template #gallery-additional-content>
                            <ProductBadgesNew
                                v-if="hasProductBadges"
                                :badges="productBadges"
                                :class="{
                                    'is-half-transparent': !product.isInStock,
                                }"
                                class="badges"
                            />

                            <AddToWishlistWrapper
                                #default="{ isLoading, isAdded, toggle }"
                                :product="productWithActionField"
                                :chosen-size="chosenSize.toString()"
                                class="favourite-icon"
                            >
                                <ButtonIconToggle
                                    :variant="FAVOURITE_VARIANT"
                                    :size="FAVOURITE_SIZE"
                                    :disabled="isLoading"
                                    :toggled="isAdded"
                                    :data-test-id="ADD_TO_WISHLIST"
                                    @toggle="toggle()"
                                />
                            </AddToWishlistWrapper>
                        </template>
                    </ProductMainGallery>

                    <BadgePromoWide
                        v-if="product.promoSticker && promotionBadgeVisible"
                        :background-color="product.promoSticker.bgColor"
                        :color="product.promoSticker.textColor"
                        :label="product.promoSticker.content"
                        :description="`· ${$t('Valid until')}`"
                        :variant="BADGE_PROMO_WIDE_VARIANT"
                        class="promotion-badge"
                    >
                        <template #timer>
                            <Timer
                                :now="TIME_NOW"
                                :date="promoStickerEndDate"
                                :variant="TIMER_VARIANT"
                                :size="TIMER_SIZE"
                                :color="product.promoSticker.textColor"
                                @stop="promotionBadgeVisible = false"
                            />
                        </template>
                    </BadgePromoWide>

                    <PageBuilderInterpreter
                        v-if="cmsBannerPromoLinkBlock && !isMobile"
                        class="marketing-banner-promo-link-wrapper-desktop"
                        :components-json="cmsBannerPromoLinkBlock"
                    />
                </div>
                <div class="details-column">
                    <DutyDataProvider
                        #default="{ showDutyInformation }"
                        :product="product"
                    >
                        <ProductDetailsHeader
                            :product="product"
                            :product-name="seomaticProductName"
                            :chosen-size="chosenSize.toString()"
                            :product-average-rating="averageRating"
                            :is-size-chart-button="true"
                            :is-app-only="isAppOnly"
                            :reviews-total-count="reviewsTotalCount"
                            :size-set-on-init="sizeSetOnInit"
                            :is-spa="isSPA"
                            :loyalty-points="loyaltyPoints"
                            class="details-header"
                            @set-chosen-size="setChosenSize($event)"
                            @selected-not-available="
                                setChosenNotAvailableProductVariant($event)
                            "
                            @open-modal-added-to-cart-confirmation="
                                openAddedToCartConfirmationModal($event)
                            "
                            @open-availability-modal="
                                openAvailabilityNotificationModal()
                            "
                            @esizeme-info="esizemeInfo = $event"
                            @click-rating="isReviewsPanelHydrated = true"
                        >
                            <template #after-price>
                                <DutyInfo
                                    v-if="showDutyInformation"
                                    :extended="false"
                                />
                            </template>

                            <template #color-variants>
                                <div class="details-header-color-variants">
                                    <ColorVariants
                                        :variants="colorVariantsWithStates"
                                        :selected-index="
                                            selectedColorVariantIndex
                                        "
                                        :has-divider="false"
                                        :is-all-shown="
                                            selectedColorVariantIndex > 3
                                        "
                                        :is-loading="
                                            !isColorVariantsLoadingFinished
                                        "
                                        :class="{
                                            'without-variants': withoutVariants,
                                        }"
                                        class="color-variants"
                                        @variant-mouseover="
                                            showVariantPicture($event)
                                        "
                                        @variant-mouseleave="
                                            hideVariantPicture()
                                        "
                                        @show-more-variants="
                                            onShowMoreVariants()
                                        "
                                        @variant-click="onVariantClick($event)"
                                    >
                                        <template #image="{ variant }">
                                            <ProductCardPhoto
                                                :src="variant.image.src"
                                                :is-loading="variant.loading"
                                                :is-unavailable="
                                                    variant.unavailable
                                                "
                                                :alt="variant.image.alt"
                                                :width="variant.image.width"
                                                :height="variant.image.height"
                                                :is-background-filter-on="true"
                                            />
                                        </template>
                                        <template #caption-top="{ caption }">
                                            <b>{{ $t('Color') }}:</b>
                                            {{ caption || product.color }}
                                        </template>
                                    </ColorVariants>
                                </div>
                            </template>
                        </ProductDetailsHeader>
                    </DutyDataProvider>
                    <BrandShopItem
                        v-if="!isMobile && brandShop"
                        :heading="brandShop.heading"
                        :brand-name="brandShop.brandName"
                        :image-url="brandShop.imageUrl"
                        :url="brandShop.url"
                        class="brand-shop-desktop"
                    />
                </div>
            </ContainerContent>

            <ContainerContent
                :data-test-id="PRODUCT_DATA"
                class="specification-container"
            >
                <div class="wrapper">
                    <LazyHydrate :when-visible="true">
                        <ProductSpecification
                            :product-values="product.values"
                            :product-main-category="product.mainCategoryUrl"
                            :sku="product.sku"
                            :is-mobile="isMobile"
                            class="specification"
                        />
                    </LazyHydrate>

                    <Synthrone
                        v-if="isSynthroneEnabled"
                        class="synthrone-container"
                    />

                    <LazyHydrate
                        :when-visible="true"
                        :trigger-hydration="isReviewsPanelHydrated"
                    >
                        <ProductReviewsPanel
                            v-if="reviewsTotalCount > 0"
                            :sku="product.sku"
                            :reviews-data="reviews"
                            :average-rating="averageRating"
                            class="product-reviews-panel"
                        />
                    </LazyHydrate>

                    <BrandShopItem
                        v-if="isMobile && brandShop"
                        :heading="brandShop.heading"
                        :brand-name="brandShop.brandName"
                        :image-url="brandShop.imageUrl"
                        :url="brandShop.url"
                        class="brand-shop-mobile"
                    />
                </div>
            </ContainerContent>

            <PageBuilderInterpreter
                v-if="cmsPromoSliderBlock"
                :components-json="cmsPromoSliderBlock"
                class="promo-slider"
            />

            <PageBuilderInterpreter
                v-if="cmsBannerPromoLinkBlock && isMobile"
                class="marketing-banner-promo-link-wrapper-mobile"
                :components-json="cmsBannerPromoLinkBlock"
            />

            <RecommendedProductsSliderWrapperNew
                v-if="
                    pdp1CampaignId ||
                    checkIfPlacementHasCampaignId(WEB_EOB_PDP_1)
                "
                :recommendation-type="WEB_EOB_PDP_1"
                :custom-campaign-id="pdp1CampaignId"
                :product-sku="product.sku"
                :placement-page-name="PRODUCT_PLACEMENT_PAGE_NAME"
                :is-size-filter-enabled="isSizeFilterEnabled"
                :category-gender="categoryGender"
                class="slider"
            />

            <Observer
                v-if="isProductReviewsWrapperVisible"
                :observe-once="true"
                class="product-reviews"
                @intersect="onProductReviewsInViewport($event)"
            >
                <ProductReviewsWrapper
                    :sku="product.sku"
                    :reviews="reviews"
                    :average-rating="averageRating"
                />
            </Observer>

            <SponsoredSliderWrapper
                v-if="isSponsoredSliderEnabled"
                :product-url="product.urlKey"
                :placement-page-name="PRODUCT_PLACEMENT_PAGE_NAME"
                :heading="$t('Sponsored, selected for you')"
                class="slider"
            />

            <RecommendedProductsSliderWrapperNew
                v-if="
                    pdp2CampaignId ||
                    checkIfPlacementHasCampaignId(WEB_EOB_PDP_2)
                "
                :recommendation-type="WEB_EOB_PDP_2"
                :custom-campaign-id="pdp2CampaignId"
                :product-sku="product.sku"
                :placement-page-name="PRODUCT_PLACEMENT_PAGE_NAME"
                class="slider"
            />

            <RecentlyViewedProductsSliderWrapper :sku="product.sku" />

            <Observer
                v-if="isDisplayAdsContainerVisible"
                :observe-once="true"
                :options="ADS_DISPLAY_CONTAINER_OBSERVER_OPTIONS"
                class="display-container"
                @intersect="onDisplayAdsInViewport($event)"
            >
                <Display v-if="hasDisplayAdsData" :display="displayAdsData" />
            </Observer>

            <LazyHydrate :when-visible="true">
                <PopularBrandsAndCategoriesNew
                    v-if="popularBrands && popularCategories"
                    :key="seoLinks.categoryId"
                    :is-mobile="isMobile"
                    :brands="popularBrands"
                    :categories="popularCategories"
                />

                <PopularBrandsAndCategories v-else />
            </LazyHydrate>

            <ProductExitModalWrapper :sku="product.sku" :is-mobile="isMobile" />

            <AddedToCartConfirmationModal
                v-if="isAddedToCartConfirmationModalOpen && addedCartItem"
                :is-open="isAddedToCartConfirmationModalOpen"
                :cart-item="addedCartItem"
                @close="closeAddedToCartConfirmationModal()"
                @go-to-cart="goToCart()"
            />

            <ModalAddToCartError
                v-else-if="isOpenAddToCartErrorModal"
                :is-open="isOpenAddToCartErrorModal"
                :product="product"
                :chosen-size="chosenSize.toString()"
                :is-low-stock-error="isLowStockError"
                @close="resetAddProductToCartErrorModal()"
            />

            <AvailabilityNotificationModal
                v-if="isOpenAvailabilityNotificationModal"
                :unavailable-product-sizes="unavailableProductSizes"
                :has-one-variant="product.hasOneVariant"
                :is-one-size="product.isOneSize"
                :product-index="product.sku"
                :chosen-not-available-product-variant="
                    chosenNotAvailableProductVariant
                "
                @close="resetChosenNotAvailableProductVariant()"
            />
        </article>
    </WithWishlistedProducts>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import {
    MAX_BADGES_TO_DISPLAY,
    PRODUCT_CMS_BLOCKS_IDS,
    PSB_BRAND_SHOP,
} from '@configs/product';
import { REDIRECT_HTTP_CODE } from '@configs/http-codes';
import {
    PSB_PROMO_SLIDER,
    PSB_BANNER_PROMO_LINK,
} from '@configs/marketing-actions';
import { META_ROBOTS_INDEX_FOLLOW } from '@configs/seo';
import {
    SHOPPING_CMS_BLOCKS_IDS,
    PSB_PAYMENT_WIDGET,
} from '@configs/shopping-info';
import { PRODUCT_PAGE_REVIEWS_LIST_SIZE } from '@configs/reviews';
import { PSB_POPULAR_CONTENT_BLOCKS_IDS } from '@configs/popular-blocks';

import {
    BRAND_ATTRIBUTE_NAME,
    BRAND_WITH_COLLECTION_ATTRIBUTE_NAME,
    SALES_CHANNEL_ATTRIBUTE_NAME,
} from '@configs/product-attribute-names';
import {
    PLACEMENT_CATEGORY_MAP,
    PRODUCT_PLACEMENT_PAGE_NAME,
    PSB_SYNERISE_CAMPAIGNS_PDP_1_MAP,
    PSB_SYNERISE_CAMPAIGNS_PDP_2_MAP,
} from '@configs/synerise';
import { PRODUCT_SHOE_FAMILY_CODE } from '@configs/product-family';
import { AB_TEST_RECO_SIZE_FILTER } from '@configs/recommendations';

import {
    DAYS_FOR_RETURNS,
    IS_HREFLANG_ALLOWED,
    IS_SPONSORED_SLIDER_ENABLED,
    SHOW_DUTY_INFORMATION_NAME,
    SYNERISE_CONFIG_NAME,
} from '@localeConfig/keys';

import { ASYNC_SEOMATIC_SERVICE_CONFIG } from '@async-services/seomatic/meta';
import { ASYNC_LOYALTY_POINTS_ESTIMATOR_SERVICE_CONFIG } from '@loyalty-club/async-services/points-estimator/meta';

import { DEFAULT_LOCALE } from '@analytics-types/Analytics';
import { AB_TEST } from '@analytics-types/Events';

import { CHECKOUT_CART_PAGE_NAME, PRODUCT_PAGE_NAME } from '@router/names';
import {
    SEARCH_RESULTS_PAGE_NAME,
    CATALOG_PAGE_NAME,
} from '@search/routing/names';
import { HOME_PAGE } from '@router/paths';

import {
    IMAGE_FORMAT_WEBP_MIME_TYPE,
    DEFAULT_IMAGE_FORMAT,
    IMAGE_TYPE_PRODUCT_132w_176h,
    IMAGE_TYPE_PRODUCT_528w_704h,
    IMAGE_TYPE_PRODUCT_660w_880h,
    IMAGE_FORMAT_WEBP_EXTENSION,
} from '@types/Image';
import {
    WEB_EOB_PDP_1,
    WEB_EOB_PDP_2,
    WEB_EOB_PDP_UNAVBL_1,
    SYNERISE_RECOMMENDATION_PRODUCT_PLACEMENTS,
} from '@types/Synerise';
import { FETCH_PRIORITY } from '@types/FetchPriority';
import { EOBUWIE_CATEGORY_ID } from '@types/Navigation';
import {
    PRODUCT,
    PRODUCT_GALLERY,
    PRODUCT_DATA,
    ADD_TO_WISHLIST,
} from '@types/AutomaticTestIDs';
import {
    PRODUCT_DETAIL_VIEW,
    PRODUCT_REVIEW_BOX_VIEW,
    ON_ELEMENT_ACTION,
    PRODUCT_COLOR_VARIANTS_CLICK,
    PRODUCT_COLOR_VARIANTS_VIEW_OFFERS,
} from '@analytics-module/modules/product/types/Events';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { TYPE_APP_ONLY, TYPE_SOLD_OUT } from '@types/Badge';
import { FORMAT_YYYYMMDD_HHMM } from '@types/DateFormat';

import { CLIENT_IDENTITY_DOMAIN } from '@errors/feature-domain-names';

import {
    LOW_STOCK_ERROR,
    OUT_OF_STOCK_ERROR,
} from '@cherokee/store/modules/cart/actions';

import ProductNotFoundException from '@exceptions/services/product/ProductNotFoundException';

import DataLayerProduct from '@models/Analytics/DataLayerProduct';
import DateHelper from '@models/DateHelper/DateHelper';

import { isLoyaltyClubEnabled } from '@loyalty-club/assets';
import Deferred from '@core-assets/deferred';
import {
    unavailableProductSizes,
    normalizeProductSizeCode,
} from '@assets/product-sizes';
import {
    getStructuredDataProduct,
    getStructuredDataBreadcrumbList,
} from '@assets/structured-data';
import { isRejected } from '@assets/promise';
import { toHreflangLink } from '@assets/hreflang';
import { getSelectLocales } from '@assets/locale';
import { getUrlForSeomatic, makeSeomaticRedirect } from '@assets/seomatic';
import {
    getProductImage,
    getProductImageUrl,
    getPlaceholder,
} from '@assets/images';
import { calculateAverageFromRatings, loadReviews } from '@assets/reviews';
import {
    getChosenSizeLabel,
    getCategoriesWithBrandFilter,
    getCategoryUrlsWithColorFilter,
} from '@assets/product';
import { getSeoLinks } from '@assets/seo-links';
import { isHttpLink } from '@assets/link';
import { matchBestCategory } from '@assets/recommendations';
import { sortByActiveVariant } from '@assets/color-variants';
import LazyHydrate from '@assets/vue-lazy-hydration/LazyHydrate';

import { CMS_SYNERISE_MAP_CATEGORIES } from '@modules/page-builder/page-builder.config';

import BasePage from '@pages/mixins/BasePage';
import TitleBaseDomain from '@mixins/TitleBaseDomain';

import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

import { MODULE_NAME as PRODUCT_MODULE_NAME } from '@analytics-module/modules/product/meta';
import { CATEGORIES as PRODUCT_CATEGORIES } from '@analytics-module/modules/product/types/Categories';
import { ACTIONS as GLOBAL_ACTIONS } from '@analytics-module/types/Actions';
import { LABELS as GLOBAL_LABELS } from '@analytics-types/Labels';

import OverlayLoader from '@atoms/OverlayLoader/OverlayLoader';
import Observer from '@atoms/Observer/Observer';

import Display from '@molecules/Display/Display';
import DutyInfo from '@molecules/DutyInfo/DutyInfo';
import ProductBadgesNew from '@molecules/ProductBadgesNew/ProductBadgesNew';
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import DutyDataProvider from '@molecules/DutyDataProvider/DutyDataProvider';
import ProductSpecification from '@molecules/ProductSpecification/ProductSpecification';
import WithWishlistedProducts from '@molecules/WithWishlistedProducts/WithWishlistedProducts';
import PopularBrandsAndCategories from '@molecules/PopularBrandsAndCategories/PopularBrandsAndCategories';
import PopularBrandsAndCategoriesNew from '@molecules/PopularBrandsAndCategoriesNew/PopularBrandsAndCategoriesNew';
import BrandShopItem from '@molecules/BrandShop/BrandShopItem';
import Synthrone from '@molecules/Synthrone/Synthrone';

import ProductMainGallery from '@organisms/ProductMainGallery/ProductMainGallery';
import ProductDetailsHeader from '@organisms/ProductDetailsHeader/ProductDetailsHeader';
import ProductReviewsWrapper from '@organisms/ProductReviewsWrapper/ProductReviewsWrapper';
import ProductExitModalWrapper from '@organisms/ProductExitModalWrapper/ProductExitModalWrapper';
import RecommendedProductsSliderWrapperNew from '@organisms/RecommendedProductsSliderWrapperNew/RecommendedProductsSliderWrapperNew';
import RecentlyViewedProductsSliderWrapper from '@organisms/RecentlyViewedProductsSliderWrapper/RecentlyViewedProductsSliderWrapper';
import SponsoredSliderWrapper from '@organisms/SponsoredSlider/SponsoredSliderWrapper';
import ProductReviewsPanel from '@organisms/ProductReviewsPanel/ProductReviewsPanel';

import AddToWishlistWrapper from '@molecules/AddToWishlistWrapper/AddToWishlistWrapper';

import {
    ButtonIconToggle,
    BUTTON_ICON_TOGGLE_VARIANTS,
    BUTTON_ICON_TOGGLE_SIZES,
} from '@eobuwie-ui/components/ButtonIconToggle/v1';
import { Breadcrumbs } from '@eobuwie-ui/components/Breadcrumbs/v1';
import {
    BadgePromoWide,
    BADGE_PROMO_WIDE_VARIANTS,
} from '@eobuwie-ui/components/BadgePromoWide/v1';
import {
    Timer,
    TIMER_VARIANTS,
    TIMER_SIZES,
} from '@eobuwie-ui/components/Timer/v1';
import { ProductCardPhoto } from '@eobuwie-ui/components/ProductCardPhoto/v1';
import { ColorVariants } from '@eobuwie-ui/components/ColorVariants/v1';

const {
    mapState: mapConfigState,
    mapGetters: mapConfigGetters,
} = createNamespacedHelpers('config');

const {
    mapActions: mapRecentlyViewedProductsActions,
} = createNamespacedHelpers('recentlyViewedProducts');

const { mapActions: mapCategoryActions } = createNamespacedHelpers('category');

const {
    mapState: mapProductState,
    mapActions: mapProductActions,
} = createNamespacedHelpers('product');

const {
    mapActions: mapModalsActions,
    mapState: mapModalsState,
} = createNamespacedHelpers('modals');

const {
    mapActions: mapCmsBlockActions,
    mapGetters: mapCmsBlockGetters,
} = createNamespacedHelpers('cmsBlock');

const DISPLAY_ADS_PLACEMENT = 'PRODUCTPAGE';

export default {
    name: 'Product',

    components: {
        Synthrone,
        ProductReviewsPanel,
        SponsoredSliderWrapper,
        PageBuilderInterpreter,
        RecommendedProductsSliderWrapperNew,
        RecentlyViewedProductsSliderWrapper,
        WithWishlistedProducts,
        ProductReviewsWrapper,
        ContainerContent,
        Breadcrumbs,
        ProductDetailsHeader,
        ProductMainGallery,
        DutyDataProvider,
        DutyInfo,
        ProductSpecification,
        ProductExitModalWrapper,
        PopularBrandsAndCategories,
        PopularBrandsAndCategoriesNew,
        ProductBadgesNew,
        Observer,
        Display,
        BrandShopItem,
        BadgePromoWide,
        Timer,
        ProductCardPhoto,
        ColorVariants,
        LazyHydrate,
        AddToWishlistWrapper,
        ButtonIconToggle,

        AddedToCartConfirmationModal: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "added-to-cart-confirmation-modal" */ '@organisms/AddedToCartConfirmationModal/AddedToCartConfirmationModal'
            ),
        }),

        AvailabilityNotificationModal: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "modal-availability-notification-modal" */ '@organisms/AvailabilityNotification/AvailabilityNotificationModal'
            ),

            loading: OverlayLoader,
            delay: 0,
            timeout: 4000,
        }),

        ModalAddToCartError: () => ({
            component: import(
                /* webpackChunkName: "modal-add-to-cart-error" */
                '@organisms/ModalAddToCartError/ModalAddToCartError'
            ),
        }),
    },

    mixins: [
        BasePage({
            sendPageEvent: false,
        }),
        TitleBaseDomain,
    ],

    layout: () => 'default',

    middleware: ['parse-product-route-url'],

    async asyncData({ app, store, error, from, query, redirect, route, req }) {
        const urlForSeomatic = getUrlForSeomatic(req, route);
        const getSeomaticPromise = async () =>
            app.$asyncServices.use(
                ASYNC_SEOMATIC_SERVICE_CONFIG.NAME,
                ASYNC_SEOMATIC_SERVICE_CONFIG.METHODS.GET_SEO,
                {
                    url: urlForSeomatic,
                }
            );

        const getStaticBlocksPromise = async () =>
            store.dispatch('cmsBlock/getCmsBlocks', {
                cmsBlocksToLoad: [
                    ...PRODUCT_CMS_BLOCKS_IDS,
                    ...PSB_POPULAR_CONTENT_BLOCKS_IDS,
                    ...SHOPPING_CMS_BLOCKS_IDS,
                ],
            });
        const commonPromises = [
            getSeomaticPromise(),
            store.dispatch('seo/getSeoData'),
            getStaticBlocksPromise(),
        ];

        const productPageBlocks = [
            PSB_BANNER_PROMO_LINK,
            PSB_PROMO_SLIDER,
            PSB_BRAND_SHOP,
            PSB_PAYMENT_WIDGET,
            PSB_SYNERISE_CAMPAIGNS_PDP_1_MAP,
            PSB_SYNERISE_CAMPAIGNS_PDP_2_MAP,
        ];

        const productPageBlocksToLoad = productPageBlocks.filter(
            block =>
                !store.getters['cmsBlock/pageBuilderBlockById'](block).length
        );

        if (productPageBlocksToLoad.length > 0) {
            await store.dispatch('cmsBlock/getPageBuilderBlocks', {
                pageBuilderBlocksToLoad: productPageBlocksToLoad,
            });
        }

        const fromPath = from ? from.path : '';

        const {
            product: { urlKey, sku },
            category: { currentProduct },
            config: {
                storeView: { base_currency_code: currency, locale },
                storeViews,
            },
        } = store.state;

        let product = null;
        let isFromCategory = false;
        let seomatic = null;
        let seoData = {};

        if (
            [CATALOG_PAGE_NAME, SEARCH_RESULTS_PAGE_NAME].includes(from?.name)
        ) {
            isFromCategory = true;
            product = currentProduct;
        }

        if (product) {
            const [seomaticPromise, seoDataPromise] = await Promise.allSettled(
                commonPromises
            );

            seomatic = seomaticPromise.value;
            seoData = seoDataPromise.value;

            store.dispatch(
                'product/setColorVariants',
                sortByActiveVariant(product.colorVariants || [], sku)
            );
        } else {
            const [
                productPromise,
                seomaticPromise,
                seoDataPromise,
            ] = await app.$fetchInParallel([
                app.$services.product.getByUrlKey({
                    urlKey,
                    locale,
                    currency,
                    selectLocales: getSelectLocales(
                        locale,
                        app.$getLocaleConfigByKey(SHOW_DUTY_INFORMATION_NAME)
                    ),
                }),
                ...commonPromises,
            ]);

            seomatic = seomaticPromise.value;

            if (seomatic?.redirect) {
                try {
                    return makeSeomaticRedirect(
                        seomatic.redirect,
                        redirect,
                        route
                    );
                } catch (err) {
                    app.$errorHandler.captureError(
                        err,
                        {
                            [ERROR_ACTION_TAG_NAME]: 'makeSeomaticRedirect',
                        },
                        {
                            urlForSeomatic,
                            seomatic,
                        }
                    );
                }
            }

            if (isRejected(productPromise)) {
                const err = productPromise.reason;

                if (err instanceof ProductNotFoundException) {
                    return error({
                        statusCode: err.statusCode,
                        message: err.message,
                    });
                }

                return error({
                    statusCode: 500,
                    message: 'Error getting product.',
                });
            }

            product = productPromise.value;
            seoData = seoDataPromise.value;

            if (urlKey !== product.urlKey) {
                const queryString = Object.keys(query)
                    .map(param => `${param}=${query[param]}`)
                    .join('&');
                const queryStringSign = queryString ? '?' : '';

                return redirect(
                    REDIRECT_HTTP_CODE,
                    `${product.url}${queryStringSign}${queryString}`
                );
            }
        }

        if (!product.category) {
            app.$errorHandler.captureError(
                new Error(`Product with sku ${product.sku} has no category.`)
            );

            return error({
                message: 'Something went wrong.',
                statusCode: 500,
            });
        }

        const { chosenSize } = product;

        const productUrls = product?.values?.url_key?.value;
        let metaLinks = [];

        if (productUrls) {
            const isHreflangAllowedInLocale = app.$getLocaleConfigByKey(
                IS_HREFLANG_ALLOWED
            )[EOBUWIE_CATEGORY_ID];

            metaLinks = storeViews
                .filter(
                    ({ locale: loc }) =>
                        isHreflangAllowedInLocale?.[loc] || false
                )
                .map(({ locale: loc, base_url }) => {
                    const url = productUrls[loc];

                    if (!url) {
                        return null;
                    }

                    return toHreflangLink(
                        loc,
                        `https://${base_url}${app.$createProductPath(url)}`
                    );
                })
                .filter(localeLink => localeLink);
        }

        const { colorVariantsCount } = product;

        let reviews = {};

        if (process.server) {
            const { reviews: reviewsLoaded } = await loadReviews({
                app,
                sku: product.sku,
                currentPage: 1,
                pageSize: PRODUCT_PAGE_REVIEWS_LIST_SIZE,
            });

            reviews = reviewsLoaded;
        }

        const isColorVariantsLoadingFinished =
            from?.name !== SEARCH_RESULTS_PAGE_NAME &&
            Boolean(!colorVariantsCount || colorVariantsCount <= 1);

        if (isColorVariantsLoadingFinished) {
            store.dispatch('product/setColorVariants', []);
        }

        return {
            chosenSize,
            metaLinks,
            isFromCategory,
            urlKey,
            product,
            fromPath,
            isFromProduct: from?.name === PRODUCT_PAGE_NAME,
            isSPA: !!from,
            seomatic,
            seomaticProductName: seomatic?.seo?.name,
            seoData,
            reviews,
            isColorVariantsLoadingFinished,
        };
    },

    data() {
        return {
            addedCartItem: null,
            isAddedToCartConfirmationModalOpen: false,
            isFullProductDataLoadedDeferred: new Deferred(),
            isEsizemeInfoLoadedDeffered: new Deferred(),
            variantImage: null,
            isAnalyticsEventSent: false,
            sizeSetOnInit: false,
            esizemeInfo: null,
            isDisplayAdsContainerVisible: true,
            displayAdsData: null,
            isProductDetailViewEventEmittedDeferred: new Deferred(),
            promotionBadgeVisible: true,
            isReviewsPanelHydrated: false,
            loyaltyPoints: 0,
            chosenNotAvailableProductVariant: null,
            isModalGalleryOpened: false,
            colorVariantsDeferred: new Deferred(),
        };
    },

    head() {
        const {
            currency,
            baseUrl,
            canonicalUrl,
            breadcrumbsString,
            product: {
                name,
                brandName,
                color,
                images,
                price,
                sku,
                variants,
                category,
            },
            metaLinks,
            seomatic,
            averageRating,
            reviews,
        } = this;
        const {
            title: seoTitle,
            description: seoDescription,
            robots: seoRobots,
        } = seomatic?.seo?.meta || {};
        const { canonical_url: seoCanonicalUrl } = seomatic?.seo || {};

        const title = seoTitle || `${name} | ${this.titleBaseDomain}`;
        const description =
            seoDescription ||
            [
                name,
                this.$t('Stylish {category} from top brands #eobuwie', {
                    category: category.label,
                }),
                this.$t('Free delivery and {days} days return', {
                    days: this.$getLocaleConfigByKey(DAYS_FOR_RETURNS),
                }),
                this.$t('Eobuwie online store'),
            ].join(' | ');

        const meta = [
            {
                hid: 'robots',
                name: 'robots',
                content: seoRobots || META_ROBOTS_INDEX_FOLLOW,
            },
            {
                hid: 'title',
                name: 'title',
                content: title,
            },
            {
                hid: 'og:title',
                property: 'og:title',
                content: title,
            },
            {
                hid: 'description',
                name: 'description',
                content: description,
            },
            {
                hid: 'og:description',
                property: 'og:description',
                content: description,
            },
            {
                hid: 'og:type',
                property: 'og:type',
                content: 'product',
            },
            {
                hid: 'product:price:currency',
                property: 'product:price:currency',
                content: currency,
            },
            {
                hid: 'product:price:amount',
                property: 'product:price:amount',
                content: price.promotional.amount,
            },
            {
                hid: 'product:brand',
                property: 'product:brand',
                content: brandName,
            },
            {
                hid: 'product:color',
                property: 'product:color',
                content: color,
            },
            {
                hid: 'product:retailer_part_no',
                property: 'product:retailer_part_no',
                content: sku,
            },
            {
                hid: 'product:mfr_part_no',
                property: 'product:mfr_part_no',
                content: sku, // @todo change to proper value
            },
            {
                hid: 'product:category',
                property: 'product:category',
                content: breadcrumbsString,
            },
        ];

        const link = [
            ...metaLinks,
            {
                hid: 'canonical',
                rel: 'canonical',
                href: seoCanonicalUrl || this.canonicalUrl,
            },
        ];

        const firstImage = images?.[0][IMAGE_TYPE_PRODUCT_528w_704h];

        if (typeof firstImage !== 'undefined') {
            const { width, height, url, alt } = firstImage;

            meta.push(
                {
                    hid: 'og:image',
                    property: 'og:image',
                    content: url,
                },
                {
                    hid: 'og:image:secure_url',
                    property: 'og:image:secure_url',
                    content: url,
                },
                {
                    hid: 'og:image:width',
                    property: 'og:image:width',
                    content: width,
                },
                {
                    hid: 'og:image:height',
                    property: 'og:image:height',
                    content: height,
                },
                {
                    hid: 'og:image:alt',
                    property: 'og:image:alt',
                    content: alt,
                },
                {
                    hid: 'og:image:type',
                    property: 'og:image:type',
                    content: DEFAULT_IMAGE_FORMAT.mimeType,
                }
            );
        }

        const preloadMedia = [
            {
                alias: IMAGE_TYPE_PRODUCT_660w_880h,
                media: '(max-width: 1023px)',
            },
            {
                alias: IMAGE_TYPE_PRODUCT_528w_704h,
                media: '(min-width: 1024px)',
            },
        ];

        preloadMedia.forEach(({ alias, media }) => {
            const firstWebPProductImageUrl = getProductImageUrl(
                images[0],
                alias,
                IMAGE_FORMAT_WEBP_EXTENSION
            );

            if (firstWebPProductImageUrl) {
                link.push({
                    rel: 'preload',
                    href: firstWebPProductImageUrl,
                    as: 'image',
                    fetchpriority: FETCH_PRIORITY.HIGH,
                    type: IMAGE_FORMAT_WEBP_MIME_TYPE,
                    media,
                });
            }
        });

        return {
            titleTemplate: titleChunk => titleChunk,
            title,
            meta,
            htmlAttrs: {
                prefix:
                    'og: http://ogp.me/ns# product: http://ogp.me/ns/product#',
            },

            link,
            script: [
                {
                    hid: 'ldjson-schema-breadcrumbs',
                    innerHTML: getStructuredDataBreadcrumbList(
                        this.breadcrumbs,
                        this.$route.fullPath,
                        this.$absoluteUrl
                    ).toJSON(),

                    type: 'application/ld+json',
                },
                {
                    hid: 'ldjson-schema-product',
                    innerHTML: getStructuredDataProduct({
                        name,
                        description,
                        sku,
                        category,
                        images,
                        brandName,
                        baseUrl,
                        canonicalUrl,
                        price,
                        currency,
                        reviews,
                        averageRating,
                        variants,
                    }).toJSON(),

                    type: 'application/ld+json',
                },
            ],

            __dangerouslyDisableSanitizersByTagID: {
                'ldjson-schema-breadcrumbs': ['innerHTML'],
                'ldjson-schema-product': ['innerHTML'],
            },
        };
    },

    computed: {
        ...mapState(['isMobile', 'isMobileEvaluated']),
        ...mapConfigState(['baseUrl']),
        ...mapConfigGetters([
            'locale',
            'localeConfigByKey',
            'synthroneScriptUrl',
            'synthroneBrands',
            'storeCode',
        ]),

        ...mapCmsBlockGetters([
            'staticBlockById',
            'getComponentFromPageBuilderBlock',
        ]),

        ...mapModalsState([
            'isOpenAvailabilityNotificationModal',
            'cartItemData',
            'isOpenAddToCartErrorModal',
        ]),

        ...mapProductState(['listingCategoryId', 'colorVariants']),

        isLoyaltyClubEnabled() {
            return isLoyaltyClubEnabled(
                this.$abTests,
                this.storeCode,
                this.$cookies
            );
        },

        isSynthroneEnabled() {
            const brandValue = this.product.values[BRAND_ATTRIBUTE_NAME] || {};

            return (
                isHttpLink(this.synthroneScriptUrl) &&
                this.synthroneBrands.includes(brandValue.value?.url)
            );
        },

        matchedCategory() {
            const {
                listingCategoryId,
                product: { categories },
            } = this;

            if (categories.length === 0) {
                return [];
            }

            const [defaultCategory] = categories;

            if (!listingCategoryId) {
                return defaultCategory;
            }

            const [matchedCategory] = categories.filter(category =>
                category.some(
                    subcategory => subcategory.id === listingCategoryId
                )
            );

            return matchedCategory || defaultCategory;
        },

        categoryGender() {
            const manCategories = ['2445', '3157'];
            const womanCategories = ['2439', '3156'];

            // eslint-disable-next-line no-restricted-syntax
            for (const { id } of this.matchedCategory) {
                if (manCategories.includes(id)) {
                    return 'man';
                }

                if (womanCategories.includes(id)) {
                    return 'woman';
                }
            }

            return null;
        },

        breadcrumbs() {
            const { locale, matchedCategory } = this;

            return matchedCategory.map(item => {
                const { label: name = '', url = '' } =
                    item.translations[locale] || {};

                const { id } = item;

                return {
                    id,
                    name,
                    path: url ? this.$createCategoryPath(url) : '',
                };
            });
        },

        breadcrumbsList() {
            return [
                {
                    name: this.$t('Home page'),
                    path: HOME_PAGE,
                },
                ...this.breadcrumbs.slice(1),
                {
                    name: this.product.name,
                },
            ];
        },

        breadcrumbsString() {
            return this.breadcrumbs.map(item => item.name).join('/');
        },

        averageRating() {
            return (
                calculateAverageFromRatings?.(this.reviews?.averageRatings) || 0
            );
        },

        reviewsTotalCount() {
            return this.reviews?.totalCountAll || 0;
        },

        withoutVariants() {
            return (
                this.isColorVariantsLoadingFinished &&
                this.colorVariants.length === 0
            );
        },

        unavailableProductSizes() {
            return unavailableProductSizes(this.product);
        },

        getProductSizes() {
            return Object.keys(this.product.variants);
        },

        hasProductBadges() {
            return Object.keys(this.productBadges).length > 0;
        },

        isAppOnly() {
            const value =
                this.product.values[SALES_CHANNEL_ATTRIBUTE_NAME]?.value?.[
                    this.locale
                ] || {};

            const list = Object.values(value).map(({ label }) => label);

            return list.includes('app') && !list.includes('web');
        },

        productBadges() {
            const { badges, isInStock } = this.product;

            if (!isInStock) {
                return {
                    outOfStock: {
                        code: TYPE_SOLD_OUT,
                        label: this.$t('Sold out'),
                    },
                };
            }

            if (!this.isAppOnly) {
                return badges;
            }

            const badgesToDisplay = Object.entries(badges);

            badgesToDisplay.splice(1, 0, [
                'app-only',
                {
                    code: TYPE_APP_ONLY,
                    label: this.$t('Only in the app'),
                },
            ]);

            return Object.fromEntries(
                badgesToDisplay.slice(0, MAX_BADGES_TO_DISPLAY)
            );
        },

        cmsBannerPromoLinkBlock() {
            const bannerPromoLinkBlock = this.staticBlockById(
                PSB_BANNER_PROMO_LINK
            );

            return bannerPromoLinkBlock?.content_json || null;
        },

        cmsPromoSliderBlock() {
            const promoSliderBlock = this.staticBlockById(PSB_PROMO_SLIDER);

            return promoSliderBlock?.content_json || null;
        },

        cmsActionBrandShop() {
            const actionBlock = this.staticBlockById(PSB_BRAND_SHOP);

            return actionBlock?.content_json || null;
        },

        brandShop() {
            const { cmsActionBrandShop } = this;

            if (!cmsActionBrandShop) {
                return null;
            }

            const brandItemList =
                JSON.parse(this.cmsActionBrandShop)[0].slots.default?.[0]?.slots
                    ?.default || [];

            const productBrandNormalized = this.product.brandName.toLocaleLowerCase();

            const brandItem = brandItemList.find(
                ({ props }) =>
                    props.brandName.toLocaleLowerCase() ===
                    productBrandNormalized
            );

            return brandItem?.props || null;
        },

        isSponsoredSliderEnabled() {
            return this.localeConfigByKey(IS_SPONSORED_SLIDER_ENABLED);
        },

        chosenSizeLabel() {
            return getChosenSizeLabel(this.product, this.chosenSize);
        },

        hasDisplayAdsData() {
            return Object.keys(this.displayAdsData || {}).length > 0;
        },

        categories() {
            return this.product.categories.map(category =>
                category
                    .map(
                        subcategory =>
                            subcategory.translations[this.locale]?.url || ''
                    )
                    .filter(subcategory => !!subcategory)
                    .at(-1)
            );
        },

        seoLinks() {
            return getSeoLinks(this.product.categories, this.seoData);
        },

        popularBrands() {
            return this.seoLinks.brands;
        },

        popularCategories() {
            const { product = {}, $createCategoryPath } = this;

            const { brandName } = product;

            const brandUrl = this.product.values[BRAND_ATTRIBUTE_NAME].value
                .url;
            const brandAttribute = this.product.attributes[
                BRAND_WITH_COLLECTION_ATTRIBUTE_NAME
            ];

            return [
                ...getCategoriesWithBrandFilter({
                    categories: this.matchedCategory.slice(1),
                    brandAttribute,
                    brandUrl,
                    brandName,
                    locale: this.locale,
                    $createCategoryPath,
                }),
                ...getCategoryUrlsWithColorFilter(product, $createCategoryPath),
                ...this.seoLinks.categories,
            ];
        },

        isProductReviewsWrapperVisible() {
            return this.reviewsTotalCount;
        },

        isLowStockError() {
            return this.cartItemData?.error === LOW_STOCK_ERROR;
        },

        syneriseCampaignIds() {
            const { locale, pdp1CampaignId, pdp2CampaignId } = this;

            const pdpCampaignIds = SYNERISE_RECOMMENDATION_PRODUCT_PLACEMENTS.map(
                placement =>
                    this.$services.recommendations.getCampaignIdByType(
                        placement,
                        locale
                    )
            );

            const pdp1PlacementCampaignId = this.$services.recommendations.getCampaignIdByType(
                WEB_EOB_PDP_1,
                locale
            );

            const pdp2PlacementCampaignId = this.$services.recommendations.getCampaignIdByType(
                WEB_EOB_PDP_2,
                locale
            );

            if (pdp1CampaignId) {
                pdpCampaignIds.push(pdp1CampaignId);
            } else if (pdp1PlacementCampaignId) {
                pdpCampaignIds.push(pdp1PlacementCampaignId);
            }

            if (pdp2CampaignId) {
                pdpCampaignIds.push(pdp2CampaignId);
            } else if (pdp2PlacementCampaignId) {
                pdpCampaignIds.push(pdp2PlacementCampaignId);
            }

            return pdpCampaignIds;
        },

        pdp1CampaignId() {
            return this.getSyneriseCampaignIdForPlacement(WEB_EOB_PDP_1);
        },

        pdp2CampaignId() {
            return this.getSyneriseCampaignIdForPlacement(WEB_EOB_PDP_2);
        },

        promoStickerEndDate() {
            return DateHelper.toJSDate(
                this.dateHelper.createDateFromStringInTimezone(
                    this.product.promoSticker?.endDate || ''
                )
            );
        },

        colorVariantsWithStates() {
            return this.colorVariants.map(item => {
                const { images = [] } = item;

                const [image] = images;

                const displayImage =
                    image?.[IMAGE_TYPE_PRODUCT_132w_176h] ||
                    getPlaceholder(IMAGE_TYPE_PRODUCT_132w_176h);

                const {
                    query: { size = '' },
                } = this.$route;

                const isUnavailable =
                    !this.product.isOneSize && this.isVariantUnavailable(item);

                const url =
                    !size || isUnavailable
                        ? item.url
                        : `${item.url}?size=${size}`;

                return {
                    ...item,
                    url,
                    image: { ...displayImage, src: displayImage.url },
                    active: item.url === this.product.url,
                    unavailable: isUnavailable,
                };
            });
        },

        selectedColorVariantIndex() {
            return this.colorVariantsWithStates.findIndex(item => item.active);
        },

        productWithActionField() {
            return {
                ...this.product,
                actionField: this.$route.path,
            };
        },

        isSizeFilterEnabled() {
            return (
                this.product.family.code === PRODUCT_SHOE_FAMILY_CODE &&
                !!this.categoryGender &&
                this.$abTests.getVariant(AB_TEST_RECO_SIZE_FILTER) === 'on'
            );
        },
    },

    watch: {
        esizemeInfo: {
            handler() {
                this.isEsizemeInfoLoadedDeffered.resolve();
            },
        },

        cartItemData(data, oldData) {
            if (!data && oldData?.error) {
                this.chosenSize = '';
                this.setQuerySize(null);
            }

            const { error } = data || {};

            if (error === OUT_OF_STOCK_ERROR) {
                this.loadProduct(true);
            }
        },
    },

    async mounted() {
        await this.loadProduct();

        this.loadColorVariants();

        const { product } = this;

        if (this.product.hasOneVariant && !this.chosenSizeLabel) {
            this.chosenSize = this.product.chosenSize;
        }

        this.setInitProductSize();

        if (this.isLoyaltyClubEnabled) {
            this.getPointEstimationForProduct();
        }

        this.addProductToRecentlyViewed(product);

        if (Object.keys(this.reviews).length === 0) {
            const { reviews } = await loadReviews({
                app: this,
                sku: product.sku,
                currentPage: 1,
                pageSize: PRODUCT_PAGE_REVIEWS_LIST_SIZE,
            });

            this.reviews = reviews;
        }

        const { averageRatings, totalCountAll } = this.reviews || {};
        const rating = averageRatings?.[0]?.value;

        this.setProductsReviewsTotalCount({
            [product.sku]: totalCountAll,
        });

        this.setProductsReviewsAverageRating(rating);

        // event for synthrone script to reexecute on spa navigation
        document.dispatchEvent(
            new CustomEvent('product-loaded', { bubbles: true })
        );

        this.sendAnalyticsEvents();
    },

    beforeCreate() {
        this.$store.dispatch('setRouteRenderStart', {
            name: 'product',
            timestampStart: new Date().getTime(),
        });

        this.WEB_EOB_PDP_UNAVBL_1 = WEB_EOB_PDP_UNAVBL_1;
        this.PRODUCT_PLACEMENT_PAGE_NAME = PRODUCT_PLACEMENT_PAGE_NAME;
        this.PRODUCT = PRODUCT;
        this.PRODUCT_GALLERY = PRODUCT_GALLERY;
        this.PRODUCT_DATA = PRODUCT_DATA;

        this.WEB_EOB_PDP_1 = WEB_EOB_PDP_1;
        this.WEB_EOB_PDP_2 = WEB_EOB_PDP_2;

        this.ADS_DISPLAY_CONTAINER_OBSERVER_OPTIONS = {
            root: null,
            threshold: 0,
            rootMargin: '0px 0px 200px 0px',
        };

        this.TIMER_VARIANT = TIMER_VARIANTS.DATE_BASIC;
        this.TIMER_SIZE = TIMER_SIZES.XS;
        this.BADGE_PROMO_WIDE_VARIANT = BADGE_PROMO_WIDE_VARIANTS.S;

        this.ADD_TO_WISHLIST = ADD_TO_WISHLIST;
        this.FAVOURITE_VARIANT = BUTTON_ICON_TOGGLE_VARIANTS.FAVOURITE;
        this.FAVOURITE_SIZE = BUTTON_ICON_TOGGLE_SIZES.S;
    },

    created() {
        if (process.client) {
            this.$analytics.waitPromise = this.isProductDetailViewEventEmittedDeferred.promise;

            if (this.isColorVariantsLoadingFinished) {
                this.colorVariantsDeferred.resolve();
            }
        }

        this.dateHelper = new DateHelper(this.timezone, FORMAT_YYYYMMDD_HHMM);
        this.TIME_NOW = DateHelper.toJSDate(
            this.dateHelper.createCurrentDateInTimezone()
        );
    },

    beforeDestroy() {
        this.setCurrentProduct(null);
    },

    methods: {
        ...mapCategoryActions(['setCurrentProduct']),
        ...mapProductActions([
            'setProductsReviewsTotalCount',
            'setProductsReviewsAverageRating',
            'setColorVariants',
        ]),

        ...mapCmsBlockActions(['getPageBuilderBlocks']),
        ...mapModalsActions([
            'openAvailabilityNotificationModal',
            'resetAddProductToCartErrorModal',
        ]),

        ...mapRecentlyViewedProductsActions({
            addProductToRecentlyViewed: 'addProduct',
        }),

        async getPointEstimationForProduct() {
            try {
                const { brandName, price, name, id, variants } =
                    this.product || {};
                const { promotional, regular } = price || {};

                const sku =
                    this.chosenSize && variants
                        ? variants[this.chosenSize].id
                        : null;

                const data = await this.$asyncServices.use(
                    ASYNC_LOYALTY_POINTS_ESTIMATOR_SERVICE_CONFIG.NAME,
                    ASYNC_LOYALTY_POINTS_ESTIMATOR_SERVICE_CONFIG.METHODS
                        .GET_POINTS_ESTIMATOR_FOR_PRODUCTS,
                    {
                        storeCode: this.storeCode,
                        items: [
                            {
                                index: id,
                                sku,
                                brand: brandName,
                                productName: name,
                                price: promotional?.amount || regular?.amount,
                            },
                        ],
                    }
                );

                if (!data || !Array.isArray(data)) {
                    this.loyaltyPoints = null;

                    return;
                }

                const { points } = data[0];

                this.loyaltyPoints = points;
            } catch (err) {
                this.$errorHandler.captureDomainError(
                    CLIENT_IDENTITY_DOMAIN,
                    err
                );

                this.loyaltyPoints = null;
            }
        },

        async loadProduct(force = false) {
            if (!this.isFromCategory && !force) {
                this.isFullProductDataLoadedDeferred.resolve(true);

                return;
            }

            const { locale, currency } = this;

            try {
                this.product = await this.$services.product.getByUrlKey({
                    urlKey: this.urlKey,
                    locale,
                    currency,
                    selectLocales: getSelectLocales(
                        locale,
                        this.$getLocaleConfigByKey(SHOW_DUTY_INFORMATION_NAME)
                    ),
                });
            } catch {
                /* empty */
            }

            this.isFullProductDataLoadedDeferred.resolve(true);
        },

        goToCart() {
            this.$router.push({
                name: CHECKOUT_CART_PAGE_NAME,
            });
        },

        setChosenSize(chosenSize) {
            if (this.chosenSize === chosenSize) {
                return;
            }

            this.setQuerySize(chosenSize);
            this.chosenSize = chosenSize;

            if (this.isLoyaltyClubEnabled) {
                this.getPointEstimationForProduct();
            }
        },

        setQuerySize(size) {
            const query = { ...(this.$route.query || {}) };

            if (size === query.size) {
                return;
            }

            if (size) {
                query.size = size;
            } else {
                delete query.size;
            }

            this.$router.replace({ query });
        },

        closeAddedToCartConfirmationModal() {
            this.addedCartItem = null;
            this.isAddedToCartConfirmationModalOpen = false;
        },

        openAddedToCartConfirmationModal(addedCartItem) {
            this.addedCartItem = addedCartItem;
            this.isAddedToCartConfirmationModalOpen = true;
        },

        setChosenNotAvailableProductVariant(productVariant) {
            this.chosenNotAvailableProductVariant = productVariant;
            this.openAvailabilityNotificationModal();
        },

        resetChosenNotAvailableProductVariant() {
            this.chosenNotAvailableProductVariant = null;
        },

        async loadColorVariants() {
            if (this.isColorVariantsLoadingFinished) {
                return;
            }

            const {
                locale,
                currency,
                isFromProduct,
                product: {
                    colorVariantsLink: { url, value },
                    sku,
                },
                colorVariantsDeferred,
            } = this;

            if (!url || !value) {
                this.setColorVariants([]);

                this.isColorVariantsLoadingFinished = true;
                colorVariantsDeferred.resolve();

                return;
            }

            if (this.colorVariants?.[0]?.colorVariantsLink?.value === value) {
                if (!isFromProduct) {
                    this.setColorVariants(
                        sortByActiveVariant(this.colorVariants, sku)
                    );
                }

                this.isColorVariantsLoadingFinished = true;
                colorVariantsDeferred.resolve();

                return;
            }

            const colorVariants = await this.$services.product.getColorVariants(
                url,
                [value],
                locale,
                currency,
                [DEFAULT_LOCALE]
            );

            this.setColorVariants(sortByActiveVariant(colorVariants, sku));

            this.isColorVariantsLoadingFinished = true;
            colorVariantsDeferred.resolve();

            this.onProductColorVariantOffers();
        },

        showVariantPicture(productId) {
            const product = this.colorVariants.find(
                variant => variant.id === productId
            );

            if (product.sku === this.product.sku) {
                this.hideVariantPicture();

                return;
            }

            this.variantImage = getProductImage(product, 0, [
                IMAGE_TYPE_PRODUCT_660w_880h,
                IMAGE_TYPE_PRODUCT_528w_704h,
            ]);
        },

        onVariantClick(productId) {
            const position = this.colorVariantsWithStates.findIndex(
                variant => variant.id === productId
            );

            const product = this.colorVariantsWithStates[position];

            this.$analytics.moduleEmit(
                PRODUCT_MODULE_NAME,
                PRODUCT_COLOR_VARIANTS_CLICK,
                {
                    productSku: product.sku,
                    position,
                    isAvailable: !product.unavailable,
                }
            );
        },

        onProductColorVariantOffers() {
            const offersCountCalculated = this.colorVariantsWithStates.length;
            const offersAvailableCalculated = this.colorVariantsWithStates.filter(
                offer => offer.unavailable === false
            ).length;

            this.$analytics.moduleEmit(
                PRODUCT_MODULE_NAME,
                PRODUCT_COLOR_VARIANTS_VIEW_OFFERS,
                {
                    offersCount: offersCountCalculated,
                    offersAvailable: offersAvailableCalculated,
                    offersNotAvailable:
                        offersCountCalculated - offersAvailableCalculated,
                }
            );
        },

        hideVariantPicture() {
            this.variantImage = null;
        },

        onShowMoreVariants() {
            this.$analytics.moduleEmit(PRODUCT_MODULE_NAME, ON_ELEMENT_ACTION, {
                eventCategory: PRODUCT_CATEGORIES.PRODUCT_COLOR_VARIANTS,
                eventAction: GLOBAL_ACTIONS.CLICK,
                eventLabel: GLOBAL_LABELS.SHOW_MORE,
            });
        },

        setInitProductSize() {
            let {
                query: { size = '' },
            } = this.$route;

            size = normalizeProductSizeCode(size);

            if (
                !size ||
                !this.getProductSizes.includes(size) ||
                this.unavailableProductSizes.some(el => el.size === size)
            ) {
                return;
            }

            this.sizeSetOnInit = true;
            this.chosenSize = size;
        },

        async sendAnalyticsEvents() {
            const attributionToken = this.$route.query.token ?? null;

            const { route } = this.$router.resolve({
                name: PRODUCT_PAGE_NAME,
                params: {
                    urlKey: this.product.analytics.urlKey,
                },
            });

            await this.isCustomerFetchedDeferred.promise.then(() => {
                this.emitPageEvent({
                    route,
                    syneriseCampaignIds: this.syneriseCampaignIds,
                });
            });

            this.emitProductDetailViewEvent(attributionToken);

            const variantNameRecoSizeFilterTest = this.$abTests.getVariant(
                AB_TEST_RECO_SIZE_FILTER
            );

            if (variantNameRecoSizeFilterTest) {
                this.$analytics.emit(AB_TEST, {
                    testName: AB_TEST_RECO_SIZE_FILTER,
                    variantName: variantNameRecoSizeFilterTest,
                });

                window.clarity?.(
                    'set',
                    AB_TEST_RECO_SIZE_FILTER,
                    variantNameRecoSizeFilterTest
                );
            }

            this.isAnalyticsEventSent = true;
        },

        onProductReviewsInViewport(isInViewport) {
            if (isInViewport) {
                this.$analytics.moduleEmit(
                    PRODUCT_MODULE_NAME,
                    PRODUCT_REVIEW_BOX_VIEW,
                    {
                        reviewsCount: this.reviewsTotalCount,
                        rating: this.averageRating,
                    }
                );
            }
        },

        checkIfPlacementHasCampaignId(placement) {
            const syneriseConfig = this.$getLocaleConfigByKey(
                SYNERISE_CONFIG_NAME
            );

            return !!syneriseConfig?.placements?.[placement];
        },

        async onDisplayAdsInViewport(isInViewport) {
            if (!isInViewport) {
                return;
            }

            const result = await this.$services.adTech.getDisplay({
                payload: {
                    placement: DISPLAY_ADS_PLACEMENT,
                    categoryPaths: this.categories,
                    sourceUrl: this.$route.fullPath,
                    productId: this.product.sku,
                },
                locale: this.locale,
            });

            const [data = {}] = result?.display || [];

            this.displayAdsData = data;

            if (Object.keys(data).length === 0) {
                this.isDisplayAdsContainerVisible = false;
            }
        },

        async emitProductDetailViewEvent(attributionToken) {
            await Promise.all([
                this.isFullProductDataLoadedDeferred.promise,
                this.isPageEventEmittedDeferred.promise,
            ]);

            await this.$analytics.moduleEmit(
                PRODUCT_MODULE_NAME,
                PRODUCT_DETAIL_VIEW,
                {
                    attributionToken,
                    currency: this.currency,
                    product: new DataLayerProduct({
                        product: this.product,
                        reviews: this.reviews,
                        variantId: this.product.variants?.[this.chosenSize]?.id,
                        esizemeChosenSizeLabel: this.esizemeInfo
                            ?.esizemeChosenSizeLabel,
                    }).build(),
                    actionFieldList: this.fromPath,
                    isAppOnly: this.isAppOnly,
                },
                true
            );

            this.isProductDetailViewEventEmittedDeferred.resolve();
        },

        isVariantUnavailable(item) {
            const { chosenSize } = this;

            if (!chosenSize) {
                return false;
            }

            const variant = item.variants[chosenSize];

            return !variant || parseInt(variant.stock_quantity, 10) === 0;
        },

        getSyneriseCampaignIdForPlacement(placement) {
            const testCampaignId = this.$services.recommendations.getTestCampaignId(
                placement,
                this.locale
            );

            if (testCampaignId) {
                return testCampaignId;
            }

            const blockName = PLACEMENT_CATEGORY_MAP[placement];

            const blockComponents = this.getComponentFromPageBuilderBlock(
                blockName,
                CMS_SYNERISE_MAP_CATEGORIES
            );

            const categoriesMap = blockComponents?.props?.categoriesMap || {};

            const bestCategoryId = matchBestCategory(
                this.matchedCategory,
                Object.keys(categoriesMap)
            );

            return categoriesMap[bestCategoryId] || null;
        },
    },
};
</script>

<style lang="scss" scoped>
$promo-slider-margin-top-lg: 80px;

.product {
    .gallery-column,
    .details-column {
        @apply w-ui-percent-100;
    }

    .badges {
        @apply absolute top-ui-6 left-ui-6;
    }

    .promotion-badge {
        @apply -ml-ui-4;
        @apply -mr-ui-4;
    }

    .slider {
        @apply mt-7;

        &.slider-unavailable {
            @apply mt-ui-0;
            @apply mb-ui-6;
        }
    }

    .color-variants {
        @apply my-ui-4;
        @apply relative z-ui-0;

        &.without-variants {
            &:deep(.caption) {
                @apply mb-ui-0;
            }

            &:deep(.list) {
                @apply hidden;
            }
        }

        &:deep(.skeleton-wrapper) {
            @apply pb-[133.33333%];
        }

        &:deep(.list) {
            @apply gap-y-ui-2;
        }

        &:deep(.variant) {
            @apply bg-ui-container-secondary-default;

            &.selected {
                &:after {
                    @apply top-ui-auto;
                    @apply bottom-ui-0;
                    @apply mt-ui-0;
                }
            }
        }
    }

    .favourite-icon {
        @apply absolute top-ui-6 right-ui-4;
        @apply z-ui-1;
    }

    &:deep(.favourite-icon.button-icon) {
        @apply bg-ui-swap-white;
    }

    @screen mobile-and-tablet-only {
        .details-column {
            @apply mt-ui-4;
        }

        .color-variants {
            &:deep(.variant) {
                @apply w-[66px] h-[88px];
            }
        }
    }

    @screen ui-desktop {
        .promotion-badge {
            @apply hidden;
        }

        .product-data {
            @apply grid;
            @apply gap-x-ui-3;
            @apply items-start;
            @apply grid-cols-ui-12;
        }

        .gallery-column {
            @apply pr-ui-7;
            @apply sticky top-ui-7;
            @apply col-ui-span-8;

            &.gallery-column-open {
                @apply z-ui-2;
            }
        }

        .details-column {
            @apply h-ui-percent-100;
            @apply col-ui-span-4;
        }

        .slider {
            @apply mt-8;
        }

        .color-variants {
            &:deep(.list) {
                @apply pb-ui-0;
            }
        }
    }

    @screen lg {
        .favourite-icon {
            @apply top-ui-3 right-ui-3;
        }

        &:deep(.favourite-icon.button-icon) {
            @apply w-ui-13 h-ui-13;
        }
    }
}

.product-reviews,
.specification-container,
.related-categories-links {
    @apply mt-5;
}

.wrapper {
    @apply w-full;
}

.promo-slider {
    @apply mt-5;
}

.specification {
    &:deep(.open-button) {
        @apply border-t-1 border-b-1;
    }
}

.marketing-banner-promo-link-wrapper-desktop,
.brand-shop-desktop {
    @apply hidden;
}

.marketing-banner-promo-link-wrapper-mobile {
    @apply mt-4 px-3 w-full;
}

.display-container {
    @apply my-5;
}

.brand-shop-mobile {
    @apply -mx-3;
    width: calc(100% + (2 * #{theme('spacing.3')}));
}

.synthrone-container {
    @apply mt-3;
}

@screen mobile-and-tablet-only {
    .product-reviews {
        @apply hidden;
    }

    .details-header-color-variants {
        @apply -mx-ui-4;

        &:deep(.variant) {
            &:first-child {
                @apply ml-ui-4;
            }

            &:last-child {
                @apply mr-ui-4;
            }
        }

        &:deep(.caption) {
            @apply ml-ui-4;
        }
    }
}

@screen lg {
    .specification {
        @apply mt-5;
    }

    .promo-slider {
        margin-top: $promo-slider-margin-top-lg;
    }

    .brand-shop-mobile {
        @apply hidden;
    }

    .marketing-banner-promo-link-wrapper-desktop {
        @apply block mt-6;
    }

    .display-container {
        @apply mt-8;
    }

    .brand-shop-desktop {
        @apply flex mt-5 w-full;
    }

    .product-reviews-panel {
        @apply hidden;
    }
}
</style>
