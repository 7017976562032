var render = function render(){var _vm=this,_c=_vm._self._c;return (
        _vm.shoppingInfoStaticBlocks.length ||
        _vm.isKlarnaWidgetEnabled ||
        _vm.isPaypoWidgetEnabled ||
        _vm.paymentCustomText
    )?_c('div',{staticClass:"shopping-additional-info"},[(_vm.isTestDeliveryPromiseOn)?_c('div',{staticClass:"item"},[_c('Icon',{staticClass:"item-svg",attrs:{"icon":_vm.Green}}),_vm._v(" "),_c('div',[_vm._v("\n            "+_vm._s(_vm.deliveryTime)+"\n        ")])],1):_vm._e(),_vm._v(" "),_vm._t("before-static-blocks",null,{"isTestDeliveryPromiseOn":_vm.isTestDeliveryPromiseOn}),_vm._v(" "),_vm._l((_vm.shoppingInfoStaticBlocks),function({ id, icon, blockId, block }){return _c('div',{key:blockId,staticClass:"item"},[_c('SvgIcon',{staticClass:"item-svg",class:id},[_c(icon,{tag:"component"})],1),_vm._v(" "),_c('div',{attrs:{"data-test-id":blockId === _vm.PSB_SHOPPING_SHIPPING_RETURN
                    ? _vm.FREE_SHIPPING_AND_RETURN
                    : null}},[_vm._v("\n            "+_vm._s(block.title)+"\n        ")]),_vm._v(" "),_c('BaseTooltip',{staticClass:"shopping-additional-info-tooltip",attrs:{"title":_vm.$t('More'),"is-mobile":_vm.isMobile,"position":_vm.POSITION_LEFT,"tabindex":"0","tooltip-container-class":"shopping-additional-info-tooltip-container"},on:{"tooltip-showed":function($event){return _vm.onTooltipShowed(id)}},scopedSlots:_vm._u([{key:"tooltipContent",fn:function(){return [(block)?_c('StaticBlock',{attrs:{"content":block.content}}):_vm._e()]},proxy:true}],null,true)},[_c('BaseParagraph',{staticClass:"more-info"},[_vm._v("\n                "+_vm._s(_vm.$t('More'))+"\n            ")])],1)],1)}),_vm._v(" "),(_vm.isPaypoWidgetEnabled)?_c('PaypoInfoWidget',{staticClass:"paypo-info-widget"},[_c('div',{staticClass:"item"},[_c('SvgIcon',{staticClass:"item-svg"},[_c('Paypo')],1),_vm._v(" "),_c('div',[_vm._v("\n                "+_vm._s(_vm.$t('Buy now and pay in 30 days'))+"\n            ")]),_vm._v(" "),_c('SvgIcon',{staticClass:"tooltip-icon",attrs:{"width":"20px","height":"20px"}},[_c('QuestionMark')],1)],1)]):(_vm.isKlarnaWidgetEnabled)?_c('KlarnaPromotionBadge',{staticClass:"item",attrs:{"price":_vm.price}}):(_vm.paymentCustomText)?_c('div',{staticClass:"item"},[_vm._v("\n        "+_vm._s(_vm.paymentCustomText)+"\n    ")]):_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }