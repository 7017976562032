<template>
    <div class="product-reviews-panel">
        <CellList
            :variant="CELL_LIST_VARIANTS.BASIC"
            :horizontal-padding="CELL_LIST_PADDINGS.PX_0"
            :label="panelTitle"
            class="open-reviews-button"
            @click.native="openReviews()"
        >
            <template #button>
                <Icon :icon="ShevronRight" />
            </template>
        </CellList>

        <RatingStars :rating="averageRating" class="stars" />

        <MultiStepSideModal
            v-if="isSideModalOpen"
            ref="multiStepSideModal"
            :class="{
                'is-close-hidden': !isModalCloseButtonVisible,
            }"
            :is-open="isSideModalOpen"
            :headings="headings"
            :init-step="initStep"
            :apply-only-on-mobile-and-tablet="true"
            :defer-open-to-mount="true"
            class="multi-step-side-modal"
            @step-changed="onStepChanged($event)"
            @close="close()"
        >
            <template #default="{ currentStep, nextStep }">
                <ProductReviews
                    v-if="currentStep === STEP_REVIEWS_LIST"
                    :reviews-data="reviewsData"
                    :average-rating="averageRating"
                    :sku="sku"
                    :has-link-how-reviews-work="isHowOpinionsWorkBlockExist"
                    class="product-reviews"
                    @open-modal-how-opinions-work="nextStep()"
                />

                <StaticBlock
                    v-else-if="
                        currentStep === STEP_HOW_REVIEWS_WORK &&
                        isHowOpinionsWorkBlockExist
                    "
                    :content="howOpinionsWorkCmsBlock.content"
                    class="block"
                />
            </template>
        </MultiStepSideModal>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { PSB_HOW_OPINIONS_WORK } from '@configs/reviews';
import { MODAL_REVIEWS_PANEL_NAME } from '@configs/modals';

import { PRODUCT_READ_ALL_OPINIONS_CLICK } from '@analytics-module/modules/product/types/Events';
import { MODULE_NAME as PRODUCT_MODULE_NAME } from '@analytics-modules/product/meta';

import RatingStars from '@atoms/RatingStars/RatingStars';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';

import MultiStepSideModal from '@molecules/MultiStepSideModal/MultiStepSideModal';

import ProductReviews from '@organisms/ProductReviews/ProductReviews';

import { ShevronRight } from '@eobuwie-ui/icons/v1/other';
import { Icon } from '@eobuwie-ui/components/Icon/v1';

import {
    CellList,
    CELL_LIST_VARIANTS,
    CELL_LIST_PADDINGS,
} from '@eobuwie-ui/components/CellList/v1';

const {
    mapGetters: mapCmsBlockGetters,
    mapActions: mapCmsBlockActions,
} = createNamespacedHelpers('cmsBlock');

const STEP_REVIEWS_LIST = 1;
const STEP_HOW_REVIEWS_WORK = 2;

export default {
    name: 'ProductReviewsPanel',

    components: {
        StaticBlock,
        ProductReviews,
        MultiStepSideModal,
        RatingStars,
        CellList,
        Icon,
    },

    props: {
        reviewsData: {
            type: Object,
            required: true,
        },

        averageRating: {
            type: Number,
            required: true,
        },

        sku: {
            type: String,
            required: true,
        },

        initStep: {
            type: Number,
            default: 1,
        },
    },

    data() {
        return {
            howOpinionsWorkCmsBlock: {},
            isModalCloseButtonVisible: true,
        };
    },

    computed: {
        ...mapCmsBlockGetters(['staticBlockById']),

        panelTitle() {
            return `${this.$t('Product reviews')} (${this.reviewsTotalCount})`;
        },

        reviewsTotalCount() {
            return this.reviewsData?.totalCountAll || 0;
        },

        headings() {
            return {
                [STEP_REVIEWS_LIST]: this.panelTitle,
                [STEP_HOW_REVIEWS_WORK]: this.$t('How do reviews work?'),
            };
        },

        isHowOpinionsWorkBlockExist() {
            return !!this.howOpinionsWorkCmsBlock?.content;
        },

        isSideModalOpen() {
            return this.$modals.isOpen(MODAL_REVIEWS_PANEL_NAME);
        },
    },

    watch: {
        isSideModalOpen(isOpenValue) {
            if (isOpenValue) {
                this.loadModalStaticBlock();

                return;
            }

            this.$refs.multiStepSideModal.setStep(STEP_REVIEWS_LIST);
        },
    },

    beforeCreate() {
        this.CELL_LIST_VARIANTS = CELL_LIST_VARIANTS;
        this.CELL_LIST_PADDINGS = CELL_LIST_PADDINGS;
        this.STEP_REVIEWS_LIST = STEP_REVIEWS_LIST;
        this.STEP_HOW_REVIEWS_WORK = STEP_HOW_REVIEWS_WORK;
        this.ShevronRight = ShevronRight;
    },

    methods: {
        ...mapCmsBlockActions(['getCmsBlocks']),

        openReviews() {
            this.$modals.open(MODAL_REVIEWS_PANEL_NAME);

            this.$analytics.moduleEmit(
                PRODUCT_MODULE_NAME,
                PRODUCT_READ_ALL_OPINIONS_CLICK,
                {
                    countOpinions: this.reviewsTotalCount,
                }
            );
        },

        close() {
            this.$modals.close(MODAL_REVIEWS_PANEL_NAME);
        },

        onStepChanged(step) {
            this.isModalCloseButtonVisible = step === STEP_REVIEWS_LIST;
        },

        async loadModalStaticBlock() {
            this.howOpinionsWorkCmsBlock =
                this.staticBlockById(PSB_HOW_OPINIONS_WORK) || {};

            if (this.isBlockContentExist) {
                return;
            }

            await this.getCmsBlocks({
                cmsBlocksToLoad: PSB_HOW_OPINIONS_WORK,
            });

            this.howOpinionsWorkCmsBlock =
                this.staticBlockById(PSB_HOW_OPINIONS_WORK) || {};
        },
    },
};
</script>

<style lang="scss" scoped>
.product-reviews-panel {
    @apply relative;

    .open-reviews-button {
        @apply -mx-3 border-b-1 border-gray5;

        &:deep(.container) {
            @apply max-w-full;
        }
    }

    .stars {
        @apply absolute top-1/2 pointer-events-none;

        transform: translateY(-50%);

        right: 36px;
    }

    .block {
        @apply p-4;
    }

    .product-reviews {
        @apply pb-4;
    }

    .multi-step-side-modal {
        &:deep(.mobile-and-tablet-only) {
            .header {
                @apply justify-center;
            }
        }

        &.is-close-hidden {
            &:deep(.close) {
                @apply hidden;
            }
        }
    }
}
</style>
