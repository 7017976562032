<template>
    <Observer
        ref="observer"
        :options="OBSERVER_OPTIONS"
        :observe-once="true"
        :style="style"
        :class="{
            'display-template':
                display.displayFormat === 'ImageTemplateDisplayResponse',
        }"
        class="display"
        @intersect="viewContent($event)"
    >
        <BaseLinkStatic
            v-if="pictureSrc"
            :path="redirectUrl"
            class="display-link"
            @click.exact.native="onClickDisplay(LEFT_CLICK)"
            @click.exact.native.middle="onClickDisplay(MIDDLE_CLICK)"
            @contextmenu.native="onClickDisplay(RIGHT_CLICK)"
            @click.ctrl.exact.native="onClickDisplay(RIGHT_CLICK)"
        >
            <div
                v-if="display.displayFormat === 'ImageTemplateDisplayResponse'"
                class="template"
            >
                <div
                    class="column content"
                    :style="{
                        backgroundColor:
                            display.templateParams.backgroundColor || 'grey',
                    }"
                >
                    <section>
                        <p class="title">
                            <HeaderText
                                tag="strong"
                                size="m"
                                variant="alternative"
                            >
                                {{ display.templateParams.title }}
                            </HeaderText>
                        </p>
                        <BodyText color="alternative">
                            {{ display.templateParams.subtitle }}
                        </BodyText>
                        <ButtonSmall variant="secondary-alternative">
                            {{
                                $te(display.templateParams.cta)
                                    ? $t(display.templateParams.cta)
                                    : $t('Show more')
                            }}
                        </ButtonSmall>
                    </section>
                </div>
                <div class="column image">
                    <BasePicture
                        :width="1"
                        :height="1"
                        :src="pictureSrc"
                        :sources="pictureSources"
                        :sources-with-media="sourcesWithMedia"
                        :alt="alt"
                        @load="isImageLoaded = true"
                    />
                </div>
            </div>
            <BasePicture
                v-else-if="pictureSrc"
                :width="1"
                :height="1"
                :src="pictureSrc"
                :sources="pictureSources"
                :sources-with-media="sourcesWithMedia"
                :alt="alt"
                :class="
                    breakpointMap === DISPLAY_BREAKPOINT_MOBILE
                        ? 'aspect-breakpoint-mobile'
                        : 'aspect-breakpoint-tablet'
                "
                @load="isImageLoaded = true"
            />
        </BaseLinkStatic>
    </Observer>
</template>

<script>
import {
    LEFT_CLICK,
    LEFT_CTRL_CLICK,
    RIGHT_CLICK,
    MIDDLE_CLICK,
    DISPLAY_BREAKPOINT_MAP,
    DISPLAY_BREAKPOINT_MOBILE,
    DISPLAY_BREAKPOINT_TABLET,
} from '@configs/ad-tech';

import { HeaderText } from '@eobuwie/eobuwie-ui/dist/components/HeaderText/v1';
import { ButtonSmall } from '@eobuwie/eobuwie-ui/dist/components/ButtonSmall/v1';
import { BodyText } from '@eobuwie/eobuwie-ui/dist/components/BodyText/v1';

import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import BasePicture from '@atoms/BasePicture/BasePicture';
import Observer from '@atoms/Observer/Observer';

const OBSERVER_OPTIONS = {
    root: null,
    threshold: 0,
    rootMargin: '0px 0px 0px 0px',
};

export default {
    name: 'Display',

    components: {
        BaseLinkStatic,
        BasePicture,
        Observer,
        ButtonSmall,
        BodyText,
        HeaderText,
    },

    props: {
        display: {
            type: Object,
            default: () => ({}),
        },

        alt: {
            type: String,
            default: 'Display',
        },

        breakpointMap: {
            type: String,
            default: DISPLAY_BREAKPOINT_MOBILE,
            validator: value => DISPLAY_BREAKPOINT_MAP.includes(value),
        },

        googleAnalyticsEventData: {
            type: Object,
            default: () => ({
                moduleName: '',
                eventNames: [],
            }),

            validator: obj =>
                Object.prototype.hasOwnProperty.call(obj, 'moduleName') &&
                Object.prototype.hasOwnProperty.call(obj, 'eventNames') &&
                Array.isArray(obj.eventNames) &&
                obj.eventNames.every(event => typeof event === 'object'),
        },
    },

    data() {
        return {
            isImageLoaded: false,
        };
    },

    computed: {
        redirectUrl() {
            return this.display.redirectUrls?.web;
        },

        pictureSrc() {
            return this.display.images?.mobile?.url;
        },

        pictureSources() {
            return {
                jpeg: [
                    {
                        src: this.display.images?.mobile?.url,
                        width: 768,
                    },
                    {
                        src: this.display.images?.desktop?.url,
                        width: 1024,
                    },
                ],
            };
        },

        sourcesWithMedia() {
            const sources = this.pictureSources;
            const result = [];

            Object.keys(sources).forEach(type => {
                sources[type].forEach(source => {
                    result.push({
                        type,
                        srcset: source.src,
                        media: this.getMediaForSource(source),
                    });
                });
            });

            return result;
        },

        style() {
            const { mobile, desktop } = this.display.images;

            const aspectMobile =
                mobile && mobile.width && mobile.height && !this.isImageLoaded
                    ? `${mobile.width} / ${mobile.height}`
                    : 'auto';

            const aspectDesktop =
                desktop &&
                desktop.width &&
                desktop.height &&
                !this.isImageLoaded
                    ? `${desktop.width} / ${desktop.height}`
                    : 'auto';

            return {
                '--aspect-mobile': aspectMobile,
                '--aspect-desktop': aspectDesktop,
            };
        },
    },

    watch: {
        $route: {
            immediate: true,
            async handler() {
                this.display.analyticsUrls.load.forEach(beacon => {
                    this.$services.adTech.notifyBeacon({ beacon });
                });
                this.$refs?.observer?.resetObserver?.();
            },
        },
    },

    beforeCreate() {
        this.OBSERVER_OPTIONS = OBSERVER_OPTIONS;
        this.LEFT_CLICK = LEFT_CLICK;
        this.LEFT_CTRL_CLICK = LEFT_CTRL_CLICK;
        this.RIGHT_CLICK = RIGHT_CLICK;
        this.MIDDLE_CLICK = MIDDLE_CLICK;
        this.DISPLAY_BREAKPOINT_MOBILE = DISPLAY_BREAKPOINT_MOBILE;
        this.DISPLAY_BREAKPOINT_TABLET = DISPLAY_BREAKPOINT_TABLET;
    },

    methods: {
        onClickDisplay(clickLabel) {
            this.display.analyticsUrls.click.forEach(beacon => {
                this.$services.adTech.notifyBeacon({ beacon, clickLabel });
            });

            const clickEvent = this.googleAnalyticsEventData.eventNames.find(
                event => event.click
            );

            if (clickEvent) {
                this.emitGAEvent(clickEvent.click);
            }

            this.$emit('click-banner');
        },

        async viewContent(isIntersecting) {
            if (!isIntersecting) {
                return;
            }

            const {
                analyticsUrls: { view: viewUrls = [] } = {},
            } = this.display;
            const { eventNames = [] } = this.googleAnalyticsEventData;

            viewUrls.forEach(beacon =>
                this.$services.adTech.notifyBeacon({ beacon })
            );

            const viewEvent = eventNames.find(event => event.view);

            if (viewEvent && typeof this.emitGAEvent === 'function') {
                await this.emitGAEvent(viewEvent.view);
            }

            this.$emit('display-banner');
        },

        getMediaForSource(source) {
            const breakpointMap = {
                [this.DISPLAY_BREAKPOINT_MOBILE]: {
                    768: '(max-width: 767px)',
                    1024: '(min-width: 768px)',
                },

                [this.DISPLAY_BREAKPOINT_TABLET]: {
                    768: '(max-width: 1023px)',
                    1024: '(min-width: 1024px)',
                },
            };

            return breakpointMap[this.breakpointMap][source.width] || '';
        },

        async emitGAEvent(eventName) {
            const { moduleName } = this.googleAnalyticsEventData;

            await this.$analytics.moduleEmit(moduleName, eventName);
        },
    },
};
</script>

<style lang="scss" scoped>
.display {
    &.display-template {
        @apply w-full max-w-[1100px] mx-auto;
    }

    .display-information {
        @apply mb-ui-1;
    }

    .display-link {
        text-decoration-line: none !important;
    }

    .template {
        @apply w-full max-w-full gap-0 p-0 rounded-0 overflow-hidden flex flex-col-reverse;

        .column {
            @apply h-auto flex-grow;

            &.content {
                @apply flex flex-col justify-center items-start p-4 h-[184px];

                .title {
                    @apply mb-2;
                }

                .body-text {
                    @apply mb-4;
                }
            }

            &.image {
                .base-picture {
                    @apply overflow-hidden h-full;

                    &:deep(.image) {
                        @apply object-cover max-h-[432px];
                    }
                }
            }
        }

        @screen md {
            @apply flex-row;

            .column {
                @apply basis-1/2 h-[200px];

                &.content {
                    @apply h-auto;
                }

                &.image {
                    :deep(.image) {
                        @apply max-h-none;
                    }
                }
            }
        }
    }

    .aspect-breakpoint-mobile {
        aspect-ratio: var(--aspect-mobile);

        @media (min-width: 768px) {
            aspect-ratio: var(--aspect-desktop);
        }
    }

    .aspect-breakpoint-tablet {
        aspect-ratio: var(--aspect-mobile);

        @media (min-width: 1024px) {
            aspect-ratio: var(--aspect-desktop);
        }
    }
}
</style>
