<template>
    <div class="add-to-cart">
        <ButtonLarge
            :variant="buttonVariant"
            :disabled="isAddToCartPending"
            class="add-to-cart-button"
            @click="addToCart(true)"
        >
            {{ buttonText }}
        </ButtonLarge>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import {
    ESIZEME_LABEL_NONE,
    ESIZEME_LABEL_ACCEPTABLE,
    ESIZEME_LABEL_RECOMMENDED,
} from '@configs/esizeme';

import { IS_DISCOUNT_ENABLED } from '@localeConfig/keys';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { transformCartItem } from '@assets/cart';
import { getChosenSizeLabel } from '@assets/product';

import {
    ButtonLarge,
    BUTTON_LARGE_VARIANTS,
} from '@eobuwie-ui/components/ButtonLarge/v1';

const {
    mapGetters: mapCartGetters,
    mapActions: mapCartActions,
    mapState: mapCartState,
} = createNamespacedHelpers('cart');

export default {
    name: 'AddToCart',

    components: {
        ButtonLarge,
    },

    props: {
        product: {
            type: Object,
            required: true,
        },

        chosenSize: {
            type: String,
            required: true,
        },

        esizemeInfo: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        ...mapCartGetters(['cartItems', 'complexProductDataItemsSkusMap']),
        ...mapCartState(['complexProductDataItems', 'isAddToCartPending']),

        cartItemsWithPaths() {
            return this.cartItems.map(item =>
                transformCartItem({
                    item,
                    isDiscountEnabled: this.$getLocaleConfigByKey(
                        IS_DISCOUNT_ENABLED
                    ),
                })
            );
        },

        buttonText() {
            return this.$t(
                this.product.isInStock ? 'Add to cart' : 'Notify me'
            );
        },

        buttonVariant() {
            return this.product.isInStock
                ? BUTTON_LARGE_VARIANTS.PRIMARY_ACCENT
                : BUTTON_LARGE_VARIANTS.PRIMARY;
        },

        chosenSizeLabel() {
            return getChosenSizeLabel(this.product, this.chosenSize);
        },
    },

    methods: {
        ...mapCartActions([
            'addProductToCart',
            'setComplexDataProductItems',
            'addToCartPending',
        ]),

        addToCart(isFromButtonClick = false) {
            if (!this.product.isInStock) {
                this.$emit('open-availability-modal');

                return;
            }

            if (isFromButtonClick) {
                this.$emit('add-to-cart-click');
            }

            if (!this.isEmpty(this.chosenSize)) {
                this.addToCartAsync();
            } else {
                this.$emit('no-size-chosen');
            }
        },

        isEmpty(val) {
            return !!(val === undefined || val == null || val.length <= 0);
        },

        async addToCartAsync() {
            const {
                product,
                chosenSize,
                chosenSizeLabel,
                esizemeInfo: {
                    scanId = '',
                    recommended = '',
                    acceptable = '',
                    isEsizemeEnabled = false,
                },
            } = this;

            if (!this.complexProductDataItemsSkusMap[product.id]) {
                this.setComplexDataProductItems([
                    ...this.complexProductDataItems,
                    product,
                ]);
            }

            try {
                const { name: productName, variants, sku } = product;

                const addToCartData = {
                    parent_sku: sku,
                    data: {
                        sku: variants[chosenSize].id,
                        quantity: 1,
                    },
                };

                if (scanId && isEsizemeEnabled) {
                    addToCartData.esizeme_info = {
                        scan_id: scanId,
                        recommended_size: recommended || '',
                    };
                }

                this.addToCartPending(true);

                let esizeme = ESIZEME_LABEL_NONE;

                if (recommended === chosenSizeLabel) {
                    esizeme = ESIZEME_LABEL_RECOMMENDED;
                } else if (acceptable === chosenSizeLabel) {
                    esizeme = ESIZEME_LABEL_ACCEPTABLE;
                }

                const addedSku = await this.addProductToCart({
                    productName,
                    addToCartData,
                    chosenSizeLabel,
                    esizeme,
                    pageType: this.$route.name,
                    actionFieldList: this.$route.path,
                });

                if (addedSku) {
                    const addedCartItem = this.cartItemsWithPaths.find(
                        ({ skuVariant }) => skuVariant === addedSku
                    );

                    if (addedCartItem) {
                        addedCartItem.quantity = 0;

                        this.$emit(
                            'open-modal-added-to-cart-confirmation',
                            addedCartItem
                        );
                    }
                }

                this.addToCartPending(false);
            } catch (err) {
                this.addToCartPending(false);

                this.$errorHandler.captureError(
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]: 'addToCart',
                    },
                    {
                        product,
                        chosenSize,
                        chosenSizeLabel,
                    }
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.add-to-cart {
    .add-to-cart-button {
        @apply w-full;
    }
}
</style>
