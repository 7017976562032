import { getChosenSizeLabelFromVariant } from '@assets/product';

const SHORT_SIZE_MAX_LENGTH = 3;

const SHORT_SIZE_SLOT_LENGTH = 1;
const LONG_SIZE_SLOT_LENGTH = 2;

export const SIZES_LINE_MAX_LENGTH = 5;

export const createSizeElement = sizeString => ({
    text: sizeString,
    isLong: sizeString.length > SHORT_SIZE_MAX_LENGTH,
    isFirstInLine: false,
});

export const createRemainedElement = remainedCount => ({
    text: `+${remainedCount}`,
    isLong: false,
    isFirstInLine: false,
});

export const transformSizesArray = array => {
    let length = 0;
    const sizesArray = [...array];
    const sizes = [];

    while (length < SIZES_LINE_MAX_LENGTH * 2) {
        if (sizesArray.length === 0) {
            return sizes;
        }

        const size = createSizeElement(sizesArray.shift());
        const remained = sizesArray.length;

        if (length === SIZES_LINE_MAX_LENGTH - 1 && size.isLong) {
            sizes.push({
                ...size,
                isFirstInLine: true,
            });
            length += SHORT_SIZE_SLOT_LENGTH;
        } else if (length < SIZES_LINE_MAX_LENGTH * 2 - 2) {
            const isFirstInLine = length % SIZES_LINE_MAX_LENGTH === 0;

            sizes.push({
                ...size,
                isFirstInLine,
            });
        } else if (length === SIZES_LINE_MAX_LENGTH * 2 - 2) {
            if (remained === 0 || !size.isLong) {
                sizes.push(size);
            } else {
                sizes.push(createRemainedElement(remained + 1));
            }
        } else if (remained === 0 && !size.isLong) {
            sizes.push(size);
        } else {
            sizes.push(createRemainedElement(remained + 1));
        }

        length += size.isLong ? LONG_SIZE_SLOT_LENGTH : SHORT_SIZE_SLOT_LENGTH;
    }

    return sizes;
};

export const unavailableProductSizes = product => {
    const {
        family: { variant_dimension: variantDimension } = {},
        variants = [],
    } = product;

    return Object.values(variants)
        .filter(({ stock_quantity }) => stock_quantity === 0)
        .map(variant => {
            const { id, size } = variant;
            const sizeLabel =
                getChosenSizeLabelFromVariant(variant, variantDimension) ||
                size;

            return {
                sku: id,
                size,
                sizeLabel,
            };
        });
};

export const normalizeProductSizeCode = size =>
    size.replace(/[^a-zA-Z0-9]/g, '_');
