export const sortByActiveVariant = (colorVariants = [], sku = '') => {
    return [...colorVariants].sort(
        ({ sku: skuVariant1 }, { sku: skuVariant2 }) => {
            const isActiveVariant1 = skuVariant1 === sku;
            const isActiveVariant2 = skuVariant2 === sku;

            if (isActiveVariant1 === isActiveVariant2) {
                return 0;
            }

            return isActiveVariant1 ? -1 : 1;
        }
    );
};
