var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-details-header-price",attrs:{"data-test-id":_vm.PRODUCT_PRICE}},[_c('div',{staticClass:"final-price",class:{
            promotional:
                _vm.discountPercent &&
                (!_vm.isOmnibusStrictEnabled || _vm.priceOmnibus),
        },attrs:{"data-test-id":_vm.FINAL_PRICE}},[_vm._v("\n        "+_vm._s(_vm.formatLocalePrice(_vm.pricePromotional))+"\n    ")]),_vm._v(" "),(
            !_vm.isOmnibusStrictEnabled &&
            _vm.priceRegular !== _vm.pricePromotional &&
            _vm.isRegularPriceEnabled
        )?_c('div',{staticClass:"price-with-discount",attrs:{"data-test-id":_vm.REGULAR_PRICE}},[_vm._v("\n        "+_vm._s(_vm.formatLocalePrice(_vm.priceRegular))+"\n    ")]):_vm._e(),_vm._v(" "),_vm._t("after-price")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }