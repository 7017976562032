<template>
    <BaseTooltip
        v-if="!isMobile && item.details"
        :position="POSITION_TOP"
        :is-mobile="isMobile"
        padding="10px"
        class="product-specification-item-with-tooltip"
        tooltip-class="product-specification-tooltip-wrapper"
        tooltip-container-class="product-specification-tooltip-container"
    >
        <BaseLinkStatic
            v-if="item.url"
            :path="item.url"
            :label="item.value"
            class="base-link"
            target="_blank"
            @click.native="
                $emit('click-link', {
                    code: item.code,
                    value: item.value,
                })
            "
        />
        <span v-else>{{ item.value }}</span>

        <template #tooltipContent>
            <div v-html="item.details" />
            <BaseLinkStatic
                v-if="urlLabel"
                :path="item.url"
                class="tooltip-link"
                target="_blank"
            >
                {{ urlLabel }}
            </BaseLinkStatic>
        </template>
    </BaseTooltip>
    <div
        v-else-if="!isMobile && !item.details"
        class="product-specification-item-with-empty-description"
    >
        <BaseLinkStatic
            v-if="item.url"
            :path="item.url"
            :label="item.value"
            class="base-link"
            target="_blank"
            @click.native="
                $emit('click-link', {
                    code: item.code,
                    value: item.value,
                })
            "
        />
        <span v-else>{{ item.value }}</span>
    </div>
    <TextButton
        v-else
        class="product-specification-mobile-button"
        @click.native="$emit('open-next-step', item)"
    >
        {{ item.value }}
    </TextButton>
</template>

<script>
import { FOOTWEAR_WIDTH_ATTRIBUTE_NAME } from '@configs/product-attribute-names';

import { POSITION_TOP } from '@types/Tooltip';

import TextButton from '@atoms/TextButton/TextButton';
import BaseTooltip from '@atoms/BaseTooltip/BaseTooltip';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';

export default {
    name: 'ProductSpecificationItemWithTooltip',

    components: {
        BaseTooltip,
        TextButton,
        BaseLinkStatic,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },

        isMobile: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        urlLabel() {
            return this.item.code === FOOTWEAR_WIDTH_ATTRIBUTE_NAME
                ? this.$t('Find out more')
                : '';
        },
    },

    beforeCreate() {
        this.POSITION_TOP = POSITION_TOP;
    },
};
</script>

<style lang="scss" scoped>
.product-specification-item-with-tooltip {
    &:hover {
        .base-link {
            @apply underline;
        }
    }

    &:deep() {
        .product-specification-tooltip-wrapper {
            @apply inline-block;
        }

        .product-specification-tooltip-container {
            @apply font-book;

            .tooltip-content {
                width: theme(
                    'customVariables.productSpecification.tooltipWidth'
                );
            }
        }
    }

    .base-link {
        @apply font-bold;
    }

    .tooltip-link {
        @apply inline-block mt-3 font-bold text-s leading-s;
    }
}

.product-specification-item-with-empty-description {
    .base-link {
        @apply font-bold;
    }
}

.product-specification-mobile-button {
    @apply mr-1;
}
</style>
