export const EOBUWIE_PHOTO = 'eobuwie_photo';
export const MODEL_PHOTO = 'model_photo';
export const PACKSHOT_PHOTO = 'packshot_photo';
export const STYLIZATION_PHOTO = 'stylization_photo';
export const VIDEO = 'video';

export const MEDIA_FAMILY_MAP = {
    [EOBUWIE_PHOTO]: 'p',
    [MODEL_PHOTO]: 'm',
    [PACKSHOT_PHOTO]: 'p',
    [STYLIZATION_PHOTO]: 's',
    [VIDEO]: 'v',
};
