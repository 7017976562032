<template>
    <section class="product-specification">
        <BaseHeading tag="h2" class="heading">
            <CellList
                :variant="CELL_LIST_VARIANTS.BASIC"
                :horizontal-padding="CELL_LIST_PADDINGS.PX_0"
                :label="$t('Information about product')"
                class="open-button"
                @click.native="open()"
            >
                <template #button>
                    <Icon :icon="ShevronRight" />
                </template>
            </CellList>

            <span class="desktop-heading">
                {{ $t('Information about product') }}
            </span>
        </BaseHeading>

        <MultiStepSideModal
            ref="multiStepSideModal"
            class="multi-step-side-modal"
            :is-open="isSideModalOpen"
            :headings="headings"
            :init-step="initStep"
            :apply-only-on-mobile-and-tablet="true"
            @step-changed="clearProductDetails($event)"
            @close="close()"
        >
            <template #default="{ currentStep, nextStep }">
                <template v-if="currentStep === STEP_PRODUCT_SPECIFICATION">
                    <div
                        v-if="description"
                        class="description"
                        v-html="description"
                    />
                    <div
                        ref="content"
                        class="content"
                        :class="{ 'is-hidden': isCollapse }"
                    >
                        <div class="columns-wrapper">
                            <dl
                                v-for="{
                                    title,
                                    items,
                                } in visibleProductValuesWithColumns"
                                :key="title"
                                class="column"
                            >
                                <BaseHeading tag="h3" class="column-title">
                                    {{ title }}
                                </BaseHeading>
                                <ProductSpecificationItem
                                    v-for="(
                                        { value, attribute }, index
                                    ) in items"
                                    :key="`item-${index}`"
                                    :align-top="!!value && !!value.component"
                                >
                                    <template #label>
                                        {{
                                            getSpecificationItemLabel(
                                                attribute.label
                                            )
                                        }}
                                    </template>

                                    <template #value>
                                        <template v-if="attribute.filterable">
                                            <template
                                                v-for="(
                                                    filter, filterIndex
                                                ) in value"
                                            >
                                                <BaseLink
                                                    :key="`filter-${index}-${filterIndex}`"
                                                    :path="
                                                        getAttributePath(
                                                            filter.attribute
                                                                .url,
                                                            filter.value.url
                                                        )
                                                    "
                                                    :label="filter.value.label"
                                                    class="link"
                                                    @click.native="
                                                        onLinkClick({
                                                            code:
                                                                attribute.code,
                                                            value:
                                                                filter.value
                                                                    .label,
                                                        })
                                                    "
                                                />
                                                <template
                                                    v-if="
                                                        filterIndex + 1 <
                                                        value.length
                                                    "
                                                >
                                                    ,
                                                </template>
                                            </template>
                                        </template>

                                        <template
                                            v-else-if="value && value.component"
                                        >
                                            <template
                                                v-if="
                                                    Array.isArray(value.value)
                                                "
                                            >
                                                <component
                                                    :is="value.component"
                                                    v-for="item in value.value"
                                                    :key="item.name"
                                                    :item="item"
                                                    :is-mobile="isMobile"
                                                    class="column-item"
                                                    @click-link="
                                                        onLinkClick($event)
                                                    "
                                                    @open-next-step="
                                                        nextSpecificationStep(
                                                            item,
                                                            nextStep
                                                        )
                                                    "
                                                />
                                            </template>
                                            <component
                                                :is="value.component"
                                                v-else
                                                :item="value"
                                                :is-mobile="isMobile"
                                                class="column-item"
                                                @open-next-step="
                                                    nextSpecificationStep(
                                                        value,
                                                        nextStep
                                                    )
                                                "
                                            />
                                        </template>

                                        <template v-else>
                                            {{ value }}
                                        </template>
                                        <template
                                            v-if="
                                                hasEcoFriendlyAttribute(
                                                    attribute.code
                                                )
                                            "
                                        >
                                            <QuestionMarkTooltip
                                                :is-mobile="isMobile"
                                                :tooltip-content="
                                                    $t(
                                                        // eslint-disable-next-line max-len
                                                        'A product whose negative impact on the environment is maximally reduced or eliminated.'
                                                    )
                                                "
                                                :tooltip-position="
                                                    POSITION_BOTTOM
                                                "
                                                class="eco-friendly-tooltip"
                                            />
                                        </template>
                                    </template>
                                </ProductSpecificationItem>
                            </dl>
                        </div>

                        <ProductGuaranteeOfOriginality />
                    </div>
                </template>
                <ProductSpecificationItemMobile
                    v-if="currentStep === STEP_PRODUCT_SPECIFICATION_DETAILS"
                    :product-attributes-list="productAttributesList"
                    :product-details-content="productDetailsContent"
                    @update-active-product-item="
                        updateActiveProductItem($event)
                    "
                    @click-link="onLinkClick($event)"
                />
            </template>
            <template #footer="{ currentStep }">
                <template
                    v-if="currentStep === STEP_PRODUCT_SPECIFICATION_DETAILS"
                >
                    <div class="go-back-to-product-button-wrapper">
                        <ButtonSmall
                            :variant="BUTTON_VARIANT"
                            class="go-back-to-product-button"
                            @click="close()"
                        >
                            {{ $t('Back to the product') }}
                        </ButtonSmall>
                    </div>
                </template>
            </template>
        </MultiStepSideModal>

        <ButtonSmall
            :variant="BUTTON_VARIANT"
            class="show-more-button"
            @click="showMoreToggle($event)"
        >
            {{ showMoreText }}
        </ButtonSmall>
    </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import debounce from 'lodash.debounce';

import {
    ATTRIBUTE_TYPE_MULTI_SELECT,
    ATTRIBUTE_TYPE_SIMPLE_SELECT,
} from '@configs/product-attribute-types';
import {
    BRAND_WITH_COLLECTION_ATTRIBUTE_NAME,
    CARE_TIPS_ENTITY_NAME,
    COLLECTION_ATTRIBUTE_NAME,
    ECO_FRIENDLY_ATTRIBUTE_NAME,
    FOOTWEAR_WIDTH_ATTRIBUTE_NAME,
    TECHNOLOGY_ATTRIBUTE_NAME,
} from '@configs/product-attribute-names';

import {
    STEP_PRODUCT_SPECIFICATION,
    STEP_PRODUCT_SPECIFICATION_DETAILS,
} from '@configs/product-specification-side-modal';
import { PRODUCT_SPECIFICATION_ITEM_DETAILS } from '@configs/product-specification-item-details';

import { SHOW_SPACE_BEFORE_PUNCTUATION } from '@localeConfig/keys';

import { POSITION_BOTTOM } from '@types/Tooltip';

import { ON_ELEMENT_ACTION } from '@analytics-module/modules/product/types/Events';
import { MODULE_NAME as PRODUCT_MODULE_NAME } from '@analytics-module/modules/product/meta';
import { CATEGORIES as PRODUCT_CATEGORIES } from '@analytics-module/modules/product/types/Categories';
import { ACTIONS as PRODUCT_ACTIONS } from '@analytics-module/modules/product/types/Actions';
import { ACTIONS as GLOBAL_ACTIONS } from '@analytics-module/types/Actions';
import { LABELS as GLOBAL_LABELS } from '@analytics-types/Labels';

import { stringifyCategoryUrl } from '@assets/path';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseTag from '@atoms/BaseTag/BaseTag';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import WithScroll from '@atoms/WithScroll/WithScroll';

import ProductSpecificationItem from '@molecules/ProductSpecification/ProductSpecificationItem';
import ProductSpecificationIconsWithTooltips from '@molecules/ProductSpecification/ProductSpecificationIconsWithTooltips';
import ProductSpecificationItemWithTooltip from '@molecules/ProductSpecification/ProductSpecificationItemWithTooltip';
import ProductSpecificationItemMobile from '@molecules/ProductSpecification/ProductSpecificationItemMobile';
import ProductGuaranteeOfOriginality from '@molecules/ProductGuaranteeOfOriginality/ProductGuaranteeOfOriginality';
import MultiStepSideModal from '@molecules/MultiStepSideModal/MultiStepSideModal';
import QuestionMarkTooltip from '@molecules/QuestionMarkTooltip/QuestionMarkTooltip';

import { ShevronRight } from '@eobuwie-ui/icons/v1/other';

import {
    ButtonSmall,
    BUTTON_SMALL_VARIANTS,
} from '@eobuwie-ui/components/ButtonSmall/v1';
import {
    CellList,
    CELL_LIST_VARIANTS,
    CELL_LIST_PADDINGS,
} from '@eobuwie-ui/components/CellList/v1';
import { Icon } from '@eobuwie-ui/components/Icon/v1';

const { mapGetters: mapGettersConfig } = createNamespacedHelpers('config');

const SPECIFICATION = 'specification';

export default {
    name: 'ProductSpecification',

    components: {
        BaseHeading,
        BaseLink,
        ButtonSmall,
        BaseTag,
        ProductSpecificationItem,
        ProductSpecificationIconsWithTooltips,
        ProductSpecificationItemMobile,
        ProductGuaranteeOfOriginality,
        MultiStepSideModal,
        QuestionMarkTooltip,
        BaseLinkStatic,
        WithScroll,
        CellList,
        Icon,
        ProductSpecificationItemWithTooltip,
    },

    props: {
        productValues: {
            type: Object,
            required: true,
        },

        productMainCategory: {
            type: String,
            required: true,
        },

        isMobile: {
            type: Boolean,
            required: true,
        },

        initStep: {
            type: Number,
            default: STEP_PRODUCT_SPECIFICATION,
        },
    },

    data() {
        return {
            isCollapse: true,
            isSideModalOpen: false,
            onResizeHandler: null,
            productDetailsContent: {},
            productAttributesList: {},
            headings: {
                [this.STEP_PRODUCT_SPECIFICATION]: this.$t(
                    'Information about product'
                ),

                [this.STEP_PRODUCT_SPECIFICATION_DETAILS]: '',
            },
        };
    },

    computed: {
        ...mapGettersConfig(['locale']),

        description() {
            const { description = {} } = this.productValues || {};

            return description.value?.[this.locale]?.trim() || '';
        },

        visibleProductValuesWithColumns() {
            return Object.values(
                Object.values(this.productValues)
                    .filter(({ attribute }) => {
                        const {
                            visible,
                            group: { code, label },
                        } = attribute || {
                            visible: false,
                            group: { code: null, label: null },
                        };

                        return (
                            visible === true && code !== null && label !== null
                        );
                    })
                    .map(item => {
                        const { attribute } = item;

                        if (attribute) {
                            const value = this.getValue(item);

                            if (!value) {
                                return null;
                            }

                            return {
                                ...item,
                                value,
                            };
                        }

                        return item;
                    })
                    .filter(item => {
                        if (!item) {
                            return false;
                        }

                        const { value } = item.value || {};
                        const isArray = Array.isArray(value);

                        return !isArray || (isArray && value.length);
                    })
                    .sort((value1, value2) => {
                        const displayOrder1 =
                            parseInt(value1.attribute.display_order, 10) ||
                            99999;
                        const displayOrder2 =
                            parseInt(value2.attribute.display_order, 10) ||
                            99999;

                        return displayOrder1 - displayOrder2;
                    })
                    .reduce((groups, value) => {
                        const { code, label: title } = value.attribute.group;

                        if (groups[code]) {
                            groups[code].items.push(value);
                        } else {
                            groups[code] = {
                                title,
                                items: [value],
                            };
                        }

                        return groups;
                    }, {})
            );
        },

        showMoreText() {
            return this.$t(this.isCollapse ? 'Show more' : 'Show less');
        },
    },

    watch: {
        isMobile() {
            this.setContentMaxHeight();
        },

        isSideModalOpen(isOpenValue) {
            if (!isOpenValue) {
                this.setStep(this.STEP_PRODUCT_SPECIFICATION);
            }
        },
    },

    beforeCreate() {
        this.COLLAPSED_MAX_HEIGHT = '145px';
        this.POSITION_BOTTOM = POSITION_BOTTOM;
        this.STEP_PRODUCT_SPECIFICATION = STEP_PRODUCT_SPECIFICATION;
        this.STEP_PRODUCT_SPECIFICATION_DETAILS = STEP_PRODUCT_SPECIFICATION_DETAILS;
        this.BUTTON_VARIANT = BUTTON_SMALL_VARIANTS.SECONDARY;
        this.CELL_LIST_VARIANTS = CELL_LIST_VARIANTS;
        this.CELL_LIST_PADDINGS = CELL_LIST_PADDINGS;
        this.ShevronRight = ShevronRight;
    },

    beforeMount() {
        this.onResizeHandler = debounce(this.setContentMaxHeight, 100);
    },

    mounted() {
        this.setContentMaxHeight();
        window.addEventListener('resize', this.onResizeHandler);
    },

    beforeDestroy() {
        this.onResizeHandler.cancel();
        window.removeEventListener('resize', this.onResizeHandler);
    },

    methods: {
        hasEcoFriendlyAttribute(code) {
            return code === ECO_FRIENDLY_ATTRIBUTE_NAME;
        },

        nextSpecificationStep(item, nextStep) {
            this.headings[this.STEP_PRODUCT_SPECIFICATION_DETAILS] = item.label;
            this.productDetailsContent = item;
            nextStep();

            if (item.code === TECHNOLOGY_ATTRIBUTE_NAME) {
                this.$analytics.moduleEmit(
                    PRODUCT_MODULE_NAME,
                    ON_ELEMENT_ACTION,
                    {
                        eventCategory:
                            PRODUCT_CATEGORIES.PRODUCT_DETAILS_DESCRIPTION,
                        eventAction: PRODUCT_ACTIONS.CLICK_TECHNOLOGY_MODAL,
                        eventLabel: item.value,
                    }
                );
            }
        },

        updateActiveProductItem(item) {
            this.productDetailsContent = item;
        },

        getAttributePath(attributeUrl, valueUrl) {
            const path = stringifyCategoryUrl({
                categoryUrl: this.productMainCategory,
                filters: {
                    [attributeUrl]: [valueUrl],
                },
            });

            return this.$createCategoryPath(path);
        },

        getValue(item) {
            const { locale } = this;
            const {
                value,
                formatted,
                attribute: { code, type, filterable, localizable, label, url },
            } = item;

            if (code === CARE_TIPS_ENTITY_NAME) {
                return {
                    component: 'ProductSpecificationIconsWithTooltips',
                    value: formatted || value,
                };
            }

            if (code === TECHNOLOGY_ATTRIBUTE_NAME) {
                const technologies = Object.values(value)
                    .map(({ values, url: valueUrl }) => {
                        const {
                            label: { [locale]: name = '' } = {},
                            opis: { [locale]: details = '' } = {},
                        } = values || {};

                        return {
                            value: name,
                            details,
                            label,
                            code,
                            url: this.getAttributePath(url, valueUrl),
                        };
                    })
                    .filter(({ value: name }) => name);

                if (technologies.length) {
                    this.productAttributesList[
                        TECHNOLOGY_ATTRIBUTE_NAME
                    ] = technologies;
                }

                return {
                    component: 'ProductSpecificationItemWithTooltip',
                    value: technologies,
                };
            }

            if (filterable) {
                if (type === ATTRIBUTE_TYPE_MULTI_SELECT) {
                    return this.getFilters(item);
                }

                if (type === ATTRIBUTE_TYPE_SIMPLE_SELECT) {
                    return [item];
                }

                return formatted || value;
            }

            if (type === ATTRIBUTE_TYPE_MULTI_SELECT) {
                return Object.values(value)
                    .map(i => i.label)
                    .join(', ');
            }

            if (type === ATTRIBUTE_TYPE_SIMPLE_SELECT) {
                const itemDetails = PRODUCT_SPECIFICATION_ITEM_DETAILS[code];

                if (!itemDetails) {
                    return value.label;
                }

                const details = this.$t(itemDetails, {
                    value: value.label,
                });

                return {
                    component: 'ProductSpecificationItemWithTooltip',
                    value: [
                        {
                            value: value.label,
                            url:
                                code === FOOTWEAR_WIDTH_ATTRIBUTE_NAME
                                    ? this.$tPathKey('blog-shoe-width')
                                    : '',

                            details,
                            label,
                            code,
                        },
                    ],
                };
            }

            if (localizable) {
                return value[locale];
            }

            return formatted || value;
        },

        getFilters(item) {
            const { attribute, value } = item;

            return Object.values(value).map(itemValue => {
                return {
                    attribute,
                    value: itemValue,
                };
            });
        },

        open() {
            this.isSideModalOpen = true;

            this.$analytics.moduleEmit(PRODUCT_MODULE_NAME, ON_ELEMENT_ACTION, {
                eventCategory: PRODUCT_CATEGORIES.PRODUCT_DETAILS_DESCRIPTION,
                eventAction: GLOBAL_ACTIONS.OPEN,
                eventLabel: SPECIFICATION,
            });
        },

        close() {
            this.isSideModalOpen = false;
            this.clearProductDetails(this.initStep);

            this.$analytics.moduleEmit(PRODUCT_MODULE_NAME, ON_ELEMENT_ACTION, {
                eventCategory: PRODUCT_CATEGORIES.PRODUCT_DETAILS_DESCRIPTION,
                eventAction: GLOBAL_ACTIONS.CLOSE,
                eventLabel: SPECIFICATION,
            });
        },

        clearProductDetails(step) {
            if (step === this.initStep) {
                this.productDetailsContent = {};
            }
        },

        showMoreToggle(element) {
            element.target.blur();
            this.isCollapse = !this.isCollapse;
            this.setContentMaxHeight();

            this.$analytics.moduleEmit(PRODUCT_MODULE_NAME, ON_ELEMENT_ACTION, {
                eventCategory: PRODUCT_CATEGORIES.PRODUCT_DETAILS_DESCRIPTION,
                eventAction: GLOBAL_ACTIONS.CLICK,
                eventLabel: this.isCollapse
                    ? GLOBAL_LABELS.SHOW_LESS
                    : GLOBAL_LABELS.SHOW_MORE,
            });
        },

        setContentMaxHeight() {
            const { content } = this.$refs;

            if (!content) {
                return;
            }

            content.style.maxHeight =
                this.isCollapse && !this.isMobile
                    ? this.COLLAPSED_MAX_HEIGHT
                    : `${content.scrollHeight}px`;
        },

        setStep(step) {
            this.$refs.multiStepSideModal.setStep(step);
        },

        onLinkClick(filter) {
            const { code, value } = filter;

            const ACTIONS_MAP = {
                [TECHNOLOGY_ATTRIBUTE_NAME]: PRODUCT_ACTIONS.CLICK_TECHNOLOGY,

                [BRAND_WITH_COLLECTION_ATTRIBUTE_NAME]:
                    PRODUCT_ACTIONS.CLICK_BRAND,

                [COLLECTION_ATTRIBUTE_NAME]: PRODUCT_ACTIONS.CLICK_COLLECTION,
            };

            const eventAction = ACTIONS_MAP[code];

            if (!eventAction) {
                return;
            }

            this.$analytics.moduleEmit(PRODUCT_MODULE_NAME, ON_ELEMENT_ACTION, {
                eventCategory: PRODUCT_CATEGORIES.PRODUCT_DETAILS_DESCRIPTION,
                eventAction,
                eventLabel: value,
            });
        },

        getSpecificationItemLabel(label) {
            const hasSpace = this.$getLocaleConfigByKey(
                SHOW_SPACE_BEFORE_PUNCTUATION
            );

            return hasSpace ? `${label} :` : `${label}:`;
        },
    },
};
</script>

<style lang="scss" scoped>
@keyframes delay-overflow {
    from {
        overflow: hidden;
    }
}

.product-specification {
    .open-button {
        @apply -mx-3;

        &:deep() {
            .wrapper {
                @apply pr-0;
            }

            .container {
                @apply max-w-full;
            }
        }
    }

    .desktop-heading {
        @apply hidden;
    }

    .multi-step-side-modal {
        &:deep() {
            .header {
                @apply flex items-center w-full justify-between
                border-border border-b-1 text-center;

                .heading {
                    @apply w-full;
                }

                .label {
                    @apply mx-auto;

                    .title {
                        @apply text-text;
                    }
                }

                .close {
                    @apply absolute right-0 flex w-7 h-7;

                    &:focus {
                        @apply outline-none;
                    }

                    .icon {
                        @apply m-auto;
                    }
                }
            }

            .body-text {
                @apply grow;
            }
        }
    }

    .description {
        @apply px-3 pt-4 text-gray1;

        &:deep(a) {
            @apply underline;
        }

        &:deep(ul) {
            @apply list-disc my-3 pl-5;
        }

        &:deep(ol) {
            @apply list-decimal my-3 pl-5;
        }
    }

    .columns-wrapper {
        @apply pb-5 mb-4 px-3 border-solid border-b-1;
    }

    .column {
        @apply border-separate m-0;
    }

    .column-item {
        @apply inline-flex font-bold cursor-default;

        &:not(:last-of-type) {
            @apply mr-1;

            &::after {
                content: ',';
            }
        }
    }

    .column-title {
        @apply text-m leading-m pt-4 pb-3 text-left;
    }

    .link {
        @apply text-r underline;
    }

    .show-more-button {
        @apply hidden;
    }

    .eco-friendly-tooltip {
        @apply ml-1;

        &:deep(.tooltip-container) {
            width: theme('customVariables.tooltip.width');
        }
    }

    .go-back-to-product-button-wrapper {
        @apply mx-3;
    }

    .go-back-to-product-button {
        @apply mb-3 mx-auto block w-full;
        max-width: theme('customVariables.productSpecification.buttonWidth');
    }

    @screen lg {
        .open-button {
            @apply hidden;
        }

        .heading {
            @apply text-xl leading-xl;
        }

        .desktop-heading {
            @apply inline;
        }

        .multi-step-side-modal {
            &:deep() {
                .header {
                    @apply hidden;
                }

                .body-text {
                    overflow: unset;
                }
            }
        }

        .description {
            @apply px-0 pt-5 w-2/3;
        }

        .content {
            @apply overflow-hidden relative;
            max-height: theme(
                'customVariables.productSpecification.contentMaxHeight'
            );
            transition: max-height 1s ease;

            &:after {
                @apply block absolute bottom-0 w-full h-0;
                content: '';
                transition: height 0.2s ease;

                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    #ffffff 100%
                );
            }

            &.is-hidden {
                &:after {
                    @apply h-9;
                }
            }

            &:not(.is-hidden) {
                overflow: unset;
                animation: 3s delay-overflow;
            }
        }

        .columns-wrapper {
            @apply flex pb-5 mb-5 px-0 border-solid border-b-1;
        }

        .column {
            @apply w-1/4 pl-3;

            &:first-of-type {
                @apply pl-0;
            }
        }

        .column-title {
            @apply pt-5;
        }

        .show-more-button {
            @apply block mt-5 mx-auto;
        }
    }
}
</style>
