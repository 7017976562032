export const DELIVERY_TIME_ESTIMATES_BY_STORE = {
    bg: [
        { fromHours: 96, toHours: 120 }, // Sun
        { fromHours: 96, toHours: 120 }, // Mon
        { fromHours: 96, toHours: 120 }, // Tue
        { fromHours: 96, toHours: 120 }, // Wed
        { fromHours: 96, toHours: 120 }, // Thu
        { fromHours: 96, toHours: 120 }, // Fri
        { fromHours: 96, toHours: 120 }, // Sat
    ],
    cz: [
        { fromHours: 24, toHours: 48 }, // Sun
        { fromHours: 24, toHours: 48 }, // Mon
        { fromHours: 24, toHours: 48 }, // Tue
        { fromHours: 24, toHours: 48 }, // Wed
        { fromHours: 24, toHours: 48 }, // Thu
        { fromHours: 24, toHours: 48 }, // Fri
        { fromHours: 24, toHours: 48 }, // Sat
    ],
    de: [
        { fromHours: 24, toHours: 48 }, // Sun
        { fromHours: 24, toHours: 48 }, // Mon
        { fromHours: 24, toHours: 48 }, // Tue
        { fromHours: 24, toHours: 48 }, // Wed
        { fromHours: 24, toHours: 48 }, // Thu
        { fromHours: 24, toHours: 48 }, // Fri
        { fromHours: 24, toHours: 48 }, // Sat
    ],
    it: [
        { fromHours: 96, toHours: 120 }, // Sun
        { fromHours: 96, toHours: 120 }, // Mon
        { fromHours: 96, toHours: 120 }, // Tue
        { fromHours: 96, toHours: 120 }, // Wed
        { fromHours: 96, toHours: 120 }, // Thu
        { fromHours: 96, toHours: 120 }, // Fri
        { fromHours: 96, toHours: 120 }, // Sat
    ],
    ro: [
        { fromHours: 48, toHours: 96 }, // Sun
        { fromHours: 48, toHours: 96 }, // Mon
        { fromHours: 48, toHours: 96 }, // Tue
        { fromHours: 48, toHours: 96 }, // Wed
        { fromHours: 48, toHours: 96 }, // Thu
        { fromHours: 48, toHours: 96 }, // Fri
        { fromHours: 48, toHours: 96 }, // Sat
    ],
    sk: [
        { fromHours: 24, toHours: 48 }, // Sun
        { fromHours: 24, toHours: 48 }, // Mon
        { fromHours: 24, toHours: 48 }, // Tue
        { fromHours: 24, toHours: 48 }, // Wed
        { fromHours: 24, toHours: 48 }, // Thu
        { fromHours: 24, toHours: 48 }, // Fri
        { fromHours: 24, toHours: 48 }, // Sat
    ],
};

export const UNAVAILABLE_DELIVERY_DATES = {
    bg: [
        new Date('2024-01-01'),
        new Date('2024-03-03'),
        new Date('2024-05-01'),
        new Date('2024-05-03'),
        new Date('2024-05-04'),
        new Date('2024-05-05'),
        new Date('2024-05-06'),
        new Date('2024-05-24'),
        new Date('2024-09-06'),
        new Date('2024-09-22'),
        new Date('2024-09-23'),
        new Date('2024-12-24'),
        new Date('2024-12-25'),
        new Date('2024-12-26'),
        new Date('2025-01-01'),
    ],
    cz: [
        new Date('2024-01-01'),
        new Date('2024-03-29'),
        new Date('2024-04-01'),
        new Date('2024-05-01'),
        new Date('2024-05-08'),
        new Date('2024-07-05'),
        new Date('2024-07-06'),
        new Date('2024-09-28'),
        new Date('2024-10-28'),
        new Date('2024-11-17'),
        new Date('2024-12-24'),
        new Date('2024-12-25'),
        new Date('2024-12-26'),
        new Date('2025-01-01'),
    ],
    de: [
        new Date('2024-01-01'),
        new Date('2024-03-29'),
        new Date('2024-04-01'),
        new Date('2024-05-01'),
        new Date('2024-05-09'),
        new Date('2024-05-20'),
        new Date('2024-10-03'),
        new Date('2024-12-25'),
        new Date('2024-12-26'),
        new Date('2025-01-01'),
    ],
    it: [
        new Date('2024-01-01'),
        new Date('2024-01-06'),
        new Date('2024-03-31'),
        new Date('2024-04-01'),
        new Date('2024-04-25'),
        new Date('2024-05-01'),
        new Date('2024-06-02'),
        new Date('2024-08-15'),
        new Date('2024-11-01'),
        new Date('2024-12-08'),
        new Date('2024-12-25'),
        new Date('2024-12-26'),
        new Date('2025-01-01'),
        new Date('2025-01-06'),
    ],
    sk: [
        new Date('2024-01-01'),
        new Date('2024-01-06'),
        new Date('2024-03-29'),
        new Date('2024-04-01'),
        new Date('2024-05-01'),
        new Date('2024-05-08'),
        new Date('2024-07-05'),
        new Date('2024-09-01'),
        new Date('2024-09-15'),
        new Date('2024-11-01'),
        new Date('2024-11-17'),
        new Date('2024-12-24'),
        new Date('2024-12-25'),
        new Date('2024-12-26'),
        new Date('2025-01-01'),
        new Date('2025-01-06'),
    ],
    ro: [
        new Date('2024-01-01'),
        new Date('2024-01-02'),
        new Date('2024-01-06'),
        new Date('2024-01-07'),
        new Date('2024-01-24'),
        new Date('2024-05-01'),
        new Date('2024-05-03'),
        new Date('2024-05-05'),
        new Date('2024-05-06'),
        new Date('2024-06-01'),
        new Date('2024-06-23'),
        new Date('2024-06-24'),
        new Date('2024-08-15'),
        new Date('2024-11-30'),
        new Date('2024-12-01'),
        new Date('2024-12-25'),
        new Date('2024-12-26'),
        new Date('2025-01-01'),
        new Date('2025-01-02'),
        new Date('2025-01-06'),
        new Date('2025-01-07'),
        new Date('2025-01-24'),
    ],
};

export const WEEKEND_DAYS = [0, 6];
