<template>
    <ul class="product-reviews-list">
        <li
            v-for="({ rating, date, content, name }, index) in reviewsMapped"
            :key="index"
            class="review"
        >
            <ProductReviewsItem
                :rating="rating"
                :name="name"
                :date="date"
                :content="content"
            />
        </li>
    </ul>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { REVIEW_EMPTY_NAME } from '@configs/reviews';

import { FORMAT_DDMMYYYY_DOT } from '@types/DateFormat';

import { calculateAverageFromRatings } from '@assets/reviews';
import { formatDate } from '@assets/date';

import ProductReviewsItem from '@molecules/ProductReviewsList/ProductReviewsItem';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'ProductReviewsList',

    components: {
        ProductReviewsItem,
    },

    props: {
        reviews: {
            type: Array,
            required: true,
        },
    },

    computed: {
        ...mapConfigGetters(['timezone']),

        reviewsMapped() {
            return this.reviews.map(
                ({ ratings, createdAt, content, name }) => ({
                    rating: calculateAverageFromRatings(ratings),
                    date: formatDate(new Date(createdAt), FORMAT_DDMMYYYY_DOT),
                    content,
                    name: this.isNameVisible(name) ? name : '',
                })
            );
        },
    },

    methods: {
        isNameVisible(name) {
            return name !== REVIEW_EMPTY_NAME;
        },
    },
};
</script>

<style lang="scss" scoped>
.product-reviews-list {
    .review {
        @apply p-4 border-solid border-b-1 border-gray6;
    }

    @screen lg {
        .review {
            @apply px-0;
        }
    }
}
</style>
