export const getListingSeoLinks = (breadcrumbs, seoData) => {
    if (!Object.keys(seoData).length) {
        return {};
    }

    const breadcrumbsIdsReversed = breadcrumbs.map(({ id }) => id).reverse();

    let seoLinks = {};

    breadcrumbsIdsReversed.forEach(id => {
        if (Object.keys(seoLinks).length) {
            return;
        }

        seoLinks = seoData[id] || {};
    });

    return seoLinks;
};

export const getSeoLinks = (categories, seoData) => {
    if (!Object.keys(seoData).length) {
        return {};
    }

    let currentDepth = 0;

    return categories.reduce((acc, category) => {
        const subcategoriesIdsReversed = category.map(({ id }) => id).reverse();

        let seoLinks = {};
        let depth = 0;

        const subcategoriesCount = subcategoriesIdsReversed.length;

        subcategoriesIdsReversed.forEach((id, index) => {
            if (Object.keys(seoLinks).length) {
                return;
            }

            const data = seoData[id];

            if (data) {
                depth = subcategoriesCount - index;
                seoLinks = data;
            }
        });

        if (depth > currentDepth) {
            currentDepth = depth;

            return seoLinks;
        }

        return acc;
    }, {});
};
