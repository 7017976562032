<template>
    <section
        :id="PRODUCT_SECTION_REVIEWS_ID"
        ref="ProductReviewsWrapper"
        :data-test-id="REVIEWS_SECTION"
    >
        <ProductReviews
            v-if="isProductReviewsVisible"
            :reviews-data="reviews"
            :average-rating="averageRating"
            :sku="sku"
            :has-link-how-reviews-work="isBlockContentExist"
            @open-modal-how-opinions-work="openModal()"
        />

        <ModalHowOpinionsWork
            v-if="isModalOpen"
            :is-open="isModalOpen"
            :cms-block="howOpinionsWorkCmsBlock"
            @close="closeModal()"
        />
    </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { PRODUCT_SECTION_REVIEWS_ID } from '@configs/class-names';
import { MODAL_HOW_OPINIONS_WORK_NAME } from '@configs/modals';
import { PSB_HOW_OPINIONS_WORK } from '@configs/reviews';

import { REVIEWS_SECTION } from '@types/AutomaticTestIDs';

import Loader from '@atoms/Loader/Loader';
import OverlayLoader from '@atoms/OverlayLoader/OverlayLoader';

const {
    mapState: mapProductState,
    mapActions: mapProductActions,
} = createNamespacedHelpers('product');

const {
    mapGetters: mapCmsBlockGetters,
    mapActions: mapCmsBlockActions,
} = createNamespacedHelpers('cmsBlock');

export default {
    name: 'ProductReviewsWrapper',

    components: {
        ProductReviews: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "product-reviews" */ '@organisms/ProductReviews/ProductReviews'
            ),

            loading: Loader,
        }),

        ModalHowOpinionsWork: () => ({
            component: import(
                // eslint-disable-next-line max-len
                /* webpackChunkName: "modal-how-opinions-work" */ '@organisms/ModalHowOpinionsWork/ModalHowOpinionsWork'
            ),

            loading: OverlayLoader,
        }),
    },

    props: {
        sku: {
            type: String,
            required: true,
        },

        reviews: {
            type: Object,
            required: true,
        },

        averageRating: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            productReviewsObserver: null,
            howOpinionsWorkCmsBlock: {},
        };
    },

    computed: {
        ...mapProductState(['isProductReviewsVisible']),
        ...mapCmsBlockGetters(['staticBlockById']),

        isModalOpen() {
            return this.$modals.isOpen(MODAL_HOW_OPINIONS_WORK_NAME);
        },

        isBlockContentExist() {
            return !!this.howOpinionsWorkCmsBlock?.content;
        },
    },

    created() {
        this.PRODUCT_SECTION_REVIEWS_ID = PRODUCT_SECTION_REVIEWS_ID;
        this.REVIEWS_SECTION = REVIEWS_SECTION;
    },

    mounted() {
        this.loadReviews();

        this.loadModalStaticBlock();
    },

    beforeDestroy() {
        this.removeObserver();
    },

    methods: {
        ...mapProductActions(['setIsProductReviewsVisible']),
        ...mapCmsBlockActions(['getCmsBlocks']),

        removeObserver() {
            this.productReviewsObserver?.disconnect();
            this.productReviewsObserver = null;
        },

        loadReviews() {
            if (this.isProductReviewsVisible) {
                return;
            }

            if (!this.productReviewsObserver) {
                this.productReviewsObserver = new IntersectionObserver(
                    entries => {
                        entries.forEach(entry => {
                            if (entry.isIntersecting) {
                                this.setIsProductReviewsVisible(true);
                                this.removeObserver();
                            }
                        });
                    },
                    {
                        root: null,
                        threshold: 0,
                        rootMargin: '0px 0px 200px 0px',
                    }
                );
            }

            this.productReviewsObserver.observe(
                this.$refs.ProductReviewsWrapper
            );
        },

        openModal() {
            this.$modals.open(MODAL_HOW_OPINIONS_WORK_NAME);
        },

        closeModal() {
            this.$modals.close(MODAL_HOW_OPINIONS_WORK_NAME);
        },

        async loadModalStaticBlock() {
            this.howOpinionsWorkCmsBlock =
                this.staticBlockById(PSB_HOW_OPINIONS_WORK) || {};

            if (this.isBlockContentExist) {
                return;
            }

            await this.getCmsBlocks({
                cmsBlocksToLoad: PSB_HOW_OPINIONS_WORK,
            });

            this.howOpinionsWorkCmsBlock =
                this.staticBlockById(PSB_HOW_OPINIONS_WORK) || {};
        },
    },
};
</script>
