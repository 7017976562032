import StructuredDataBaseClass from '@models/StructuredData/StructuredDataBaseClass';
import { TYPE_LIST_ITEM } from '@configs/structured-data';

export default class StructuredDataListItem extends StructuredDataBaseClass {
    constructor(position, name, item) {
        super(TYPE_LIST_ITEM);
        this.position = position;
        this.name = name;
        this.item = item;
    }

    build() {
        return {
            ...super.build(),
            position: this.position,
            item: {
                '@id': this.item,
                name: this.name,
            },
        };
    }
}
