<template>
    <div
        v-if="
            shoppingInfoStaticBlocks.length ||
            isKlarnaWidgetEnabled ||
            isPaypoWidgetEnabled ||
            paymentCustomText
        "
        class="shopping-additional-info"
    >
        <div v-if="isTestDeliveryPromiseOn" class="item">
            <Icon :icon="Green" class="item-svg" />
            <div>
                {{ deliveryTime }}
            </div>
        </div>
        <slot
            name="before-static-blocks"
            :is-test-delivery-promise-on="isTestDeliveryPromiseOn"
        />
        <div
            v-for="{ id, icon, blockId, block } in shoppingInfoStaticBlocks"
            :key="blockId"
            class="item"
        >
            <SvgIcon :class="id" class="item-svg">
                <component :is="icon" />
            </SvgIcon>
            <div
                :data-test-id="
                    blockId === PSB_SHOPPING_SHIPPING_RETURN
                        ? FREE_SHIPPING_AND_RETURN
                        : null
                "
            >
                {{ block.title }}
            </div>
            <BaseTooltip
                :title="$t('More')"
                :is-mobile="isMobile"
                :position="POSITION_LEFT"
                tabindex="0"
                class="shopping-additional-info-tooltip"
                tooltip-container-class="shopping-additional-info-tooltip-container"
                @tooltip-showed="onTooltipShowed(id)"
            >
                <BaseParagraph class="more-info">
                    {{ $t('More') }}
                </BaseParagraph>
                <template #tooltipContent>
                    <StaticBlock v-if="block" :content="block.content" />
                </template>
            </BaseTooltip>
        </div>

        <PaypoInfoWidget v-if="isPaypoWidgetEnabled" class="paypo-info-widget">
            <div class="item">
                <SvgIcon class="item-svg">
                    <Paypo />
                </SvgIcon>
                <div>
                    {{ $t('Buy now and pay in 30 days') }}
                </div>
                <SvgIcon width="20px" height="20px" class="tooltip-icon">
                    <QuestionMark />
                </SvgIcon>
            </div>
        </PaypoInfoWidget>

        <KlarnaPromotionBadge
            v-else-if="isKlarnaWidgetEnabled"
            :price="price"
            class="item"
        />

        <div v-else-if="paymentCustomText" class="item">
            {{ paymentCustomText }}
        </div>
    </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';

import {
    CHECKOUT_PRODUCT_DELIVERY_PROMISE_AB_TEST_NAME,
    DELIVERY_PROMISE_VARIANT_ON,
} from '@configs/abtest-eob-checkout-product-delivery-promise';
import {
    SHOPPING_ADDITIONAL_INFO_MAP,
    PSB_SHOPPING_DELIVERY_TIME,
    PSB_FAST_SHOPPING_DELIVERY_TIME,
    PSB_SHOPPING_SHIPPING_RETURN,
    PSB_PAYMENT_WIDGET,
} from '@configs/shopping-info';

import {
    KLARNA_WIDGET_CLIENT_ID,
    MODAL_PAYPO_IFRAME_SRC,
} from '@localeConfig/keys';

import { FREE_SHIPPING_AND_RETURN } from '@types/AutomaticTestIDs';
import { POSITION_LEFT } from '@types/Tooltip';
import { PAYMENT_WIDGETS } from '@types/PaymentWidgets';

import { getDeliveryLabel } from '@assets/delivery-label';

import Dot from '@static/icons/24px/dot.svg?inline';
import Paypo from '@static/icons/24px/paypo.svg?inline';
import QuestionMark from '@static/icons/20px/question-mark-circled.svg?inline';

import { CMS_PAYMENT_WIDGET_CONFIG } from '@modules/page-builder/page-builder.config';

import SvgIcon from '@atoms/SvgIcon/SvgIcon';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';
import BaseTooltip from '@atoms/BaseTooltip/BaseTooltip';
import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';

import { Icon } from '@eobuwie-ui/components/Icon/v1';
import { Green } from '@eobuwie-ui/icons/v1/colors';

const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');
const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'ShoppingAdditionalInfo',

    components: {
        BaseTooltip,
        BaseParagraph,
        Icon,
        SvgIcon,
        StaticBlock,
        Dot,
        Paypo,
        QuestionMark,

        PaypoInfoWidget: () => ({
            component: import(
                /* webpackChunkName: "paypo-info-widget" */
                '@molecules/PaypoInfoWidget/PaypoInfoWidget'
            ),

            delay: 0,
        }),

        KlarnaPromotionBadge: () => ({
            component: import(
                /* webpackChunkName: "klarna-promotion-badge" */
                '@molecules/ShoppingAdditionalInfo/KlarnaPromotionBadge'
            ),

            delay: 0,
        }),
    },

    props: {
        price: {
            type: Object,
            default: null,
        },

        hiddenInfoBlocks: {
            type: Array,
            default: () => [],
        },

        fastDeliveryInfo: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            deliveryTime: '',
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapCmsBlockGetters([
            'staticBlockById',
            'getComponentFromPageBuilderBlock',
        ]),

        ...mapConfigGetters(['localeConfigByKey', 'storeCode']),

        isTestDeliveryPromiseOn() {
            return (
                this.$abTests.getVariant(
                    CHECKOUT_PRODUCT_DELIVERY_PROMISE_AB_TEST_NAME
                ) === DELIVERY_PROMISE_VARIANT_ON
            );
        },

        shoppingInfoStaticBlocksToShow() {
            const blockId = this.fastDeliveryInfo
                ? PSB_FAST_SHOPPING_DELIVERY_TIME
                : PSB_SHOPPING_DELIVERY_TIME;

            if (this.isTestDeliveryPromiseOn) {
                return SHOPPING_ADDITIONAL_INFO_MAP;
            }

            return [
                {
                    id: 'deliveryTime',
                    icon: Dot,
                    blockId,
                },
                ...SHOPPING_ADDITIONAL_INFO_MAP,
            ];
        },

        shoppingInfoStaticBlocks() {
            return this.shoppingInfoStaticBlocksToShow
                .map(item => ({
                    ...item,
                    block: this.staticBlockById(item.blockId),
                }))
                .filter(
                    ({ block, blockId }) =>
                        !!block && !this.hiddenInfoBlocks.includes(blockId)
                );
        },

        klarnaWidgetClientId() {
            return this.localeConfigByKey(KLARNA_WIDGET_CLIENT_ID);
        },

        isKlarnaWidgetEnabled() {
            return (
                this.klarnaWidgetClientId &&
                this.price &&
                this.paymentType === PAYMENT_WIDGETS.KLARNA
            );
        },

        paypoWidgetLink() {
            return this.localeConfigByKey(MODAL_PAYPO_IFRAME_SRC);
        },

        isPaypoWidgetEnabled() {
            return (
                this.paypoWidgetLink &&
                this.paymentType === PAYMENT_WIDGETS.PAYPO
            );
        },

        paymentBlock() {
            const blockComponent = this.getComponentFromPageBuilderBlock(
                PSB_PAYMENT_WIDGET,
                CMS_PAYMENT_WIDGET_CONFIG
            );

            return blockComponent.props || {};
        },

        paymentCustomText() {
            const { customText = '', paymentType } = this.paymentBlock;

            if (customText && paymentType === PAYMENT_WIDGETS.TEXT) {
                return customText;
            }

            return null;
        },

        paymentType() {
            return this.paymentBlock.paymentType;
        },
    },

    beforeCreate() {
        this.PSB_SHOPPING_SHIPPING_RETURN = PSB_SHOPPING_SHIPPING_RETURN;
        this.FREE_SHIPPING_AND_RETURN = FREE_SHIPPING_AND_RETURN;
        this.POSITION_LEFT = POSITION_LEFT;
        this.Green = Green;
    },

    mounted() {
        if (this.isTestDeliveryPromiseOn) {
            this.deliveryTime = getDeliveryLabel(
                this.storeCode,
                this.$t.bind(this)
            );
        }
    },

    methods: {
        onTooltipShowed(id) {
            this.$emit('tooltip-showed', id);
        },
    },
};
</script>

<style lang="scss" scoped>
.shopping-additional-info {
    .item {
        @apply flex justify-start items-center font-ui-body-s mt-ui-4;

        &:deep(.shopping-additional-info-tooltip-container) {
            @apply block;

            width: theme(
                'customVariables.shoppingAdditionalInfo.tooltipWidthBig'
            );
            max-width: 70vw;
        }

        &:nth-child(2) {
            &:deep(.shopping-additional-info-tooltip-container) {
                width: theme(
                    'customVariables.shoppingAdditionalInfo.tooltipWidthSmall'
                );
            }
        }
    }

    .item-svg {
        @apply mr-ui-2;

        &.deliveryTime {
            @apply text-ui-text-primary-accent;
        }
    }

    .more-info {
        @apply text-xs text-text3;
    }

    .shopping-additional-info-tooltip {
        @apply ml-ui-auto;
    }

    .tooltip-icon {
        @apply ml-ui-2 text-gray3;
    }
}

@screen lg {
    .shopping-additional-info {
        .item {
            &:deep(.shopping-additional-info-tooltip-container) {
                @apply max-w-ui-auto;
            }
        }
    }
}
</style>
