<template>
    <div class="product-specification-item-mobile">
        <WithScroll
            v-if="currentAttributeList.length"
            #default="{ autoscrollToActiveElement }"
            :is-horizontal="true"
            :hide-scrollbar="true"
            :autoscroll-on-init="true"
            :center-active-element="true"
            :autoscroll-active-class="ACTIVE_SELECTOR"
        >
            <div class="tags-list">
                <BaseTag
                    v-for="(attribute, index) in currentAttributeList"
                    :key="`${attribute.code}_${index}`"
                    :with-icon="false"
                    :selected="attribute.value === productDetailsContent.value"
                    :class="{
                        [ACTIVE_SELECTOR]:
                            attribute.value === productDetailsContent.value,
                    }"
                    class="tags"
                    @click.native="
                        updateMobileItem(attribute, autoscrollToActiveElement)
                    "
                >
                    <template #content>{{ attribute.value }}</template>
                </BaseTag>
            </div>
        </WithScroll>
        <div class="details-paragraph">
            <BaseParagraph>
                <span v-html="productDetailsContent.details" />
            </BaseParagraph>
            <BaseLinkStatic
                v-if="type === FOOTWEAR_WIDTH_ATTRIBUTE_NAME"
                :path="$tPathKey('blog-shoe-width')"
                class="more-details-link"
                target="_blank"
            >
                {{ $t('Find out more') }}
            </BaseLinkStatic>
            <BaseLinkStatic
                v-if="type === TECHNOLOGY_ATTRIBUTE_NAME"
                :path="productDetailsContent.url"
                class="more-details-link"
                target="_blank"
                @click.native="
                    $emit('click-link', {
                        code: TECHNOLOGY_ATTRIBUTE_NAME,
                        value: productDetailsContent.value,
                    })
                "
            >
                {{ $t('Show products with this technology') }}
            </BaseLinkStatic>
        </div>
    </div>
</template>

<script>
import {
    FOOTWEAR_WIDTH_ATTRIBUTE_NAME,
    TECHNOLOGY_ATTRIBUTE_NAME,
} from '@configs/product-attribute-names';

import BaseParagraph from '@atoms/BaseParagraph/BaseParagraph';
import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import BaseTag from '@atoms/BaseTag/BaseTag';
import WithScroll from '@atoms/WithScroll/WithScroll';

export default {
    name: 'ProductSpecificationItemMobile',

    components: {
        BaseParagraph,
        BaseLinkStatic,
        BaseTag,
        WithScroll,
    },

    props: {
        productAttributesList: {
            type: Object,
            default: () => ({}),
        },

        productDetailsContent: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        type() {
            return this.productDetailsContent.code;
        },

        currentAttributeList() {
            return this.productAttributesList[this.type] || [];
        },
    },

    beforeCreate() {
        this.FOOTWEAR_WIDTH_ATTRIBUTE_NAME = FOOTWEAR_WIDTH_ATTRIBUTE_NAME;
        this.TECHNOLOGY_ATTRIBUTE_NAME = TECHNOLOGY_ATTRIBUTE_NAME;
        this.ACTIVE_SELECTOR = 'selected';
    },

    methods: {
        updateMobileItem(attribute, autoscrollToActiveElement) {
            this.$emit('update-active-product-item', attribute);
            autoscrollToActiveElement();
        },
    },
};
</script>

<style lang="scss" scoped>
.product-specification-item-mobile {
    .tags-list {
        @apply flex float-left px-3 pt-3;
    }

    .tags {
        @apply flex-auto mx-1 whitespace-nowrap;

        &:first-child {
            @apply ml-0;
        }

        &:last-child {
            @apply mr-0;
        }
    }

    .details-paragraph {
        @apply m-3;
    }

    .more-details-link {
        @apply inline-block py-4 text-green2 font-bold text-s leading-s;
    }
}
</style>
