<template>
    <svg
        :height="size"
        :width="size"
        stroke="currentColor"
        class="progress-ring"
    >
        <circle
            :stroke-dasharray="`${circumference} ${circumference}`"
            :style="{ strokeDashoffset: strokeDashoffset }"
            :stroke-width="stroke"
            :r="normalizedRadius"
            :cx="size / 2"
            :cy="size / 2"
            fill="transparent"
        />
    </svg>
</template>

<script>
export default {
    name: 'ProgressRing',

    props: {
        size: {
            type: Number,
            required: true,
        },

        progress: {
            type: Number,
            default: 0,
        },

        stroke: {
            type: Number,
            default: 1,
        },
    },

    data() {
        const normalizedRadius = (this.size - this.stroke) / 2;
        const circumference = normalizedRadius * 2 * Math.PI;

        return {
            normalizedRadius,
            circumference,
        };
    },

    computed: {
        strokeDashoffset() {
            return (
                this.circumference - (this.progress / 100) * this.circumference
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.progress-ring {
    circle {
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
    }
}
</style>
