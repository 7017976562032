<template>
    <ul class="rating-stars">
        <li v-for="rate in 5" :key="rate">
            <RatingStar :type="getStarType(rate)" :is-large="isLarge" />
        </li>
    </ul>
</template>

<script>
import { TYPES } from '@types/RatingStar';

import RatingStar from '@atoms/RatingStar/RatingStar';

export default {
    name: 'RatingStars',

    components: {
        RatingStar,
    },

    props: {
        rating: {
            type: Number,
            required: true,
        },

        isLarge: {
            type: Boolean,
            default: false,
        },
    },

    methods: {
        getStarType(rate) {
            const { rating } = this;

            const fullNumber = Math.floor(rating);
            const fraction = Number((rating - fullNumber).toPrecision(2));

            if (rate === fullNumber + 1 && fraction >= 0.2) {
                return fraction >= 0.8 ? TYPES.TYPE_FULL : TYPES.TYPE_HALF;
            }

            return rating >= rate ? TYPES.TYPE_FULL : TYPES.TYPE_EMPTY;
        },
    },
};
</script>

<style lang="scss" scoped>
.rating-stars {
    @apply flex flex-row;

    .svg {
        @apply text-gray4;
    }
}
</style>
