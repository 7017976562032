<template>
    <div class="product-specification-icons-with-tooltips">
        <BaseTooltip
            v-for="({ label, icon }, index) in items"
            :key="index"
            :is-mobile="isMobile"
            position="top"
            position-mobile="top-right"
            padding="10px"
            tooltip-class="icon-tooltip"
            tooltip-container-class="icon-tooltip-container"
        >
            <img :src="icon.url" :alt="icon.alt" class="icon" />
            <template #tooltipContent>
                {{ label }}
            </template>
        </BaseTooltip>
    </div>
</template>

<script>
import { IMAGE_FORMAT_SVG, IMAGE_TYPES } from '@types/Image';
import BaseTooltip from '@atoms/BaseTooltip/BaseTooltip';

export default {
    name: 'ProductSpecificationIconsWithTooltips',

    components: {
        BaseTooltip,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },

        isMobile: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        items() {
            return Object.values(this.item.value)
                .map(item => {
                    try {
                        const { code, label, values = null } = item;

                        if (values) {
                            return {
                                label,
                                icon: this.$imaginator.getImage(
                                    values.image,
                                    code,
                                    code,
                                    IMAGE_TYPES.eob_svg.name,
                                    IMAGE_FORMAT_SVG,
                                    []
                                ),
                            };
                        }
                    } catch (err) {
                        this.$errorHandler.captureError(err);
                    }

                    return null;
                })
                .filter(item => item);
        },
    },
};
</script>

<style lang="scss" scoped>
$tooltipContentWidthMobile: 100px;
$tooltipContentWidthDesktop: 300px;

.product-specification-icons-with-tooltips {
    @apply inline-flex min-h-l;

    .icon {
        @apply h-20p;
    }

    &:deep() {
        .icon-tooltip {
            @apply ml-3;

            &:first-child {
                @apply ml-0;
            }
        }

        .icon-tooltip-container {
            width: $tooltipContentWidthMobile;

            .tooltip-content {
                @apply p-10p;
            }
        }
    }

    @screen md {
        &:deep() {
            .icon-tooltip-container {
                width: $tooltipContentWidthDesktop;
            }
        }
    }
}
</style>
