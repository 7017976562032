<template>
    <div class="product-details-header">
        <BannerInfo
            v-if="!isInStock"
            :variant="BANNER_INFO_VARIANT"
            :title="$t('The product is temporarily unavailable')"
            :description="
                $t('We can inform you as soon as it is back in our store.')
            "
            class="out-of-stock-message"
        />

        <BaseHeading tag="h1">
            <BaseLink
                :path="product.brandUrl"
                class="brand-link"
                @click.native="emitBrandNameClickEvent()"
            >
                <BaseHeading
                    :data-test-id="BRAND_NAME"
                    theme="h1"
                    class="brand-name"
                >
                    {{ product.brandName }}
                </BaseHeading>
            </BaseLink>
            <BaseHeading
                :data-test-id="PRODUCT_NAME"
                theme="h5"
                class="product-name"
            >
                {{ productName || product.nameDisplay }}
            </BaseHeading>
        </BaseHeading>

        <ProductRating
            v-if="reviewsTotalCount > 0"
            class="product-rating-container"
            :product-average-rating="productAverageRating"
            :reviews-total-count="reviewsTotalCount"
            :is-mobile="isMobile"
            @click-rating="onProductRatingClick()"
        />

        <div
            :class="{
                'reserve-rating-space': isRatingSpaceReserved,
            }"
            class="price-info"
        >
            <ProductDetailsHeaderPrice
                :discount-percent="price.discount"
                :price-regular="price.regular.formatted"
                :price-promotional="price.promotional.formatted"
                :price-omnibus="omnibusPrice"
            >
                <template #after-price>
                    <div
                        v-if="
                            omnibusPrice &&
                            !arePricesTheSame &&
                            (isOmnibusPriceInformationEnabled ||
                                hasDiscount ||
                                hasMarketingAction)
                        "
                        class="minimal-price"
                    >
                        {{ $t('Lowest price in 30 days before the discount') }}:
                        <span class="price-with-percent">
                            {{
                                formatLocalePrice(
                                    product.price.omnibus.formatted
                                )
                            }}
                            <span
                                v-if="hasDiscount && isOmnibusStrictEnabled"
                                class="price-percent is-omnibus"
                            >
                                -{{ product.price.discount }}
                            </span>
                        </span>
                    </div>
                    <MinimalPrice
                        v-else-if="
                            isMinimalPriceVisible && !isOmnibusStrictEnabled
                        "
                        :sku="product.sku"
                        :currency="currency"
                        :locale="locale"
                        :country-code="countryCode"
                        class="product-minimal-price"
                    />
                    <div
                        v-if="
                            isOmnibusStrictEnabled &&
                            omnibusPrice &&
                            !arePricesTheSame &&
                            isRegularPriceEnabled &&
                            (isOmnibusPriceInformationEnabled ||
                                hasDiscount ||
                                hasMarketingAction)
                        "
                        class="regular-price"
                    >
                        {{ $t('Regular price') }}:
                        <span class="price-with-percent">
                            {{ formatLocalePrice(price.regular.formatted) }}
                            <span v-if="hasDiscount" class="price-percent">
                                -{{ product.price.discountRegular }}
                            </span>
                        </span>
                    </div>
                </template>
            </ProductDetailsHeaderPrice>

            <MarketingActionLabel
                v-if="hasMarketingAction"
                :promo-label="product.promoSticker"
                class="marketing-action-label"
            />
        </div>

        <slot name="after-price" />

        <slot name="color-variants" />

        <div v-if="shoeWidth" class="shoe-width">
            {{ shoeWidth.label }}:
            <span class="shoe-width-label">{{ shoeWidth.value }}</span>
        </div>

        <template v-if="isAppOnly && isInStock">
            <BannerInfo
                :variant="BANNER_INFO_VARIANT"
                :title="$t('Product available only in the app')"
                :description="
                    $t('Be the first! Go to the app and buy this product now!')
                "
                class="app-only-info"
            />

            <ButtonLarge
                :variant="BUTTON_GO_TO_APP_VARIANT"
                :wide="true"
                :href="mobileAppLink"
                class="app-only-link"
                target="_blank"
            >
                {{ $t('Go to application') }}
            </ButtonLarge>
        </template>

        <template v-else>
            <ChooseSize
                ref="chooseSize"
                :product="product"
                :is-size-chart-button="isSizeChartButton"
                :product-variants="product.variants"
                :product-sku="product.sku"
                :chosen-size="chosenSize.toString()"
                :is-product-sizes-table-modal-open="
                    isProductSizesTableModalOpen
                "
                :is-product-one-size="product.isOneSize"
                :product-family="product.family"
                :size-set-on-init="sizeSetOnInit"
                :is-no-size-chosen-info-visible="noSizeChosenInfo"
                class="choose-size"
                @set-chosen-size="setChosenSize($event)"
                @close-size-modal="closeSizeModal()"
                @open-size-modal="openSizeModal()"
                @selected-not-available="selectedNotAvailable($event)"
                @esizeme-add-to-cart-info="handleEsizemeAddToCartInfo($event)"
                @esizeme-info="$emit('esizeme-info', $event)"
                @open-modal-added-to-cart-confirmation="
                    openModalAddedToCartConfirmation($event)
                "
            />

            <div
                :data-test-id="ADD_TO_CART_BUTTON"
                :class="{
                    'is-out-of-stock': !isInStock,
                }"
                class="cart-and-wishlist-buttons-wrapper"
            >
                <AddToCart
                    :product="product"
                    :chosen-size="chosenSize.toString()"
                    :esizeme-info="esizemeInfo"
                    class="add-to-cart-wrapper"
                    @no-size-chosen="scrollSizes()"
                    @open-modal-added-to-cart-confirmation="
                        openModalAddedToCartConfirmation($event)
                    "
                    @open-availability-modal="$emit('open-availability-modal')"
                    @add-to-cart-click="onAddToCartClick()"
                />

                <ButtonSmall
                    v-if="!isInStock && currentCategoryWithBrandFilterUrl"
                    :variant="BUTTON_VARIANT"
                    :href="currentCategoryWithBrandFilterUrl.path"
                >
                    {{ $t('Show similar products') }}
                </ButtonSmall>
            </div>

            <ShoppingAdditionalInfo
                v-if="isInStock"
                :fast-delivery-info="hasFastDeliveryInfo"
                :price="price"
                class="shopping-additional-info"
                @tooltip-showed="emitViewTooltipEvent($event)"
            >
                <template
                    v-if="showLoyaltyPointsEstimator"
                    #before-static-blocks
                >
                    <PointsEstimatorInfo
                        :points="loyaltyPoints"
                        :with-redirect-button="true"
                        class="points-estimator-info"
                    />
                </template>
            </ShoppingAdditionalInfo>
        </template>

        <div
            v-if="hasProductAdditionalStaticBlock"
            class="additional-static-block"
        >
            <StaticBlock :content="productAdditionalStaticBlock.content" />
        </div>
    </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';

import {
    GOOD_PRICE_ENABLED_LOCALES,
    PSB_PRODUCT_CARD_ADDITIONAL,
} from '@configs/product';
import { WIDTH_OF_SHOE_MODEL_ATTRIBUTE_NAME } from '@configs/product-attribute-names';

import {
    IS_MINIMAL_PRICE_ENABLED,
    FAST_DELIVERY_WAREHOUSE_CODE_NAME,
    IS_OMNIBUS_STRICT_ENABLED,
    IS_REGULAR_PRICE_ENABLED,
    MOBILE_APP_LINK_PDP,
} from '@localeConfig/keys';

import {
    BRAND_NAME,
    PRODUCT_NAME,
    ADD_TO_CART_BUTTON,
} from '@types/AutomaticTestIDs';

import {
    PRODUCT_OPINIONS_ANCHOR,
    PRODUCT_PAGE_ADD_TO_CART,
    ON_ELEMENT_ACTION,
} from '@analytics-module/modules/product/types/Events';
import { MODULE_NAME as PRODUCT_MODULE_NAME } from '@analytics-modules/product/meta';
import { CATEGORIES as PRODUCT_CATEGORIES } from '@analytics-module/modules/product/types/Categories';
import { ACTIONS as GLOBAL_ACTIONS } from '@analytics-module/types/Actions';
import { ACTIONS as PRODUCT_ACTIONS } from '@analytics-module/modules/product/types/Actions';

import { getCategoryUrlWithBrandFilter } from '@assets/product';
import { cutOffTheCents } from '@assets/cut-off-the-cents';
import { isLoyaltyClubEnabled } from '@loyalty-club/assets';

import BaseLink from '@atoms/BaseLink/BaseLink';
import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';

import ProductDetailsHeaderPrice from '@molecules/ProductDetailsHeaderPrice/ProductDetailsHeaderPrice';
import AddToCart from '@molecules/AddToCart/AddToCart';
import MarketingActionLabel from '@molecules/MarketingActionLabel/MarketingActionLabel';
import ShoppingAdditionalInfo from '@molecules/ShoppingAdditionalInfo/ShoppingAdditionalInfo';
import ProductRating from '@molecules/ProductRating/ProductRating';

import ChooseSize from '@organisms/ChooseSize/ChooseSize';

import {
    ButtonSmall,
    BUTTON_SMALL_VARIANTS,
} from '@eobuwie-ui/components/ButtonSmall/v1';

import {
    ButtonLarge,
    BUTTON_LARGE_VARIANTS,
} from '@eobuwie-ui/components/ButtonLarge/v1';

import {
    BannerInfo,
    BANNER_INFO_VARIANTS,
} from '@eobuwie-ui/components/BannerInfo/v1';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');
const { mapActions: mapModalActions } = createNamespacedHelpers('modals');

export default {
    name: 'ProductDetailsHeader',

    components: {
        ProductRating,
        ProductDetailsHeaderPrice,
        BaseLink,
        BaseHeading,
        ChooseSize,
        ButtonSmall,
        ButtonLarge,
        AddToCart,
        StaticBlock,
        ShoppingAdditionalInfo,
        MarketingActionLabel,
        BannerInfo,

        PointsEstimatorInfo: () => ({
            /* webpackChunkName: "points-estimator-info" */
            component: import(
                '@loyalty-club-molecules/PointsEstimatorInfo/PointsEstimatorInfo'
            ),
        }),

        MinimalPrice: () => ({
            component: import(
                /* webpackChunkName: "minimal-price" */
                '@molecules/MinimalPrice/MinimalPrice'
            ),
        }),
    },

    props: {
        product: {
            type: Object,
            required: true,
        },

        chosenSize: {
            type: String,
            required: true,
        },

        productAverageRating: {
            type: Number,
            required: true,
        },

        reviewsTotalCount: {
            type: Number,
            required: true,
        },

        isSizeChartButton: {
            type: Boolean,
            default: false,
        },

        sizeSetOnInit: {
            type: Boolean,
            default: false,
        },

        productName: {
            type: String,
            default: null,
        },

        isSpa: {
            type: Boolean,
            default: false,
        },

        isAppOnly: {
            type: Boolean,
            default: false,
        },

        loyaltyPoints: {
            type: [Number, null],
            default: null,
        },
    },

    data() {
        return {
            isProductSizesTableModalOpen: false,
            openedTooltipsIds: [],
            esizemeInfo: {},
            noSizeChosenInfo: false,
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapCmsBlockGetters(['staticBlockById']),
        ...mapConfigGetters([
            'locale',
            'currency',
            'countryCode',
            'localeConfigByKey',
            'storeCode',
        ]),

        isLoyaltyClubEnabled() {
            return isLoyaltyClubEnabled(
                this.$abTests,
                this.storeCode,
                this.$cookies
            );
        },

        showLoyaltyPointsEstimator() {
            return this.isLoyaltyClubEnabled && this.loyaltyPoints !== null;
        },

        currentCategoryWithBrandFilterUrl() {
            return getCategoryUrlWithBrandFilter(
                this.product,
                this.$createCategoryPath
            );
        },

        mobileAppLink() {
            return this.$getLocaleConfigByKey(MOBILE_APP_LINK_PDP) ?? '';
        },

        productAdditionalStaticBlock() {
            return this.staticBlockById(PSB_PRODUCT_CARD_ADDITIONAL);
        },

        hasProductAdditionalStaticBlock() {
            return this.productAdditionalStaticBlock?.content;
        },

        isInStock() {
            const { isInStock } = this.product;

            return isInStock;
        },

        price() {
            return this.product.price;
        },

        isPricesDiffer() {
            return this.price.regular.amount !== this.price.promotional.amount;
        },

        arePricesTheSame() {
            const { price } = this;

            return (
                price.promotional.amount === price.regular.amount &&
                price.promotional.amount === price.omnibus.amount
            );
        },

        hasDiscount() {
            return !!this.product.price.discount;
        },

        isRegularPriceEnabled() {
            return this.localeConfigByKey(IS_REGULAR_PRICE_ENABLED);
        },

        hasFastDeliveryInfo() {
            if (!this.chosenSize) {
                return false;
            }

            const warehouseCodeSuffix = this.$getLocaleConfigByKey(
                FAST_DELIVERY_WAREHOUSE_CODE_NAME
            );

            if (!warehouseCodeSuffix) {
                return false;
            }

            const warehouses =
                this.product.variants[this.chosenSize]?.values?.warehouse
                    ?.value || [];

            return warehouses.some(
                warehouseCode =>
                    warehouseCode.includes(`-${warehouseCodeSuffix}`) ||
                    warehouseCode === warehouseCodeSuffix
            );
        },

        isOmnibusStrictEnabled() {
            return this.$getLocaleConfigByKey(IS_OMNIBUS_STRICT_ENABLED);
        },

        omnibusPrice() {
            return this.product.price.omnibus.amount;
        },

        isRatingSpaceReserved() {
            return this.reviewsTotalCount === 0 ? this.isSpa : false;
        },

        isOmnibusPriceInformationEnabled() {
            if (GOOD_PRICE_ENABLED_LOCALES.includes(this.locale)) {
                return this.isPricesDiffer;
            }

            return false;
        },

        hasMarketingAction() {
            return Object.keys(this.product.promoSticker || {}).length > 0;
        },

        shoeWidth() {
            const {
                [WIDTH_OF_SHOE_MODEL_ATTRIBUTE_NAME]: shoeWidth,
            } = this.product.values;

            if (!shoeWidth) {
                return null;
            }

            const { label } = shoeWidth.attribute;
            const { label: value, code } = shoeWidth.value;

            if (!label || !value || code === '0') {
                return null;
            }

            return {
                label,
                value,
            };
        },
    },

    watch: {
        isProductSizesTableModalOpen(isOpen) {
            this.setIsSizeModalOpen(isOpen);
        },
    },

    beforeCreate() {
        this.BRAND_NAME = BRAND_NAME;
        this.PRODUCT_NAME = PRODUCT_NAME;
        this.ADD_TO_CART_BUTTON = ADD_TO_CART_BUTTON;
        this.BUTTON_VARIANT = BUTTON_SMALL_VARIANTS.SECONDARY;
        this.BANNER_INFO_VARIANT = BANNER_INFO_VARIANTS.INFO;
        this.BUTTON_GO_TO_APP_VARIANT = BUTTON_LARGE_VARIANTS.SECONDARY;
    },

    methods: {
        ...mapModalActions(['setIsSizeModalOpen']),

        closeSizeModal() {
            this.isProductSizesTableModalOpen = false;
        },

        openSizeModal() {
            this.isProductSizesTableModalOpen = true;
        },

        setChosenSize(size) {
            this.$emit('set-chosen-size', size.toString());
        },

        selectedNotAvailable(selectedProduct) {
            this.$emit('selected-not-available', selectedProduct);
        },

        openModalAddedToCartConfirmation(addedCartItem) {
            this.$emit('open-modal-added-to-cart-confirmation', addedCartItem);
        },

        emitBrandNameClickEvent() {
            this.$analytics.moduleEmit(PRODUCT_MODULE_NAME, ON_ELEMENT_ACTION, {
                eventCategory: PRODUCT_CATEGORIES.PRODUCT_DETAILS_HEADER,
                eventAction: PRODUCT_ACTIONS.CLICK_BRAND,
                eventLabel: this.product.brandName,
            });
        },

        onProductRatingClick() {
            this.$emit('click-rating');

            this.$analytics.moduleEmit(
                PRODUCT_MODULE_NAME,
                PRODUCT_OPINIONS_ANCHOR,
                {
                    reviewsCount: this.reviewsTotalCount,
                }
            );
        },

        emitViewTooltipEvent(id) {
            const { openedTooltipsIds } = this;

            if (!openedTooltipsIds.includes(id)) {
                this.$analytics.moduleEmit(
                    PRODUCT_MODULE_NAME,
                    ON_ELEMENT_ACTION,
                    {
                        eventCategory:
                            PRODUCT_CATEGORIES.PRODUCT_DETAILS_HEADER,
                        eventAction: GLOBAL_ACTIONS.VIEW_TOOLTIP,
                        eventLabel: id,
                    }
                );

                openedTooltipsIds.push(id);
            }
        },

        isMinimalPriceVisible() {
            if (!this.localeConfigByKey(IS_MINIMAL_PRICE_ENABLED)) {
                return false;
            }

            return this.isPricesDiffer || this.hasMarketingAction;
        },

        handleEsizemeAddToCartInfo(info) {
            if (!info) {
                return;
            }

            this.esizemeInfo = info;
        },

        onAddToCartClick() {
            this.$analytics.moduleEmit(
                PRODUCT_MODULE_NAME,
                PRODUCT_PAGE_ADD_TO_CART,
                {
                    productSku: this.product.sku,
                    size: this.chosenSize || null,
                    price: this.product.price.promotional.amount,
                    currency: this.currency,
                }
            );
        },

        formatLocalePrice(price) {
            return cutOffTheCents(price, this.$getLocaleConfigByKey);
        },

        scrollSizes() {
            this.noSizeChosenInfo = true;

            const element = this.$refs.chooseSize.$el;

            const { top, bottom } = element.getBoundingClientRect();

            const HEADER_SIZE = this.isMobile ? '128' : '120';

            if (top < HEADER_SIZE || bottom > window.innerHeight) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
$minimal-price-text-height: theme('lineHeight.s');
$margin-rating-placeholder: 50px;

.product-details-header {
    .brand-name {
        @apply block font-book text-text;
    }

    .product-name {
        @apply text-text3 font-book;
    }

    .out-of-stock-message {
        @apply my-ui-4;
    }

    .additional-static-block {
        @apply flex justify-center mt-10p;
    }

    .product-rating-container {
        @apply mt-2 h-20p;
    }

    .price-info {
        @apply mt-3;

        &.reserve-rating-space {
            margin-top: $margin-rating-placeholder;
        }
    }

    .add-to-cart-wrapper {
        @apply grow;
    }

    .product-minimal-price {
        @apply mt-1;

        min-height: $minimal-price-text-height;
    }

    .price-with-percent {
        @apply whitespace-nowrap;
    }

    .price-percent {
        @apply font-bold;

        &.is-omnibus {
            @apply text-red1;
        }
    }

    .minimal-price,
    .regular-price {
        @apply text-s leading-s text-gray9 mt-1;
    }

    .marketing-action-label {
        @apply mt-ui-4;
    }

    .app-only-info {
        @apply mt-ui-6;
    }

    .app-only-link {
        @apply mt-ui-4;
    }

    .choose-size {
        @apply mt-ui-6;
        @apply scroll-mt-[128px];
    }

    .shoe-width {
        @apply font-ui-body-m-bold;
        @apply text-ui-text-primary-default;
        @apply mt-ui-4;
    }

    .shoe-width-label {
        @apply font-ui-body-m lowercase;
    }

    .points-estimator-info {
        @apply mt-3;
    }

    &:deep() {
        .product-price {
            @apply mt-3;

            .final-price-wrapper {
                @apply text-m;
            }
        }
    }

    .cart-and-wishlist-buttons-wrapper {
        @apply grid mt-3 gap-3;

        &.is-out-of-stock {
            grid-template-columns: 1fr;
        }
    }

    @screen mobile-and-tablet-only {
        .app-only-info {
            @apply -ml-ui-4 -mr-ui-4;
        }
    }

    @screen lg {
        .out-of-stock-message {
            @apply mt-ui-0;
        }

        .brand-name {
            @apply text-xxl leading-xxl;
        }

        .product-name {
            @apply text-r leading-r;
        }

        .additional-static-block {
            @apply justify-start;
        }

        .app-only-info {
            @apply mt-ui-7;
        }

        .choose-size {
            @apply mt-ui-7;
            @apply scroll-mt-[120px];
        }

        &:deep(.product-price) {
            @apply justify-start py-ui-2;

            .wrapper {
                @apply items-baseline;
            }

            .final-price-wrapper {
                @apply text-xl;
            }
        }
    }
}
</style>
