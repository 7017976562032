<template>
    <div class="product-specification-item">
        <dt class="item item-label" :class="{ top: alignTop }">
            <slot name="label" />
        </dt>
        <dd class="item item-value">
            <slot name="value" />
            <slot name="tooltip" />
        </dd>
    </div>
</template>

<script>
export default {
    name: 'ProductSpecificationItem',

    props: {
        alignTop: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.product-specification-item {
    @apply pt-1;

    .item {
        @apply inline-flex m-0;
    }

    .item-label {
        @apply text-gray3;

        &.top {
            @apply align-top;
        }
    }

    .item-value {
        @apply inline text-gray1;
    }
}
</style>
