export const AB_TEST_NAME = 'eob_all_fit_mate';

export const EXCLUDED_CATEGORIES_IDS = [
    '2458', // dzieci
    '2447', // męskie sandały
    '3314', // męskie espadryle
    '2687', // męskie kalosze
    '2666', // męskie kapcie
    '3086', // męskie śniegowce
    '2443', // damskie sandały
    '6143', // damskie espadryle
    '2559', // damskie kalosze
    '2642', // damskie kapcie
    '2561', // damskie śniegowce
    '2538', // damskie szpilki
];
