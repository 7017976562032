import StructuredDataListItem from '@models/StructuredData/StructuredDataListItem';
import StructuredDataBaseClass from '@models/StructuredData/StructuredDataBaseClass';
import { TYPE_BREADCRUMBS } from '@configs/structured-data';

export default class StructuredDataBreadcrumbList extends StructuredDataBaseClass {
    constructor() {
        super(TYPE_BREADCRUMBS);
        this.items = [];
    }

    addItem(listItem) {
        if (!(listItem instanceof StructuredDataListItem)) {
            throw new TypeError(
                'listItem must be instance of StructuredDataListItem'
            );
        }

        this.items.push(listItem.build());
    }

    build() {
        return {
            ...super.build(),
            itemListElement: this.items,
        };
    }
}
