import StructuredDataBaseClass from '@models/StructuredData/StructuredDataBaseClass';
import { TYPE_BRAND } from '@configs/structured-data';

export default class StructuredDataBrand extends StructuredDataBaseClass {
    constructor(name, logoUrl) {
        super(TYPE_BRAND);
        this.name = name;
        this.logoUrl = logoUrl;
    }

    build() {
        return {
            ...super.build(),
            name: this.name,
            logo: this.logoUrl,
        };
    }
}
