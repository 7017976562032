<template>
    <div class="gallery-video" @click="$emit('video-click')">
        <BasePicture
            v-if="!isPlaying"
            :src="image.url"
            :alt="image.alt"
            :width="image.width"
            :height="image.height"
            :sources="image.sources"
            :sizes="imageSizes"
            :is-lazy="true"
            :is-background-filter-on="isBackgroundFilterOn"
            class="image"
        />

        <button
            v-if="!isPlaying"
            type="button"
            class="icon-container"
            @click.stop="playClick()"
            @mouseenter="startProgress()"
            @mouseleave="stopProgress()"
        >
            <span class="icon-circle">
                <ProgressRing
                    :size="72"
                    :stroke="2"
                    :progress="loadingProgress"
                    class="progress"
                />
                <Icon width="24px" height="24px" :icon="Play" class="icon" />
            </span>
        </button>

        <IframeVideo
            v-if="isLoading"
            ref="video"
            :src="`${video.url}&background=1&`"
            :width="video.width"
            :height="video.height"
            :is-background-filter-on="isBackgroundFilterOn"
            :is-lazy="true"
            :class="{
                'is-loading': !isPlaying,
            }"
            class="video"
            @loaded="playVideo()"
        />
    </div>
</template>

<script>
import Play from '@static/icons/24px/play.svg?inline';

import BasePicture from '@atoms/BasePicture/BasePicture';
import IframeVideo from '@atoms/IframeVideo/IframeVideo';

import ProgressRing from '@organisms/ProductMainGallery/ProgressRing';

import { Icon } from '@eobuwie-ui/components/Icon/v1';

const DURATION = 600;

export default {
    name: 'GalleryVideo',

    components: { ProgressRing, BasePicture, IframeVideo, Icon },

    props: {
        video: {
            type: Object,
            required: true,
        },

        image: {
            type: Object,
            required: true,
        },

        imageSizes: {
            type: String,
            default: null,
        },

        autoplayOnView: {
            type: Boolean,
            default: false,
        },

        isBackgroundFilterOn: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isLoading: false,
            isPlaying: false,
            loadingProgress: 0,
            progressTimer: null,
            observer: null,
        };
    },

    beforeCreate() {
        this.Play = Play;
    },

    mounted() {
        if (this.autoplayOnView) {
            this.startObserver();
        }
    },

    beforeDestroy() {
        this.stopProgress();
        this.stopObserver();
    },

    methods: {
        playVideo() {
            this.isPlaying = true;

            this.stopObserver();
        },

        playClick() {
            this.loadVideo();
        },

        loadVideo() {
            this.isLoading = true;
        },

        startProgress() {
            const zero = document.timeline.currentTime;

            const animate = timeStamp => {
                const value = (timeStamp - zero) / DURATION;

                this.loadingProgress = value * 100;

                if (this.loadingProgress <= 100) {
                    this.progressTimer = window.requestAnimationFrame(time =>
                        animate(time)
                    );
                } else {
                    this.loadVideo();
                }
            };

            this.progressTimer = window.requestAnimationFrame(time =>
                animate(time)
            );
        },

        stopProgress() {
            window.cancelAnimationFrame(this.progressTimer);

            this.loadingProgress = 0;
        },

        startObserver() {
            this.observer = new IntersectionObserver(
                entries => {
                    entries.forEach(entry => {
                        if (!entry.isIntersecting) {
                            this.stopProgress();

                            return;
                        }

                        this.startProgress();
                    });
                },
                { threshold: [0.9] }
            );

            this.observer.observe(this.$el);
        },

        stopObserver() {
            this.observer?.disconnect();
            this.observer = null;
        },

        // eslint-disable-next-line vue/no-unused-properties
        playVideoIframe() {
            this.$refs.video?.play();
        },
    },
};
</script>

<style lang="scss" scoped>
.gallery-video {
    @apply relative;

    &:before {
        @apply content-[''];
        @apply block;
        @apply absolute;
        @apply z-ui-1;
        @apply w-ui-percent-100;
        @apply h-ui-percent-100;
        @apply left-ui-0 top-ui-0;
    }

    .icon-container {
        @apply flex flex-col justify-center items-center;
        @apply absolute top-ui-0 left-ui-0 z-ui-1;
        @apply w-ui-percent-100 h-ui-percent-100;
    }

    .icon-circle {
        @apply h-ui-14 w-ui-14;
        @apply rounded-ui-percent-50;
        @apply flex items-center justify-center;
        @apply bg-ui-container-default-alternative;
        @apply text-ui-container-default-default;
        @apply border-ui-2 border-solid border-ui-container-stroke-300-default;
        @apply relative;
    }

    .progress {
        @apply absolute top-[-2px] left-[-2px];
    }

    .image,
    .video {
        @apply w-ui-percent-100;
    }

    .video {
        &.is-loading {
            @apply opacity-ui-default;
            @apply absolute;
        }
    }
}
</style>
