<template>
    <div :data-test-id="OVERLAY" class="overlay-loader">
        <Loader height="96px" class="loader-element" />
        <div v-if="$slots.text" class="loader-text">
            <slot name="text" />
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { PREVENT_SCROLL_CLASS_NAME } from '@configs/class-names';

import { OVERLAY } from '@types/AutomaticTestIDs';

import Loader from '@atoms/Loader/Loader';

const { mapActions: mapActionsLayout } = createNamespacedHelpers('layout');

export default {
    name: 'OverlayLoader',

    components: {
        Loader,
    },

    computed: {
        HTMLClasses() {
            return [PREVENT_SCROLL_CLASS_NAME];
        },
    },

    mounted() {
        this.addHTMLClasses(this.HTMLClasses);
    },

    beforeCreate() {
        this.OVERLAY = OVERLAY;
    },

    destroyed() {
        this.removeHTMLClasses(this.HTMLClasses);
    },

    methods: {
        ...mapActionsLayout(['addHTMLClasses', 'removeHTMLClasses']),
    },
};
</script>

<style lang="scss" scoped>
@import '@theme/resources/mixin/blurred-background';

.overlay-loader {
    @include blurred-background();
    @apply flex w-full h-screen fixed left-0 top-0 z-8 justify-center items-center flex-col;

    .loader-text {
        @apply text-center text-r leading-r text-text2 mt-2;
    }

    .loader-element {
        @apply bg-green6 rounded-50 w-9 border-solid border-1 border-gray8;
    }

    &:deep() {
        .loader {
            @apply w-8 h-8;
        }
    }
}
</style>
